import React, { Fragment, useState, createRef, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
import DeviceChooser from '../Components/DeviceChooser/AsDropdown.js';
import * as Yup from "yup"

export default function JobcardAddDeviceDialog({ open, handler }) {
    // STATE
    const [isOpen, setIsOpen] = useState(open);
    const [selectedModel, setSelectedModel] = React.useState(undefined);
    // FIXME const [selectedNotes, setSelectedNotes] = React.useState(undefined);

    // HOOKS
    const serialInputRef = createRef();
    const sensorInputRef = createRef();
    const notesInputRef = createRef();

    React.useEffect(() => {
        setIsOpen(open);
    }, [open])

    // YUP

    const formSchema = Yup.object({
        modelGuid: Yup.object({
            id: Yup.string("Enter a serial number").defined(),
            modelName: Yup.string("Enter a serial number").defined(),
        }).required(),
        serialNumber: Yup.string(),
        sensorSerial: Yup.string(),
        notes: Yup.string("Enter any notes").optional(),
    }).test('serialNumberOrSensorSerialRequired', 'Either serial number or sensor serial number is required', function (value) {
        const { serialNumber, sensorSerial } = value;
        return !serialNumber && !sensorSerial ? this.createError({
            path: 'serialNumber',
            message: 'Either serial number or sensor serial number is required',
        }) : true;
    });

    // FUNCTIONS

    const validateForm = async () => {
        try {
            await formSchema.validate({ "modelGuid": selectedModel, "serialNumber": serialInputRef.current.value, "sensorSerial": sensorInputRef.current.value, "notes": notesInputRef.current.value });
        } catch (err) {
            window.alert("Please choose a model and enter either the device/sensor serial number or both.");
            return;
        }

        handler(selectedModel, serialInputRef.current.value, sensorInputRef.current.value, notesInputRef.current.value);
    }

    const handleDeviceModelChange = useCallback((newVal, setFieldValue) => {
        setSelectedModel(newVal);
    }, []);

    function closeDialog() {
        setIsOpen(false);
        handler(undefined, undefined, undefined);
    }

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500" onClick={() => closeDialog()} >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-isober-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationIcon className="h-6 w-6 text-isober-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Add device to job card
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">Device details:</p>
                                    </div>

                                    <dl className="sm:divide-y sm:divide-gray-200">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Device model</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <DeviceChooser handler={handleDeviceModelChange} sfv={undefined} />
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Device serial number</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <input type="text" name="serialNumber" id="serialNumber" ref={serialInputRef} className="focus:ring-isober-500 focus:border-isober-500 block w-full rounded-md pl-3 sm:text-sm border-gray-300" />
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Sensor serial number</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <input type="text" name="sensorSerialNumber" id="sensorSerialNumber" ref={sensorInputRef} className="focus:ring-isober-500 focus:border-isober-500 block w-full rounded-md pl-3 sm:text-sm border-gray-300" />
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Notes</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <input type="text" name="note" id="note" ref={notesInputRef} className="focus:ring-isober-500 focus:border-isober-500 block w-full rounded-md pl-3 sm:text-sm border-gray-300" />
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-600 text-base font-medium text-white hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => validateForm()} >
                                    Save
                                </button>
                                <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-600 text-base font-medium text-white hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => closeDialog()} >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}