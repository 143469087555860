import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';

export default function useCalibration(calibrationGuid) {
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'calibrations', calibrationGuid);
    const { status, data: calibration } = useFirestoreDocDataOnce(documentRef, { idField: "id" });

    if (status !== 'success') {
        console.log("Calibration for " + calibrationGuid + " = undefined");
        return undefined;
    }

    return calibration;
}