import React from 'react';
import useClientInfo from '../Components/ClientInfo';

export default function ApiDocsTab(props) {
    const clientRecord = useClientInfo(props.userinfo.clientid);

    return (
        <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">API Docs</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Documentation for the iSober Client API.</p>
            </div>
            <div className="border-t border-gray-200">
                {(clientRecord.clientapi === null || clientRecord.clientapi === undefined) &&
                    <p className="px-8 py-8">Your account is not enabled for API access; please contact <a href="mailto:info@breathalysers.co.za" className="text-isober-900 hover:text-isober-1100">info@breathalysers.co.za</a> to discuss this feature.</p>
                }

                {(clientRecord.clientapi !== undefined && clientRecord.clientapi.enabled !== undefined && clientRecord.clientapi.enabled === true) &&
                    <dl>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <p className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Your account is enabled for API access. Use the details below to get started.</p>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Client ID</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.userinfo.clientid}</dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">API Key</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{clientRecord.clientapi.key}</dd>
                        </div>
                    </dl>
                }
            </div>
        </div>
    )
}