import React from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { Link, useLocation, useParams } from 'react-router-dom';
import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';

export default function DevicePage(props) {
    // HOOKS
    const currentLocation = useLocation();
    const { modelId } = useParams();

    // FIRESTORE
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'devicemodels', modelId);
    const { status, data: device } = useFirestoreDocData(documentRef);
    const calrangeKeys = ('calrange' in device) ? Object.keys(device.calrange).sort() : null;

    if (status === 'loading') {
        return <span>Loading...</span>;
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">The selected device's details appear below.</p>
                        </div>
                        <div className="mt-3 flex sm:mt-0 sm:ml-4">
                            <Link to={`/calibrations/admin/devices/edit/${modelId}`}>
                                <button type="button" className="mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                    Edit details
                                </button>
                            </Link>
                        </div>
                    </div>

                    {/* Device area */}
                    <div className="mt-6 bg-white shadow overflow-y-visible sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Device model information</h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">
                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Manufacturer:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {device.manufacturer !== undefined && device.manufacturer}
                                        {device.manufacturer === undefined && "Unknown"}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Model:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{device.displayname}</dd>
                                </div>

                                {(device.calrange !== undefined && device.calrange !== null) && calrangeKeys.map((key, index) => {
                                    return (<div key={`calrange_${index}`} className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">Accuracy range</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{key}: {device.calrange[key]}</dd>
                                    </div>)
                                })}

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Validity:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{device.validityCount !== undefined ? device.validityCount : '—'} tests</dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Pre-set PASS/FAIL limit:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{device.presetLimit !== undefined ? device.presetLimit : '—'}</dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">CRM type:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{device.gasType !== undefined ? device.gasType : '—'}</dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Device indicators:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {!device.ledStyle && 'Numeric range'}
                                        {device.ledStyle && 'LEDs'}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    )
}