import React from 'react';
import { BreadcrumbsAlt} from '../Breadcrumbs/index.js';

export function PageHeaderAlt(props) {
    var headerTitle = "Dashboard";
    var breadcrumbs = [];

    const clientDetailsRegex = new RegExp('/system/clients/[a-zA-Z0-9]{20}');
    const userDetailsRegex = new RegExp('/system/clients/[a-zA-Z0-9]{20}/user/[a-zA-Z0-9]{28}');

    if (props.location.startsWith("/system/social")) {
        headerTitle = "Social users";
        breadcrumbs = [];
    } else if (userDetailsRegex.test(props.location)) {
        headerTitle = "User Details";
        breadcrumbs = [{title: "Manage clients", href: "/system/clients"}];
    } else if (clientDetailsRegex.test(props.location)) {
        headerTitle = "Client Details";
        breadcrumbs = [{title: "Manage clients", href: "/system/clients"}];
    } else if (props.location.startsWith("/system/clients/users")) {
        headerTitle = "Manage Client Users";
        breadcrumbs = [{title: "Manage clients", href: "/system/clients"}];
    } else if (props.location.startsWith("/system/clients/create")) {
        headerTitle = "Create Client";
        breadcrumbs = [];
    } else if (props.location.startsWith("/system/clients/edit")) {
        headerTitle = "Edit Client Details";
        breadcrumbs = [{title: "Manage clients", href: "/system/clients"}];
    } else if (props.location.startsWith("/system/clients")) {
        headerTitle = "Manage Clients";
        breadcrumbs = [];
    } else if (props.location.startsWith("/system/commerce")) {
        headerTitle = "Subscriptions";
        breadcrumbs = [];
    } else if (props.location.startsWith("/system/feedback")) {
        headerTitle = "App Feedback";
        breadcrumbs = [];
    } else if (props.location.startsWith("/system/admin/logs")) {
        headerTitle = "Log Viewer";
        breadcrumbs = [{title: "System administration", href: "/system/admin"}];
    } else if (props.location && props.location.startsWith("/system/admin/users/user")) {
        headerTitle = "User details"
        breadcrumbs = [{title: "System administration", href: "/system/admin"}, {title: "Manage users", href: "/system/admin/users"}];
    } else if (props.location && props.location.startsWith("/system/admin/users/edit")) {
        headerTitle = "Edit user details"
        breadcrumbs = [{title: "System administration", href: "/system/admin"}, {title: "Manage users", href: "/system/admin/users"}];
    } else if (props.location && props.location.startsWith("/system/admin/users")) {
        headerTitle = "Manage users"
        breadcrumbs = [{title: "System administration", href: "/system/admin"}];
    } else if (props.location.startsWith("/system/admin")) {
        headerTitle = "System Administration";
        breadcrumbs = [];
    } 

    return (
        <div>
            <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{headerTitle}</h2>
                </div>
            </div>

            <BreadcrumbsAlt items={breadcrumbs} />
        </div>
    )
}

export default function PageHeader(props) {
    return (
        <div>
            <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{props.title}</h2>
                </div>
            </div>
        </div>
    )
}