import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useAuth } from 'reactfire';
import { getIdToken } from "firebase/auth";
import { Formik, ErrorMessage } from 'formik';
import { PageHeaderAlt } from '../Components/PageHeader/index.js';
import * as Yup from "yup"

export default function CreateUserPage(props) {
    // form validation etc
    const createUserInitialValues = { idnumber: "", fname: "", lname: "", email: "", password: "", passwordConfirm: "" };
    const createUserValidationSchema = Yup.object({
        fname: Yup.string("Enter a first name").required("First name is required"),
        lname: Yup.string("Enter a last name").required("Last name is required"),
        idnumber: Yup.string("").matches(/^(\s*|\d{13})$/, "Enter a valid ID number").nullable(),
        email: Yup.string("Enter a valid email address").email("Invalid").required("Required"),
        password: Yup.string("Enter password").required("Required").min(8, 'Password should be at least 8 characters'),
        passwordConfirm: Yup.string("Confirm password").required("Required").oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    // hooks
    const currentLocation = useLocation();
    const auth = useAuth();
    const navigate = useNavigate();

    // state
    const [formLoading, setFormLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);

    function showSuccessDialog() {
        setSuccessDialogOpen(true);
    }

    function hideSuccessDialog() {
        setSuccessDialogOpen(false);
        navigate('/system/admin/users'); // outta here
    }

    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    async function handleSubmit(form) {
        closeErrorBanner();
        setFormLoading(true);

        // validate
        if (!props.userinfo || !props.userinfo.clientid) {
            setFormLoading(false);
            showErrorBanner("Error - invalid client ID");
            return;
        } else {
            // merge in client ID from props
            form.clientid = props.userinfo.clientid;
        }

        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isober.co.za/api/useradd', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(form)
                })
                    .then(response => response.json())
                    .then(data => {
                        setFormLoading(false);
                        if (data.error) {
                            showErrorBanner(data.message);
                        } else {
                            showSuccessDialog();
                        }
                    });
            })
            .catch((errorJwt) => {
                showErrorBanner("Error - invalid auth token");
            });
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">

            <div className="py-6 px-6">
                <PageHeaderAlt location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <div className="pb-5 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">Use the form below to create a new user.</p>
                        </div>
                    </div>

                    {/* Update user modal confirmation */}
                    {successDialogOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                            {/*  This element is to trick the browser into centering the modal contents. */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                        {/* Heroicon name: outline/check */}
                                        <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                        </svg>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Confirmation</h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                The user was created successfully.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button onClick={hideSuccessDialog} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none sm:text-sm">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    <div className="bg-white shadow overflow-hidden sm:rounded-lg px-4 py-5 sm:px-6">
                        <Formik initialValues={createUserInitialValues} onSubmit={handleSubmit} validationSchema={createUserValidationSchema} >
                            {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values }) => (
                                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                        <div>
                                            <div>
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">Create user</h3>
                                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Enter the form below.</p>
                                            </div>

                                            <div className="border-t border-gray-200 mt-6 py-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                                                {/* Loading spinner */}
                                                {formLoading &&
                                                    <div className="sm:col-span-6">
                                                        <img src="/images/loading.gif" width="40" height="40" alt="" className="mb-4" />
                                                    </div>
                                                }

                                                {/* Error banner */}
                                                {errorBannerOpen && <div className="sm:col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                            </svg>
                                                        </div>
                                                        <div className="ml-3">
                                                            <p className="text-sm text-yellow-700">{lastErrorMessage}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                }

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                        Email address &nbsp;
                                                        <ErrorMessage name="email" component="span" className="text-red-700 italic" />
                                                    </label>
                                                    <div className="mt-1">
                                                        <input type="text" name="email" id="email" onChange={handleChange} onBlur={handleBlur}
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="idnumber" className="block text-sm font-medium text-gray-700">
                                                        SA ID number &nbsp;
                                                        <ErrorMessage name="idnumber" component="span" className="text-red-700 italic" />
                                                    </label>
                                                    <div className="mt-1">
                                                        <input type="text" name="idnumber" id="idnumber" placeholder="Optional" onChange={handleChange} onBlur={handleBlur}
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                <div className="sm:col-span-3">
                                                    <label htmlFor="fname" className="block text-sm font-medium text-gray-700">
                                                        First name &nbsp;
                                                        <ErrorMessage name="fname" component="span" className="text-red-700 italic" />
                                                    </label>
                                                    <div className="mt-1">
                                                        <input type="text" name="fname" id="fname" onChange={handleChange} onBlur={handleBlur}
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="lname" className="block text-sm font-medium text-gray-700">
                                                        Last name &nbsp;
                                                        <ErrorMessage name="lname" component="span" className="text-red-700 italic" />
                                                    </label>
                                                    <div className="mt-1">
                                                        <input type="text" name="lname" id="lname" onChange={handleChange} onBlur={handleBlur}
                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                <div className="sm:col-span-3">
                                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                        Password &nbsp;
                                                        <ErrorMessage name="password" component="span" className="text-red-700 italic" />
                                                    </label>
                                                    <div className="mt-1">
                                                        <input type="password" name="password" id="password" value={values.password} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="passwordConfirm" className="block text-sm font-medium text-gray-700">
                                                        Confirm password &nbsp;
                                                        <ErrorMessage name="passwordConfirm" component="span" className="text-red-700 italic" />
                                                    </label>
                                                    <div className="mt-1">
                                                        <input type="password" name="passwordConfirm" id="passwordConfirm" value={values.passwordConfirm} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* These options are not yet enabled in the web api.
                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Role</label>
                                    <div className="mt-1">
                                        <div className="flex items-center">
                                            <input id="push-everything" name="push-notifications" type="radio" defaultChecked={!clientAdmin ? true : false} className="focus:ring-isober-500 h-4 w-4 text-isober-1000 border-gray-300" />
                                            <label htmlFor="push-everything" className="ml-3 block text-sm text-gray-700">User</label>
                                        </div>
                                        <div className="flex items-center">
                                            <input id="push-email" name="push-notifications" type="radio" defaultChecked={clientAdmin ? true : false} className="focus:ring-isober-500 h-4 w-4 text-isober-1000 border-gray-300" />
                                            <label htmlFor="push-email" className="ml-3 block text-sm text-gray-700">Client administrator</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Features</label>
                                    <div className="mt-1">
                                        <div className="relative flex items-start">
                                            <div className="flex items-center h-5">
                                                <input id="candidates" name="candidates" type="checkbox"
                                                    className="focus:ring-isober-500 h-4 w-4 text-isober-1000 border-gray-300 rounded" />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label htmlFor="candidates" className="text-gray-700">Measurement alerts</label>
                                                <p className="text-gray-500">Configure measurement alerts on the web to receive push notifications on mobile.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                                        </div>
                                    </div>

                                    <div className="pt-5">
                                        <div className="flex justify-end">
                                            <Link to={`/system/admin/users`}>
                                                <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Cancel</button>
                                            </Link>
                                            <button type="submit" disabled={!dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Save</button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </main>
    )
}