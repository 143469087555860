import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom';

export function BreadcrumbsAlt(props) {
    if (props.items === null || props.items.length === 0) {
        return <div />;
    }

    return (
        <div>
            {props.items.length >= 1 &&
                <nav className="sm:hidden" aria-label="Back">
                    <Link to={props.items[props.items.length - 1].href} className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
                        <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />Back
                    </Link>
                </nav>
            }

            <nav className="hidden sm:flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-2">
                    {props.items.map((item, i) => {
                        if (props.items.length === 1) {
                            return (<li key={item.title}>
                                <div className="flex items-center">
                                    <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <Link to={item.href} className="text-sm font-medium text-gray-500 hover:text-gray-700">{item.title}</Link>
                                </div>
                            </li>);
                        }
                        if (i > 0) {
                            return (<li key={item.title}>
                                <div className="flex items-center">
                                    <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <Link to={item.href} className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700">{item.title}</Link>
                                </div>
                            </li>);
                        } else {
                            return (<li key={item.title}>
                                <div className="flex items-center">
                                    <Link to={item.href} className="text-sm font-medium text-gray-500 hover:text-gray-700">{item.title}</Link>
                                </div>
                            </li>);
                        }
                    })
                    }
                </ol>
            </nav>
        </div >
    )
}

export default function Breadcrumbs(props) {
    return (
        <div>
            <nav className="sm:hidden" aria-label="Back">
                <a href="#1" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
                    <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />Back</a>
            </nav>
            <nav className="hidden sm:flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-2">
                    <li>
                        <div>
                            <a href="#2" className="text-sm font-medium text-gray-500 hover:text-gray-700">Jobs</a>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <a href="#3" className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700">Engineering</a>
                        </div>
                    </li>
                </ol>
            </nav>
        </div>
    )
}