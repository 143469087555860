import React, { useRef, useCallback, useEffect } from 'react';
import * as Yup from "yup"
import cloneDeep from 'lodash/cloneDeep';
import MetadataDropdown from './metadataDropdown.js';

export default function MetadataCapture({ handler, sfv, sft }) {
    // REFS
    const itemValueRef = useRef();
    const metadataDropdownHandler = useCallback((newVal, setFieldValue) => {
        setMetadataItem(newVal);
    }, []);

    // STATE
    const [metadataItemList, setMetadataItemList] = React.useState([])
    const [metadataItem, setMetadataItem] = React.useState(undefined)

    useEffect(() => {
        handler(metadataItemList, sfv, sft);
    }, [metadataItemList, handler, sfv, sft]);

    // YUP

    const formSchema = Yup.object({
        fieldKey: Yup.string("Choose a field").required(),
        fieldValue: Yup.string("Enter a field value").required()
    });

    // ACTIONS

    const insertItem = async () => {
        try {
            await formSchema.validate({ "fieldKey": metadataItem.fieldKey, "fieldValue": itemValueRef.current.value });
        } catch (err) {
            window.alert("Please choose a model and enter a serial number");
            return;
        }

        // check we don't already have this model/serial number
        var isDuplicate = false;
        metadataItemList.forEach(item => {
            if (item.fieldKey === metadataItem.fieldKey) {
                isDuplicate = true;
            }
        });

        if (isDuplicate) {
            window.alert('That field has already been captured below');
            return;
        }

        await setMetadataItemList([{"fieldKey": metadataItem.fieldKey, "fieldDisplay": metadataItem.fieldDisplay, "fieldValue": itemValueRef.current.value}, ...metadataItemList]);
        itemValueRef.current.value = ''
        itemValueRef.current.focus();

        // finally, pass the current set of devices/serials upwards
        // useEffect will ensure handler() is called when metadataItemList changes
        // per https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
    }

    const removeItem = async (idx) => {
        const metadataItemListClone = cloneDeep(metadataItemList);
        metadataItemListClone.splice(idx, 1);
        await setMetadataItemList(metadataItemListClone);

        // useEffect will ensure handler() is called when metadataItemList changes
        // per https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
    }

    return (
        <div className="shadow sm:rounded-md overflow-y-visible">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Extra information</h3>
                    <p className="mt-1 text-sm text-gray-500">Choose any supporting fields to add to the job card.</p>
                </div>

                {/* start form */}
                <div className="grid grid-cols-5 gap-6">
                    <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">
                            Field &nbsp;
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <MetadataDropdown handler={metadataDropdownHandler} sfv={undefined} />
                        </dd>
                    </div>

                    <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">
                            Value &nbsp;
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                    <input type="text" name="serialNumber" id="serialNumber" ref={itemValueRef} className="focus:ring-isober-500 focus:border-isober-500 block w-full rounded-md pl-3 sm:text-sm border-gray-300" />
                                </div>
                            </div>
                        </dd>
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                            &nbsp;
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <button type="button" onClick={insertItem} className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md disabled:text-gray-300 text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500">
                                <span>Add</span>
                            </button>
                        </dd>
                    </div>
                </div>
                {/* end form */}

                {metadataItemList.length > 0 && <div>
                    {metadataItemList.map((item, idx) => (
                        <div key={idx} className="grid grid-cols-5 gap-6 my-0">
                            <div className="sm:col-span-2">
                                <p className="text-gray-700">
                                    <span className=''>{item.fieldDisplay}</span>
                                </p>
                            </div>

                            <div className="sm:col-span-2">
                                <span className=''>{item.fieldValue}</span>
                            </div>

                            <div className="sm:col-span-1">
                                <span className="inline-flex items-center my-2 px-2 py-1 rounded-full text-xs font-medium leading-4 bg-isober-50 text-isober-900 focus:outline-none focus:shadow-outline mr-2">
                                    <button type="button" onClick={() => removeItem(idx)} className="flex-shrink-0 ml-0 inline-flex text-isober-500 focus:outline-none focus:text-isober-700">
                                        <span>Remove</span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    ))}
                </div>}

            </div>
        </div>
    )
}