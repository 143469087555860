import { collection, query } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function useJobcardNotes(jobcardId) {
    const firestore = useFirestore();
    const jobcardNotesCollection = collection(firestore, `jobcards/${jobcardId}/notes`);
    var activeQuery = query(jobcardNotesCollection);

    const { status, data: notes } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return notes;
}
