import React, { useState } from 'react';
import JobCardsList from './jobcardlist';

function OpenJobCards(props) {
    // STATE
    const [branch, setBranch] = useState(0);

    function branchSelected(id) {
        const validBranches = [0, 1, 2, 3, 4, 5];
        if (validBranches.includes(id)) {
            setBranch(id);
        }
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div>
            <div className="py-4">

                {/* fixme: de-dup this from dashboard to one component that lifts selected branch */}
                <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Open job cards</h3>
                        <div className="mt-4 grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                                <label htmlFor="street-address" className="block text-sm text-gray-500">Branch:</label>
                                <div className="">
                                    <span className="mt-1 relative z-0 inline-flex shadow-sm rounded-md">
                                        <button type="button" onClick={() => branchSelected(0)} className={classNames(branch === 0 ? 'bg-isober-50' : 'bg-white', 'rounded-l-md -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500')}>All</button>
                                        <button type="button" onClick={() => branchSelected(1)} className={classNames(branch === 1 ? 'bg-isober-50' : 'bg-white', '-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500')}>Johannesburg</button>
                                        <button type="button" onClick={() => branchSelected(2)} className={classNames(branch === 2 ? 'bg-isober-50' : 'bg-white', '-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500')}>Cape Town</button>
                                        <button type="button" onClick={() => branchSelected(3)} className={classNames(branch === 3 ? 'bg-isober-50' : 'bg-white', '-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500')}>Durban</button>
                                        <button type="button" onClick={() => branchSelected(4)} className={classNames(branch === 4 ? 'bg-isober-50' : 'bg-white', '-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500')}>Client site</button>
                                        <button type="button" onClick={() => branchSelected(5)} className={classNames(branch === 5 ? 'bg-isober-50' : 'bg-white', 'rounded-r-md -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500')}>Botswana</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* List of job cards */}
                <JobCardsList branch={branch} />
            </div>
        </div>
    )
}

export default OpenJobCards;