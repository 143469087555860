import { useEffect, useState } from 'react';
import { formatDate } from '../../Shared/Components/functions.js'
import usePaginatedAndroidSubscriptions from './pageinatedAndroid.js';
import { Link } from 'react-router-dom';

export default function SubscriptionFragment(props) {

    // State
    const resultsPerPage = 10;
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedClientReadings()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);

    // set up firestore query
    const entries = usePaginatedAndroidSubscriptions(resultsPerPage, referenceItem, include);
    const currentLastVisibleItem = (entries !== undefined) ? entries.docs[entries.size - 1] : null;

    // Functions

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, entries.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    const activeBadge = () => {
        return (
            <span className="inline-flex items-center flex-shrink-0 inline-block px-2 py-0.5 text-xs rounded-full bg-green-100 text-green-800">
                <svg className="mr-1.5 h-2 w-2 text-green-800" fill="currentColor" viewBox="0 0 8 8"><circle cx={4} cy={4} r={3} /></svg>
                Active
            </span>
        )
    }

    const inactiveBadge = () => {
        return (
            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-xs rounded-full bg-gray-200 text-gray-800">Inactive</span>
        )
    }

    const devtestBadge = () => {
        return (
            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-xs rounded-full bg-purple-100 text-purple-800">Dev test</span>
        )
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (entries !== undefined && entries.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (entries !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, entries]);

    return (
        <div>
            <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Android Subscriptions</h3>
                    {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Subtitle</p>*/}
                </div>

                <div className="border-t border-gray-200">
                    <div className="py-0 px-0">
                        {/* table start */}
                        <div className="mt-0 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-0 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Starts</th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expires</th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {entries !== undefined && entries.docs.map((entry, entryIdx) => (
                                                <tr key={entryIdx} className={entryIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {(typeof entry.data().purchaseType == "undefined" && entry.data().expiryTime.toDate() >= new Date()) && activeBadge()}
                                                        {(typeof entry.data().purchaseType == "undefined" && entry.data().expiryTime.toDate() < new Date()) && inactiveBadge()}
                                                        {typeof entry.data().purchaseType !== "undefined" && devtestBadge()}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {entry.data().countryCode && entry.data().countryCode}
                                                        {!entry.data().countryCode && "—"}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {entry.data().lastStatus && entry.data().lastStatus}
                                                        {!entry.data().lastStatus && "—"}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {entry.data().startTime && formatDate(entry.data().startTime.toDate())}
                                                        {!entry.data().startTime && "—"}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {entry.data().expiryTime && formatDate(entry.data().expiryTime.toDate())}
                                                        {!entry.data().expiryTime && "—"}
                                                    </td>
                                                    <td>
                                                        <Link to={`/system/commerce/subscription/${entry.id}`}>
                                                            <span className="text-xs text-isober-900 hover:text-isober-1100">Details</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* table end */}
                    </div>
                </div>
            </div>

            {/* pagination start */}
            {entries.docs.length > 0 && /* pagination start */
                <nav
                    className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{(firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>

                        <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            }
            {/* end pagination */}
        </div>
    )
}