import React from 'react';

export default function SupportTab(props) {

    return (
        <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Request Support</h3>
            </div>
            <div className="border-t border-gray-200">
                <p className="px-8 py-8">
                    For support, please contact <a href="mailto:angus@breathalysers.co.za" className="text-isober-900 hover:text-isober-1100">angus@breathalysers.co.za</a>.
                </p>
            </div>
        </div>
    )
}