import { HomeIcon, LogoutIcon } from '@heroicons/react/outline'
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useFirebaseApp } from 'reactfire';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const sections = [
    { name: 'Dashboard', href: '/stock', icon: HomeIcon },
    { name: 'Admin', href: '/stock/admin', icon: HomeIcon },
]

export default function SideNavigation(props) {
    const navigate = useNavigate();
    const app = useFirebaseApp();
    const auth = getAuth(app);

    // includes fix from https://github.com/FirebaseExtended/reactfire/discussions/228
    function signOut() {
        auth.signOut().then(() => {
            navigate('/app');
            window.location.reload();

        });
    }

    function isHighlighted(item) {
        if ((props.path === '/stock' || props.path.startsWith('/stock/sku')) && item === 'Dashboard') {
            return true
        } else if (props.path.startsWith('/stock/admin') && item === 'Admin') {
            return true
        }

        return false
    }

    return (
        <nav className="mt-5 flex-1 px-2 space-y-1">
            {sections.map((section) => (
                <Link to={section.href} key={section.name} className={classNames(
                    isHighlighted(section.name) ? 'bg-[#003140] text-white' : 'text-white hover:bg-[#002834]',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md')}>
                    <section.icon className="mr-3 h-6 w-6 text-white" aria-hidden="true" />
                    {section.name}
                </Link>
            ))}
            <button onClick={() => signOut()} className="text-white hover:bg-[#014256] group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                <LogoutIcon className="mr-3 h-6 w-6 text-white" />
                Sign out
            </button>
        </nav>
    )
}