import React, { useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from "yup"
import CerticatesByIdentifier from './certificatesbyid.js';
import CerticatesByDeviceSerial from './certificatesbyserial.js';
import CerticatesByGuid from './certificatesbyguid.js';

export default function CertificatesTab(props) {
    // STATE 
    const [predicate, setPredicate] = useState(0);
    const [query, setQuery] = useState(false);

    // LOGIC

    function predicateSelected(id) {
        // reset search
        setQuery(false);

        const validPredicates = [0, 1, 2]; // cert no, serialidx
        if (validPredicates.includes(id)) {
            setPredicate(id);
        }
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    // FORMS

    const initialValues = { query: '' };
    const validationSchema = Yup.object({
        query: Yup.string("Enter search term").required("Required")
    });

    async function handleSubmit(form) {
        setQuery(form.query);
    }

    return (
        <div>
            <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Search by certificate number</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Enter a certificate number to search. 2022/15 &nbsp; TBHKJLG0 on2ux0q5fynmZ3DxpH9k</p>
                </div>

                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                    {({ handleSubmit, handleChange, handleBlur, isValid, dirty }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="px-6 pb-6">
                                <div className="grid grid-cols-6 gap-6">

                                    <div className="mt-4 grid grid-cols-6 gap-6">
                                        <div className="col-span-6">
                                            <label htmlFor="street-address" className="block text-sm text-gray-700">Field:</label>
                                            <div className="">
                                                <span className="mt-1 relative z-0 inline-flex shadow-sm rounded-md">
                                                    <button type="button" onClick={() => predicateSelected(0)} className={classNames(predicate === 0 ? 'bg-isober-50' : 'bg-white', 'rounded-l-md -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500 whitespace-nowrap')}>Certificate no</button>
                                                    <button type="button" onClick={() => predicateSelected(1)} className={classNames(predicate === 1 ? 'bg-isober-50' : 'bg-white', '-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500 whitespace-nowrap')}>Unique ID</button>
                                                    <button type="button" onClick={() => predicateSelected(2)} className={classNames(predicate === 2 ? 'bg-isober-50' : 'bg-white', 'rounded-r-md -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500 whitespace-nowrap')}>Device serial</button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-6">
                                        <label htmlFor="query" className="block text-sm font-medium text-gray-700">
                                            {predicate === 0 && "Certificate number: "} 
                                            {predicate === 1 && "Certificate ID: "} 
                                            {predicate === 2 && "Device serial number: "} 
                                            {predicate === 3 && "Customer name: "} 
                                            &nbsp; <ErrorMessage name="query" component="span" className="text-red-700 italic" />
                                        </label>

                                        {/* input with button */}
                                        <div className="mt-1 flex rounded-md shadow-sm">
                                            <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                                <input type="text" name="query" id="query" autoComplete='off' onChange={handleChange} onBlur={handleBlur} className="focus:ring-isober-500 focus:border-isober-500 block w-full rounded-none rounded-l-md pl-2 sm:text-sm border-gray-300" placeholder="Enter search term..." />
                                                {/*<div className='absolute top-3 right-2'>
                                                    {formLoading && <img src='/images/loadingtiny.gif' width='16' height='16' alt='' />}
                                                </div>*/}
                                            </div>
                                            <button type="submit" disabled={/*formLoading ||*/ !dirty || !isValid} className="disabled:opacity-50 -ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500">
                                                <span>Search</span>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>

            {predicate === 0 && query && <CerticatesByIdentifier query={query.trim()} />}
            {predicate === 1 && query && <CerticatesByGuid query={query.trim()} />}
            {predicate === 2 && query && <CerticatesByDeviceSerial query={query.trim()} />}

        </div>
    )
}