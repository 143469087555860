import React, { Fragment, useState } from 'react';
import SignaturePad from 'react-signature-pad-wrapper'
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

// See https://github.com/michaeldzjap/react-signature-pad-wrapper
//     https://github.com/szimek/signature_pad
//     https://github.com/michaeldzjap/react-signature-pad-wrapper/issues/36

export default function SignatureCanvas(props) {
    // HOOKS
    var signaturePadRef = React.createRef();

    // EFFECTS

    // FFS am seeing pixellated crap on first load, and if one resizes the window, then it goes to retina
    React.useEffect(() => {
        if (signaturePadRef.current) {
            signaturePadRef.current.handleResize();
        }
    }, [signaturePadRef]);

    // STATE
    const [open, setOpen] = useState(props.open)

    // INTERFACE

    // eslint-disable-next-line
    function isEmpty() {
        const signaturePad = signaturePadRef.current;
        if (!signaturePad) {
            return true;
        }

        return signaturePad.isEmpty();
    }

    // eslint-disable-next-line
    function toDataURL() {
        const signaturePad = signaturePadRef.current;
        if (!signaturePad) {
            return undefined;
        }

        return signaturePad.toDataURL();
    }

    // INTERNAL HELPERS

    function clearSignature() {
        const signaturePad = signaturePadRef.current;

        if (signaturePad) {
            signaturePad.instance.clear();
        }
    }

    function closeDialog() {
        setOpen(false);
        props.closehandler();
    }

    function handleImage() {
        const signaturePad = signaturePadRef.current;
        if (!signaturePad) {
            closeDialog();
        }

        props.imagehandler(signaturePad.toDataURL());
        closeDialog();
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500"
                                    onClick={() => closeDialog()}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="pb-3 text-lg leading-6 font-medium text-gray-900">
                                        Sign in the space below
                                    </Dialog.Title>

                                    <div className="inline-block border-2 border-gray-300 rounded-md w-96 h-42">
                                        <SignaturePad ref={signaturePadRef} options={{ penColor: 'rgb(0, 0, 0)', backgroundColor: 'rgb(255, 255, 255)' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-600 text-base font-medium text-white hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => handleImage()} >
                                    Done
                                </button>
                                <button type="button" className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => clearSignature()} >
                                    Clear canvas
                                </button>
                                <button type="button" className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => closeDialog()} >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
