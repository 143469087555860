import { collection, query, where, orderBy, limit, startAfter, startAt } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function usePaginatedJobcards(startItem, include, branch, jobcardStatus, rpp) {
    const firestore = useFirestore();
    const jobcardsCollection = collection(firestore, 'jobcards');

    console.log("Branch is " + branch);

    if (!jobcardStatus) {
        jobcardStatus = "Open";
    }

    var activeQuery = undefined;
    if (startItem === null) {
        activeQuery = (branch !== 0) ? query(jobcardsCollection, where('status', '==', jobcardStatus), where('branch', '==', branch), orderBy('created', 'desc'), limit(rpp)) : query(jobcardsCollection, where('status', '==', jobcardStatus), orderBy('created', 'desc'), limit(rpp));
    } else {
        if (branch === 0 || branch === null || branch === undefined ) {
            activeQuery = (include) ? query(jobcardsCollection, where('status', '==', jobcardStatus), orderBy('created', 'desc'), limit(rpp), startAt(startItem)) : query(jobcardsCollection, where('status', '==', jobcardStatus), orderBy('created', 'desc'), limit(rpp), startAfter(startItem));
        } else {
            activeQuery = (include) ? query(jobcardsCollection, where('status', '==', jobcardStatus), where('branch', '==', branch), orderBy('created', 'desc'), limit(rpp), startAt(startItem)) : query(jobcardsCollection, where('status', '==', jobcardStatus), where('branch', '==', branch), orderBy('created', 'desc'), limit(rpp), startAfter(startItem));
        }
    }

    const { status, data: jobcards } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return jobcards;
}
