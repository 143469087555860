import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import usePaginatedClientUsers from './useclientusers.js';

export default function UsersListFragment() {
    // get client id from the router
    const { clientId } = useParams();

    // pagination settings
    const resultsPerPage = 25;
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedClientUsers()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);

    const clientUsers = usePaginatedClientUsers(clientId, resultsPerPage, referenceItem, include);
    const currentLastVisibleItem = (clientUsers !== undefined) ? clientUsers.docs[clientUsers.size - 1] : null;

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, clientUsers.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (clientUsers !== undefined && clientUsers.size % resultsPerPage !== 0) {
            setNextDisabled(true);
        } else if (clientUsers !== undefined) {
            setNextDisabled(false);
        }
    }, [firstItemStack, clientUsers]);

    return (
        <div className="flex flex-col">


            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500">The users that belong to this client are listed below. Select View Details to manage a user or select Create user to add a new user to this client.</p>
                </div>
                <div className="mt-3 flex sm:mt-0 sm:ml-4">
                    <Link to="create"> 
                        <button type="button" className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Create&nbsp;user</button>
                    </Link>
                </div>
            </div>

            {(clientUsers.docs === undefined || clientUsers.docs.length === 0) &&
                <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="border-t border-gray-200">
                        <p className="p-6 text-sm text-black">No users are currently loaded for this client; use the "Create user" button above to create a new user.</p>
                    </div>
                </div>
            }

            {(clientUsers.docs !== undefined && clientUsers.docs.length > 0) &&
                <div className="mt-5 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                                        <th scope="col" className="relative px-6 py-3"><span className="sr-only">Edit</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientUsers.docs.map((person, personIdx) => (
                                        <tr key={person.data().email} className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{person.data().fname} {person.data().lname}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.data().email}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.data().role}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <Link to={`/system/clients/${clientId}/user/${person.id}`}>
                                                    <span className="text-isober-900 hover:text-isober-1100">View details</span>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            {clientUsers.docs.length > 0 && /* pagination start */
                <nav
                    className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{(firstItemStack.length * resultsPerPage) + clientUsers.size}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>

                        <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            }

        </div>
    )
}