import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';

export default function useJobCardDevice(jobcardGuid, deviceGuid) {
    const firestore = useFirestore();
    const documentRef = doc(firestore, `jobcards/${jobcardGuid}/devices`, deviceGuid);
    const { status, data: device } = useFirestoreDocDataOnce(documentRef, { idField: "id" });

    if (status !== 'success') {
        return undefined;
    }
    
    return device;
}