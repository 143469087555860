import React, { useState } from 'react';
import { PageHeaderAlt } from '../Components/PageHeader/index.js';
import { useLocation } from 'react-router-dom';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import LoadingActivity from './LoadingActivity.js';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import * as XLSX from "xlsx"; // https://docs.sheetjs.com/docs/demos/frontend/react/
import useClientUsers from '../Components/UserInfo/clientusers.js';
import 'react-day-picker/dist/style.css';

// See https://github.com/gpbl/react-day-picker
//     https://react-day-picker.js.org/basics/styling

// FIXME start date in the future
//       end date in the future
//       start date > end date

export default function ReportsPage(props) {
    // first day of the current month
    const currentMonthStart = new Date();
    currentMonthStart.setDate(1);
    currentMonthStart.setHours(0, 0, 0, 0);
    const defaultSelected = { from: currentMonthStart, to: new Date() };

    // HOOKS
    const firestore = useFirestore();
    const currentLocation = useLocation();
    const clientUsers = useClientUsers(props.userinfo.clientid);

    // STATE
    const [range, setRange] = useState(defaultSelected);
    const [loading, setLoading] = useState(false);
    const [reportResultset, setReportResultset] = useState(undefined);

    // HELPERS

    const resetRange = () => {
        setRange(defaultSelected);
        setReportResultset(undefined);
    }

    const validateDateRange = () => {
        // duplicated in overall picture, but needed here
        if (!range || !range.from || !range.to) {
            window.alert("Range error: a date range is not set.");
            return false;
        }

        if (range.from >= new Date()) {
            window.alert("Range error: start date cannot be in the future.");
            return false;
        }

        if (range.from >= range.to) {
            window.alert("Range error: end date cannot be earlier than start date.");
            return false;
        }

        return true;
    }

    // MAIN SHOW

    const getReportData = async () => {
        setReportResultset(undefined); // reset
        setLoading(true);

        // check date range
        if (validateDateRange() === false) {
            // alerts will be raised in validateDateRange() or shown on-page
            setLoading(false);
            return;
        }

        // validate client ID
        if (!props.userinfo || !props.userinfo.clientid) {
            window.alert("Error: client ID is not set, please contact support.");
            setLoading(false);
            return;
        }

        // validate dates
        if (!range || !range.from || !range.to) {
            window.alert("Error: date range is not set, please contact support.");
            setLoading(false);
            return;
        }

        // start from 00:00:00 AM
        const startDate = range.from;
        startDate.setHours(0, 0, 0, 0);

        // end at 23:59:59 PM
        const endDate = range.to;
        endDate.setHours(23, 59, 59, 999);

        // configure query
        const collectionPath = `clients/${props.userinfo.clientid}/readings`;
        const readingsCollection = collection(firestore, collectionPath);
        const collectionQuery = query(readingsCollection, where('created', '>=', startDate), where('created', '<=', endDate), orderBy('created', 'desc'));

        // execute query
        const querySnapshot = await getDocs(collectionQuery);
        setReportResultset(querySnapshot);

        setLoading(false);
    }

    // FIXME sort Object.keys
    // FIXME https://bobbyhadz.com/blog/javascript-sort-object-keys

    const handleDownload = () => {
        // adjust firestore result set slightly for the spreadsheet
        var rows = reportResultset.docs.map(function (measurement) {
            var retval = measurement.data();

            // fix headings for measurement
            if (retval.baclevel !== undefined) {
                retval.measurement = retval.baclevel;
                delete retval.baclevel;
            }
            if (retval.bacscale) {
                retval.unitScale = retval.bacscale;
                delete retval.bacscale;
            }

            // format created date (Android can have a separate event date)
            if (retval.testdate && retval.testdate.toDate()) {
                retval.eventDate = retval.testdate.toDate().toLocaleString()
                delete retval.testdate;
                delete retval.created;
            } else {
                retval.eventDate = retval.created.toDate().toLocaleString()
                delete retval.created;
            }

            // unwrap user name
            if (retval.useruid && clientUsers.get(retval.useruid) !== undefined) {
                retval.user = clientUsers.get(retval.useruid);
            } else {
                retval.user = retval.useruid;
            }
            delete retval.useruid;

            // remove irrelevant columns 
            delete retval.handled;
            delete retval.photomain;
            delete retval.statusmsg;

            // unwrap location
            if (retval.location) {
                if (retval.location.latitude) retval.latitude = retval.location.latitude;
                if (retval.location.longitude) retval.longitude = retval.location.longitude;
                delete retval.location;
            }

            // seeing trailing CR on deviceserial
            retval.deviceserial = retval.deviceserial.trim();

            // unwrap placemark
            if (retval.placemark) {
                if (retval.placemark.locality) {
                    retval.locationLocality = retval.placemark.locality;
                }
                if (retval.placemark.sublocality) {
                    retval.locationSublocality = retval.placemark.sublocality;
                }
                if (retval.placemark.name) {
                    retval.locationName = retval.placemark.name;
                }

                delete retval.placemark;
            }

            // fix device headings
            if (retval.devicemodel) {
                retval.deviceModel = retval.devicemodel;
                delete retval.devicemodel;
            }
            if (retval.deviceserial) {
                retval.deviceSerial = retval.deviceserial;
                delete retval.deviceserial;
            }
            if (retval.firmware) {
                retval.deviceFirmware = retval.firmware;
                delete retval.firmware;
            }

            // unwrap test another
            if (retval.subject) {
                if (retval.subject.idno) {
                    retval.subjectIdno = retval.subject.idno;
                }
                if (retval.subject.name) {
                    retval.subjectIName = retval.subject.name;
                }
                if (retval.subject.notes) {
                    retval.subjectNote = retval.subject.notes;
                }

                delete retval.subject;
            }

            return retval;
        });

        // create workbook and worksheet
        const workbook = XLSX.utils.book_new();
        const measurementsWorksheet = XLSX.utils.json_to_sheet(rows);
        // const usersWorksheet = ...

        XLSX.utils.book_append_sheet(workbook, measurementsWorksheet, "Measurements");

        // serve to browser
        XLSX.writeFile(workbook, "ReportFor2023.xlsx", { compression: true });
    };

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeaderAlt location={currentLocation.pathname} />

                {loading && <LoadingActivity loading={loading} />}

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}
                    <div>
                        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                            <div>
                                <p className="mt-2 max-w-4xl text-sm text-gray-500">Download measurements in Excel format using the form below.</p>
                            </div>
                            <div className="mt-3 flex sm:mt-0 sm:ml-4">
                                {/* Button here if needed */}
                            </div>
                        </div>

                        <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6 grid grid-cols-2">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Date range</h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Choose a date range below, and press 'Next' to generate.</p>
                                </div>
                                <div className='justify-right text-right'>
                                    <button type="button" onClick={() => resetRange()} className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Reset</button>
                                </div>


                            </div>
                            <div className="border-t border-gray-200">
                                <DayPicker mode="range" selected={range} onSelect={setRange} max={90} defaultMonth={currentMonthStart} numberOfMonths={3} modifiersClassNames={{ selected: 'text-white bg-isober-900', today: 'text-white bg-isober-1100' }} />
                            </div>
                        </div>

                        <div className="grid grid-cols-2">
                            <div className="mt-5 flex justify-start">
                                {range && range.from && range.to && <p>{format(range.from, 'PPPP')} &nbsp;to&nbsp; {format(range.to, 'PPPP')}</p>}
                                {!range && <p>No range selected</p>}
                            </div>
                            <div className="mt-5 flex justify-end">
                                {!reportResultset && <button type="button" onClick={() => getReportData()} disabled={!(range && range.from && range.to)} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-1000 hover:bg-isober-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                    Next
                                </button>}

                                {reportResultset && <div>
                                    <button type="button" onClick={() => handleDownload()} disabled={!(range && range.from && range.to)} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-1000 hover:bg-isober-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                        Download Excel
                                    </button>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}
