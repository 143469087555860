import React, { useState } from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LoadingBanner, ErrorBanner } from '../Components/Helpers/index.js';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from "yup"
import ConfirmCreateCustomer from './confirmcreatecustomer.js';
import { useFirestore, useAuth } from 'reactfire';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

export default function CreateCustomerPage(props) {
    // HOOKS
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const firestore = useFirestore();
    const customersConfiguration = collection(firestore, 'calibrationclients');
    const logConfiguration = collection(firestore, 'calibrationlogs');

    // FORMS

    const initialValues = { name: '', address1: '', address2: '', city: '', postcode: '' };
    const validationSchema = Yup.object({
        name: Yup.string("Enter a customer name").required("Required").test('len', 'Too short', val => (val !== undefined && val.trim().length >= 4)),
        address1: Yup.string("Enter street").required("Required").test('len', 'Too short', val => (val !== undefined && val.trim().length >= 4)),
        address2: Yup.string("Enter street").optional(),
        city: Yup.string("Enter street").optional(),
        postcode: Yup.string("Enter street").optional(),
    });

    async function handleSubmit(form) {
        closeErrorBanner();
        setFormLoading(true);

        const docRef = await addDoc(customersConfiguration, {
            creator: auth.currentUser.uid,
            created: serverTimestamp(),
            name: form.name,
            nameidx: form.name.toLowerCase(),
            deleted: false,
            address1: form.address1,
            address2: form.address2,
            city: form.city,
            postcode: form.postcode,
        }).catch((error) => {
            setFormLoading(false);
            showErrorBanner("Error: could not save customer record " + error.message);
            console.log(error);
        });

        // merge user display name into form
        var userDisplayName = "Not set";
        if (props.userinfo && props.userinfo.fname && props.userinfo.lname) {
            userDisplayName = `${props.userinfo.fname} ${props.userinfo.lname}`;
        }

        await addDoc(logConfiguration, {
            user: auth.currentUser.uid,
            dts: serverTimestamp(),
            reference: docRef.id,
            success: true,
            action: "Create customer record",
            detail: `${form.name} created via admin screen`,
            username: userDisplayName
        });

        if (docRef !== null) {
            console.log("Then block " + docRef);
            setFormLoading(false);
            showSuccessDialog();
        }
    }

    // HELPERS

    function showSuccessDialog() {
        setFormLoading(false);
        setSuccessDialogOpen(true);
    }

    function hideSuccessDialog() {
        setSuccessDialogOpen(false);
        navigate('/calibrations/admin/clients'); // outta here
    }

    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    // STATE

    const [formLoading, setFormLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);

    // LIFTING STATE
    
    const handleConfirmDeactivateChange = (newVal) => {
        setSuccessDialogOpen(newVal);

        if (newVal === false) {
            hideSuccessDialog();
        }
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">Create a new customer record using the details below.</p>
                        </div>
                    </div>

                    <ConfirmCreateCustomer open={successDialogOpen} onConfirmDeactivateOpenChange={handleConfirmDeactivateChange} />

                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                        {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values }) => (
                            <form onSubmit={handleSubmit} className="">
                                {/* Calibration details area */}
                                <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Customer information</h3>
                                        {formLoading && <LoadingBanner />}
                                        {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />}
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Name &nbsp;
                                                    <ErrorMessage name="name" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="name" id="name" autoComplete="off" onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Address line 1 &nbsp;
                                                    <ErrorMessage name="address1" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="address1" id="address1" autoComplete="off" onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Address line 2 &nbsp;
                                                    <ErrorMessage name="address2" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="address2" id="address2" placeholder='Optional' autoComplete="off" onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    City &nbsp;
                                                    <ErrorMessage name="city" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="city" id="city" autoComplete="off" placeholder='Optional' onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Postcode &nbsp;
                                                    <ErrorMessage name="postcode" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="postcode" id="postcode" autoComplete="off" placeholder='Optional' onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>

                                {/* Button actions */}
                                <div className="mt-4 flex justify-end">
                                    <Link to="/calibrations/admin/clients">
                                        <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                            Cancel
                                        </button>
                                    </Link>
                                    <button type="submit" disabled={!dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-900">
                                        Save
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>

                </div>
            </div>
        </main>
    )
}