import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import useModel from '../Components/Hooks/usemodel';
import useCrm from '../Components/Hooks/useCrm'
import useCalibration from '../Components/Hooks/usecalibration';
import { formatDateDMYStephen } from '../../Shared/Components/functions.js'

// FIXME props.calibrationGuid will be set, need to fetch the record and then set its values

export default function DetailsOverlay(props) {
    // HOOKS
    const calibrationRecord = useCalibration(props.calibrationGuid);
    const deviceModel = useModel(calibrationRecord.modelGuid);
    const crm = useCrm(calibrationRecord.crmGuid);

    // STATE
    const [open, setOpen] = useState(props.open)
    const [copySuccess, setCopySuccess] = useState(false)

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    // FUNCTIONS

    function printLabel() {
        props.handlePrint();
    }

    function copyCSV() {
        const csvEntry = composeCSV();
        copyTextToClipboard(csvEntry);
    }

    function composeCSV() {
        if (!calibrationRecord) {
            return "";
        }

        var retval = formatDateDMYStephen(calibrationRecord.dts.toDate()) + "\t"; // Calibration date
        retval += "\t"; // could use certificateGuid to look up its serial number
        retval += `${calibrationRecord.certificateCustomerName}\t`; // customer name
        retval += `${calibrationRecord.modelName}\t`; // model name
        retval += (calibrationRecord.unitserial) ? `${calibrationRecord.unitserial}\t` : "\t"; // unit serial

        // use mac address if sensor serial is not set
        if (calibrationRecord.sensorserial) {
            retval += `${calibrationRecord.sensorserial}\t`;
        } else if (calibrationRecord.macaddress) {
            retval += `${calibrationRecord.macaddress}\t`;
        } else {
            retval += "\t";
        }

        retval += `${calibrationRecord.readingscount}\t`; // readings count
        retval += (calibrationRecord.ledStyle !== null && calibrationRecord.ledStyle === true) ? formatLedStyle(calibrationRecord.precalzero) + "\t" : `${calibrationRecord.precalzero} ${calibrationRecord.unitScale}\t`; // pre cal zero
        retval += (calibrationRecord.ledStyle !== null && calibrationRecord.ledStyle === true) ? formatLedStyle(calibrationRecord.precalalcohol) + "\t" : `${calibrationRecord.precalalcohol} ${calibrationRecord.unitScale}\t`; // pre cal alcohol
        retval += `${calibrationRecord.calvalue}\t`; // cal value
        retval += (calibrationRecord.ledStyle !== null && calibrationRecord.ledStyle === true) ? formatLedStyle(calibrationRecord.postcalreading) + "\t" : `${calibrationRecord.postcalreading} ${calibrationRecord.unitScale}\t`; // post cal alcohol
        retval += (calibrationRecord.ledStyle !== null && calibrationRecord.ledStyle === true) ? formatLedStyle(calibrationRecord.postcalzero) + "\t" : `${calibrationRecord.postcalzero} ${calibrationRecord.unitScale}\t`; // post cal zero
        retval += `${calibrationRecord.temperature}\t`; // temperature
        retval += `${calibrationRecord.humidity}\t`; // humidity
        retval += crm.bottlenumber ? crm.bottlenumber : "Unknown\t"; // CRM reference
        retval += "\t"; // this space intentionally left blank
        retval += (calibrationRecord.technicianNote) ? `${calibrationRecord.technicianNote}\t` : "\t"; // technician note

        return retval;
    }

    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            window.alert('Copy ' + msg);
        } catch (err) {
            console.error('Unable to copy', err);
        }

        document.body.removeChild(textArea);
    }
    function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            setCopySuccess(true);
        }, function (err) {
            window.alert('Could not copy text: ', err);
        });
    }

    function formatLedStyle(n) {
        switch (n) {
            case "0":
                return "PASS";
            case "1":
                return "WARN";
            case "2":
                return "FAIL";
            case "3":
                return "WARN/FAIL";
            default:
                return "UNKNOWN";
        }
    }

    function closeDialog() {
        setOpen(false);
        props.onDetailsOpenChange(false);
    }

    if (calibrationRecord === undefined) {
        return undefined;
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500"
                                    onClick={() => closeDialog()}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-isober-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationIcon className="h-6 w-6 text-isober-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Calibration Details
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        {calibrationRecord.authUserName && <p className="text-sm text-gray-500">Calibration performed by {calibrationRecord.authUserName}.</p>}
                                        {!calibrationRecord.authUserName && <p className="text-sm text-gray-500">The details for the selected calibration are as follows:</p>}
                                    </div>

                                    <dl className="sm:divide-y sm:divide-gray-200">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Device model</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {calibrationRecord.modelName ? calibrationRecord.modelName : "—"}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Accuracy specification</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {deviceModel.calrange.calrangeBac !== undefined && <p>{deviceModel.calrange.calrangeBac}</p>}
                                                {deviceModel.calrange.calrangeMgl !== undefined && <p>{deviceModel.calrange.calrangeMgl}</p>}
                                            </dd>
                                        </div>

                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Pre-cal</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                Zero: &nbsp;

                                                {(calibrationRecord.ledStyle === null || calibrationRecord.ledStyle === false) && calibrationRecord.precalzero && calibrationRecord.unitScale && `${calibrationRecord.precalzero} ${calibrationRecord.unitScale}`}
                                                {(calibrationRecord.ledStyle !== null && calibrationRecord.ledStyle === true) && calibrationRecord.precalzero && formatLedStyle(calibrationRecord.precalzero)} <br />

                                                Alcohol: &nbsp;

                                                {(calibrationRecord.ledStyle === null || calibrationRecord.ledStyle === false) && calibrationRecord.precalalcohol && calibrationRecord.unitScale && `${calibrationRecord.precalalcohol} ${calibrationRecord.unitScale}`}
                                                {(calibrationRecord.ledStyle !== null && calibrationRecord.ledStyle === true) && calibrationRecord.precalalcohol && formatLedStyle(calibrationRecord.precalalcohol)} <br />
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Sensor condition</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {calibrationRecord.calvalue ? calibrationRecord.calvalue : "—"}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Post-cal</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                Zero: &nbsp;

                                                {(calibrationRecord.ledStyle === null || calibrationRecord.ledStyle === false) && calibrationRecord.postcalzero && calibrationRecord.unitScale && `${calibrationRecord.postcalzero} ${calibrationRecord.unitScale}`}
                                                {(calibrationRecord.ledStyle !== null && calibrationRecord.ledStyle === true) && calibrationRecord.postcalzero && formatLedStyle(calibrationRecord.postcalzero)} <br />

                                                Alcohol: &nbsp;

                                                {(calibrationRecord.ledStyle === null || calibrationRecord.ledStyle === false) && calibrationRecord.postcalreading && calibrationRecord.unitScale && `${calibrationRecord.postcalreading} ${calibrationRecord.unitScale}`}
                                                {(calibrationRecord.ledStyle !== null && calibrationRecord.ledStyle === true) && calibrationRecord.postcalreading && formatLedStyle(calibrationRecord.postcalreading)} <br />
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Environment</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                Temperature: {calibrationRecord.temperature && `${calibrationRecord.temperature} °C`} <br />
                                                Humidity: {calibrationRecord.humidity && `${calibrationRecord.humidity} %`}
                                            </dd>
                                        </div>

                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Other</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {calibrationRecord.overridePassLimit && <div>Pass/fail limit: {calibrationRecord.overridePassLimit}</div>}
                                                {calibrationRecord.overrideValidityCount && <div>Validity count: {calibrationRecord.overrideValidityCount}</div>}

                                                {!calibrationRecord.overridePassLimit && <div>Pass/fail limit: {deviceModel.presetLimit}</div>}
                                                {!calibrationRecord.overrideValidityCount && <div>Validity count: {deviceModel.validityCount}</div>}
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-600 text-base font-medium text-white hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => closeDialog()}>
                                    Close
                                </button>

                                {!copySuccess && <button type="button" onClick={() => copyCSV()} className="inline-flex justify-center mr-2 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Copy CSV</button>}
                                {copySuccess && <button type="button" onClick={() => copyCSV()} className="inline-flex justify-center mr-2 bg-green-100 py-2 px-4 border border-green-500 rounded-md shadow-sm text-sm font-medium text-green-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Copy CSV</button>}

                                {<button type="button" onClick={() => printLabel()} className="inline-flex justify-center mr-2 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Print label</button>}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}