import React, { useState, useRef } from 'react';
import { collection, query, doc, addDoc, updateDoc, getDoc, arrayRemove, arrayUnion, increment, deleteDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'reactfire';
import { getIdToken } from "firebase/auth";
import ConfirmGenerate from '../Calibrations/confirmgenerate.js';
import CalDetailsOverlay from './caldetailsoverlay.js';
import { LoadingBanner, ErrorBanner } from '../Components/Helpers/index.js';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import FileSaver from "file-saver";
import EditDeviceSerialDialog from './editDeviceSerialDialog.js';
import JobcardAddDeviceDialog from './jobcardAddDeviceDialog.js';
import EnqueueConfirmationDialog from './enqueueConfirmation.js';
import PrintLabelOverlay from '../Calibrations/printdialog';
import cloneDeep from 'lodash/cloneDeep';
import DeviceActionMenu from './DeviceActionMenu.js';
import ConfirmVoid from './confirmvoid';
import ConfirmScrap from './confirmscrap';
import VoidSuccessDialog from './VoidSuccessDialog.js';
import ScrapSuccessDialog from './scrapsuccessdialog.js';
import CalHistoryOverlay from './calhistoryoverlay.js';
import { formatDate } from '../../Shared/Components/functions.js';

export default function JobCardDeviceList({ jobcardId, allowEdits, userinfo, customerName }) {
    // HOOKS
    const auth = useAuth();
    const navigate = useNavigate();
    const selectAllRef = useRef(null);

    // STATE
    const [enqueueSuccessOpen, setEnqueueSuccessOpen] = useState(false)
    const [confirmGenerateOpen, setConfirmGenerateOpen] = useState(false)
    const [confirmScrapOpen, setConfirmScrapOpen] = useState(false)
    const [detailsOverlayOpen, setDetailsOverlayOpen] = useState(false)
    const [calHistoryOverlayOpen, setCalHistoryOverlayOpen] = useState(false)
    const [formLoading, setFormLoading] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);

    // eslint-disable-next-line
    const [isDownloading, setIsDownloading] = useState(false);

    const [editDeviceSerialOpen, setEditDeviceSerialOpen] = useState(false)
    const [addDeviceDialogOpen, setAddDeviceDialogOpen] = useState(false)
    const [editingDeviceSerial, setEditingDeviceSerial] = useState(undefined)
    const [editingDeviceGuid, setEditingDeviceGuid] = useState(undefined)
    const [selectedForGeneration, setSelectedForGeneration] = useState([])
    const [printOverlayOpen, setPrintOverlayOpen] = useState(false)
    const [selectedCalibrationDetails, setSelectedCalibrationDetails] = useState(undefined)
    const [selectedDeviceSerials, setSelectedDeviceSerials] = useState(undefined)
    const [selectedDeviceDocId, setSelectedDeviceDocId] = useState(undefined)
    const [confirmVoidOpen, setConfirmVoidOpen] = useState(false)
    const [voidSuccessDialogOpen, setVoidSuccessDialogOpen] = useState(false)
    const [scrapSuccessDialogOpen, setScrapSuccessDialogOpen] = useState(false)

    // FIRESTORE
    const firestore = useFirestore();
    const devicesCollection = collection(firestore, `jobcards/${jobcardId}/devices`);
    const activeQuery = query(devicesCollection); // serial or time
    const { status, data: devicesRecord } = useFirestoreCollectionData(activeQuery, { idField: 'id', });

    if (status === 'loading') {
        return 'Loading...';
    }

    // FUNCTIONS

    const handlePrintClosed = () => {
        setDetailsOverlayOpen(false);
        setPrintOverlayOpen(false);
        setSelectedCalibrationDetails(undefined);
    }

    const handlePrint = () => {
        setDetailsOverlayOpen(false);
        setPrintOverlayOpen(true);
    }

    function confirmGenerate() {
        setConfirmGenerateOpen(true);
    }

    function openCertificateDetails(selected) {
        setSelectedCalibrationDetails(selected);
        setDetailsOverlayOpen(true);
    }

    function showVoidSuccessDialog() {
        setVoidSuccessDialogOpen(true);
    }

    function showScrapSuccessDialog() {
        setScrapSuccessDialogOpen(true);
    }

    // FORMS

    // this form takes calibrated devices and enqueues certificate generation
    // FIXME: WIP: selectedForGeneration
    async function handleSubmit(form) {
        closeErrorBanner();

        // sanity check
        if (selectedForGeneration.length === 0) {
            window.alert("No devices selected");
            return;
        }

        // thought I saw a double submit despite button being disabled?
        if (formLoading === true) {
            return;
        }

        setFormLoading(true);

        // split calibration ID and device ID
        var payload = {};
        var payloadItems = [];
        selectedForGeneration.forEach((item) => {
            payloadItems.push({ 'calibrationGuid': item });
        });
        payload.calibrations = payloadItems;

        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isoberdev.web.app/cal/enqueue', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => response.json())
                    .then(data => {
                        setFormLoading(false);
                        clearForm();
                        if (data.error) {
                            showErrorBanner(data.message);
                        } else {
                            showSuccessDialog();
                        }
                    });
            })
            .catch((errorJwt) => {
                setFormLoading(false);
                showErrorBanner("Error - invalid auth token " + errorJwt);
            });
    }

    // LIFTING STATE

    const handleConfirmOpenChange = (okToGenerate) => {
        // thought I saw a double submit despite button being disabled?
        if (formLoading === true) {
            return;
        }
        setConfirmGenerateOpen(false);
        if (okToGenerate === true) {
            console.log("OK to generate");
            handleSubmit();
        } else {
            console.log("[UnsignedTab] Canceling generate");
        }
    }

    const handleDetailsOpenChange = (newVal) => {
        setDetailsOverlayOpen(newVal);
        setSelectedCalibrationDetails(undefined);
    }

    const handleAddDevice = async (deviceModel, serialNo, sensorSerial, notes) => {
        setAddDeviceDialogOpen(false);
        closeErrorBanner();

        // all three parameters null is just a cancel button
        if (!deviceModel && !serialNo && !notes) {
            return;
        }

        // validate model
        if (!deviceModel) {
            showErrorBanner("Device model is invalid.")
            return;
        }

        // validate serials
        if (serialNo === undefined && sensorSerial === undefined) {
            showErrorBanner("Device or sensor serial number is invalid.")
            return;
        }

        // check if serial is unique
        if (serialNo !== undefined && !deviceSerialIsUnique(serialNo)) {
            showErrorBanner("Error: device serial number is already on this job card.");
            return;
        }

        // check if sensor serial is unique
        if (sensorSerial !== undefined && !sensorSerialIsUnique(sensorSerial)) {
            showErrorBanner("Error: sensor serial number is already on this job card.");
            return;
        }

        setFormLoading(true);

        // OK to proceed...
        // window.alert(JSON.stringify(deviceModel));

        // a) create device record under job card ID; already have $devicesCollection
        try {
            const ledStyle = (deviceModel.ledStyle && deviceModel.ledStyle === true) ? true : false;
            var newDeviceRecord = { modelId: deviceModel.id, model: deviceModel.modelName, serial: serialNo, sensorSerial: sensorSerial, status: 'Not started', ledStyle: ledStyle };
            if (notes) { newDeviceRecord.note = notes.trim(); }

            // eslint-disable-next-line
            const docRef = await addDoc(devicesCollection, newDeviceRecord);
        } catch (err) {
            setFormLoading(false);
            showErrorBanner("Error: could not save device record " + err.message);
        }

        // b) update job card serial number index with docRef.id
        const jobcardDocRef = await doc(firestore, 'jobcards', jobcardId);
        if (serialNo && !sensorSerial) {
            await updateDoc(jobcardDocRef, {
                deviceIdx: arrayUnion(serialNo),
                numDevices: increment(1)
            });
        } else if (!serialNo && sensorSerial) {
            await updateDoc(jobcardDocRef, {
                deviceIdx: arrayUnion(sensorSerial),
                numDevices: increment(1)
            });
        } else if (serialNo && sensorSerial) {
            await updateDoc(jobcardDocRef, {
                deviceIdx: arrayUnion(serialNo, sensorSerial),
                numDevices: increment(1)
            });
        }

        setFormLoading(false);
    }

    const handleDeviceSerialChange = (deviceGuid, oldSerialNo, newSerialNo) => {
        setEditDeviceSerialOpen(false); // close dialog

        // undefined here has the semantic of just closing the dialog
        if (newSerialNo === undefined) {
            setEditingDeviceSerial(undefined); // clear temp holder
            setEditingDeviceGuid(undefined); // clear temp holder
            return;
        }

        if (newSerialNo === editingDeviceSerial) {
            window.alert("No change, ignoring");
            setEditingDeviceSerial(undefined); // clear temp holder
            setEditingDeviceGuid(undefined); // clear temp holder
            return;
        }

        if (!deviceSerialIsValid(newSerialNo)) {
            window.alert("Invalid serial number");
            setEditingDeviceSerial(undefined); // clear temp holder
            setEditingDeviceGuid(undefined); // clear temp holder
            return;
        }

        if (!deviceSerialIsUnique(newSerialNo)) {
            window.alert("Serial number must be unique on the job card");
            setEditingDeviceSerial(undefined); // clear temp holder
            setEditingDeviceGuid(undefined); // clear temp holder
            return;
        }

        // no issues, then persist to firestore
        setDeviceSerialNumber(deviceGuid, oldSerialNo, newSerialNo);
    }

    // FIRESTORE

    const removeFromJobCard = async (jobcardGuid, deviceGuid, deviceSerial, sensorSerial) => {
        // validate
        let docIdRegex = /^[A-Za-z0-9]{10,30}$/;
        if (!docIdRegex.test(jobcardGuid)) {
            alert("Invalid job card ID");
            return;
        }
        if (!docIdRegex.test(deviceGuid)) {
            alert("Invalid device ID");
            return;
        }

        setFormLoading(true);

        // a) remove the device doc
        const collectionRef = collection(firestore, `jobcards/${jobcardGuid}/devices`);
        const deviceDocRef = doc(collectionRef, deviceGuid);
        await deleteDoc(deviceDocRef);

        // b) update the job card index
        const jobcardDocRef = await doc(firestore, 'jobcards', jobcardGuid);
        if (deviceSerial !== undefined) {
            await updateDoc(jobcardDocRef, {
                deviceIdx: arrayRemove(deviceSerial)
            });
        }
        if (sensorSerial !== undefined) {
            await updateDoc(jobcardDocRef, {
                deviceIdx: arrayRemove(sensorSerial)
            });
        }

        await updateDoc(jobcardDocRef, {
            numDevices: increment(-1)
        });

        setFormLoading(false);
    }

    const setDeviceNote = async (jobcardGuid, deviceGuid, note) => {
        // validate
        let docIdRegex = /^[A-Za-z0-9]{10,30}$/;
        if (!docIdRegex.test(jobcardGuid)) {
            alert(`Invalid job card ID ${jobcardGuid}`);
            return;
        }
        if (!docIdRegex.test(deviceGuid)) {
            alert(`Invalid device ID ${deviceGuid}`);
            return;
        }
        if (!note.trim()) {
            alert("Note is empty");
            return;
        }

        setFormLoading(true);

        const deviceDocRef = await doc(firestore, `jobcards/${jobcardGuid}/devices`, deviceGuid);
        await updateDoc(deviceDocRef, { note: note.trim() })
            .catch((error) => {
                setFormLoading(false);
                showErrorBanner("Error: could not update note: " + error.message);
                return; // don't carry on
            }).then(() => {
                setFormLoading(false);
            });
    }

    const setDeviceSerialNumber = async (deviceGuid, oldSerialNo, newSerialNo) => {
        setFormLoading(true);

        // a) update the device doc
        const deviceDocRef = await doc(firestore, `jobcards/${jobcardId}/devices`, deviceGuid);
        await updateDoc(deviceDocRef, { serial: newSerialNo })
            .catch((error) => {
                setFormLoading(false);
                showErrorBanner("Error: could not update serial number: " + error.message);
                return; // don't carry on
            }).then(() => {
                setFormLoading(false);
            });

        // b) update the job card index
        const jobcardDocRef = await doc(firestore, 'jobcards', jobcardId);
        await updateDoc(jobcardDocRef, {
            deviceIdx: arrayRemove(oldSerialNo)
        });
        await updateDoc(jobcardDocRef, {
            deviceIdx: arrayUnion(newSerialNo)
        });
        setFormLoading(false);
    }

    // HELPERS

    // validates that serial number is valid
    const deviceSerialIsValid = (s) => {
        if (s === null || s === undefined) {
            return false;
        }

        if (s.trim() === "") {
            return false;
        }

        return true;
    }

    // validates that serial number does not already exist on this job card
    const deviceSerialIsUnique = (sn) => {
        var isUnique = true;

        if (devicesRecord.size === 0) {
            return false;
        }

        // iterate devices we've been passed and compare
        devicesRecord.forEach((record) => {
            if (record.serial && sn.trim() === record.serial.trim()) {
                isUnique = false;
            }
        });

        return isUnique;
    }

    // validates that serial number does not already exist on this job card
    const sensorSerialIsUnique = (sn) => {
        var isUnique = true;

        if (devicesRecord.size === 0) {
            return false;
        }

        // iterate devices we've been passed and compare
        devicesRecord.forEach((record) => {
            if (record.sensorSerial && sn.trim() === record.sensorSerial.trim()) {
                isUnique = false;
            }
        });

        return isUnique;
    }

    function showSuccessDialog() {
        setFormLoading(false);
        setEnqueueSuccessOpen(true);
    }

    function hideSuccessDialog() {
        setEnqueueSuccessOpen(false);
    }

    // eslint-disable-next-line
    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    const showEditDeviceSerial = (e, deviceGuid, currentSerial) => {
        e.preventDefault();

        setEditDeviceSerialOpen(true);
        setEditingDeviceSerial(currentSerial);
        setEditingDeviceGuid(deviceGuid);
    }

    const showAddDevice = () => {
        setAddDeviceDialogOpen(true);
    }

    const gotoCalibrate = (jobcardGuid, deviceGuid) => {
        navigate(`/calibrations/calibrate/${jobcardGuid}/${deviceGuid}`); // outta here
    }

    // eslint-disable-next-line
    const trim = (str, ch) => {
        var start = 0,
            end = str.length;

        while (start < end && str[start] === ch)
            ++start;

        while (end > start && str[end - 1] === ch)
            --end;

        return (start > 0 || end < str.length) ? str.substring(start, end) : str;
    }

    // eslint-disable-next-line
    const friendlyForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Company";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";

        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;

            if (alphaNumeric.test(str[i])) {
                retval += str[i];
            } else if (retval.slice(-1) !== "_") {
                retval += "_";
            }
        }

        // remove leading/trailing underscores
        retval = trim(retval, "_");

        // return something if end up with nothing
        if (!retval) {
            return "Company";
        }

        return retval;
    }

    const handleEnqueueConfirm = (b) => {
        hideSuccessDialog();
    }

    const handleVoidConfirm = (b) => {
        setConfirmVoidOpen(false);
    }

    const handleScrapConfirm = (b) => {
        setConfirmScrapOpen(false);
    }

    const selectForGeneration = (e) => {
        const guid = e.target.value;
        const checked = e.target.checked;

        const selectedForGenerationClone = cloneDeep(selectedForGeneration);
        if (checked === true) {
            selectedForGenerationClone.push(guid);
        } else {
            const index = selectedForGenerationClone.indexOf(guid);
            selectedForGenerationClone.splice(index, 1);
        }
        setSelectedForGeneration(selectedForGenerationClone);
    }

    const clearForm = () => {
        setSelectedForGeneration([]);
    }

    const toggleCheckboxes = () => {
        if (selectAllRef.current.checked === true) {
            var retval = [];
            if (devicesRecord.length > 0) {
                devicesRecord.forEach(record => {
                    if (record.calibrationGuid !== undefined && (record.certificategenerated === undefined || record.certificategenerated === false)) {
                        retval.push(record.calibrationGuid);
                    }
                });

                setSelectedForGeneration(retval);
            }
        } else {
            setSelectedForGeneration([]);
        }
    }

    const friendlyCompanyForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Company";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";

        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;

            if (alphaNumeric.test(str[i])) {
                retval += str[i];
            } else if (retval.slice(-1) !== "_") {
                retval += "_";
            }
        }

        // remove leading/trailing underscores
        retval = trim(retval, "_");

        // return something if end up with nothing
        if (!retval) {
            return "Company";
        }

        return retval;
    }

    const friendlySerialForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Number";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";

        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;

            if (alphaNumeric.test(str[i])) {
                retval += str[i];
            } else if (retval.slice(-1) !== "-") {
                retval += "-";
            }
        }

        // remove leading/trailing dashes
        retval = trim(retval, "-");

        // return something if end up with nothing
        if (!retval) {
            return "Number";
        }

        return retval;
    }

    const servePdf = async (guid) => {
        setIsDownloading(true);

        const docRef = doc(firestore, `certificates`, guid);
        const docSnap = await getDoc(docRef);

        // existence check
        if (!docSnap.exists()) {
            window.alert("Not exists");
            return;
        }

        // certificate data should be truthy from here
        const certificate = docSnap.data();

        // compose filename overloaded with fields from the record:
        var friendlyCompanyName = friendlyCompanyForFilename(certificate.certificateCustomerName);
        var friendlyCertificateNo = friendlySerialForFilename(certificate.serial);

        var friendlySerialNo = friendlySerialForFilename(certificate.serialIdx.length > 1 ? `${certificate.serialIdx[0]}_${certificate.serialIdx[1]}` : certificate.serialIdx[0]);
        //var friendlySerialNo = friendlySerialForFilename(certificate.serialIdx[0]);

        var friendlyFileName = `Certificate_${friendlyCompanyName}_${friendlySerialNo}_${friendlyCertificateNo}.pdf`;

        const storage = getStorage();
        getDownloadURL(ref(storage, `certificates/${guid}.pdf`))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    setIsDownloading(false);
                    FileSaver.saveAs(blob, friendlyFileName);
                };
                xhr.onerror = (error) => {
                    setIsDownloading(false);
                    window.alert("Error: could not download file");
                }
                xhr.open('GET', url);
                xhr.send();
            })
            .catch((error) => {
                setIsDownloading(false);
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        window.alert("Error: object not found");
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        window.alert("Error: unauthorized");
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        window.alert("Error: request cancelled");
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        window.alert("Unknown error occurred");
                        break;
                    default:
                        window.alert("Unknown error occurred");
                        break;
                }
            });
    }

    // NB remember that there is also a scrap option in Calibrate's Actions menu
    const handleScrapDevice = async (proceedApproved, reason) => {
        setConfirmScrapOpen(false);

        // if proceed is false then don't proceed
        if (!proceedApproved || proceedApproved === false) {
            setSelectedDeviceDocId(undefined);
            return;
        }

        if (!userinfo || !userinfo.fname || !userinfo.lname) {
            showErrorBanner("Error – user info is not set, can't continue.");
            setSelectedDeviceDocId(undefined);
            return;
        }

        if (!selectedDeviceDocId) {
            showErrorBanner("Error – device ID is not set, can't continue.");
            return;
        }

        // validate user display name
        if (!userinfo || !userinfo.fname || !userinfo.lname) {
            showErrorBanner("Error – user info is not set, can't continue.");
            setSelectedDeviceDocId(undefined);
            return;
        }

        // validate client ID
        if (!userinfo || !userinfo.clientid) {
            showErrorBanner("Error – user info is not set, can't continue.");
            setSelectedDeviceDocId(undefined);
            return;
        }

        closeErrorBanner();
        setFormLoading(true);

        // create payload
        var payload = {
            clientGuid: userinfo.clientid,
            jobcardGuid: jobcardId,
            deviceGuid: selectedDeviceDocId,
            authUserName: `${userinfo.fname} ${userinfo.lname}`,
            reason: reason,
        }

        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isoberdev.web.app/cal/scrap', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(payload)
                })
                    .catch((err) => {
                        setFormLoading(false);
                        showErrorBanner("Error " + err);
                    })
                    .then(response => response.json())
                    .then(data => {
                        setFormLoading(false);
                        if (data.error) {
                            showErrorBanner(data.message);
                        } else {
                            setFormLoading(false);
                            showScrapSuccessDialog();
                        }
                    });
            })
            .catch((errorJwt) => {
                setFormLoading(false);
                showErrorBanner("Error - invalid auth token");
            });
    }

    const handleVoidCalibration = async (proceedApproved, reason) => {
        setConfirmVoidOpen(false);

        // if proceed is false then don't proceed
        if (!proceedApproved || proceedApproved === false) {
            setSelectedCalibrationDetails(undefined);
            return;
        }

        if (!userinfo || !userinfo.fname || !userinfo.lname) {
            showErrorBanner("Error – user info is not set, can't continue.");
            setSelectedCalibrationDetails(undefined);
            return;
        }

        if (!selectedCalibrationDetails) {
            showErrorBanner("Error – device ID is not set, can't continue.");
            setSelectedCalibrationDetails(undefined);
            return;
        }

        closeErrorBanner();
        setFormLoading(true);

        // create payload
        var payload = {
            authUserName: `${userinfo.fname} ${userinfo.lname}`,
            jobcardGuid: jobcardId,
            deviceGuid: selectedCalibrationDetails.deviceId,
            calibrationGuid: selectedCalibrationDetails.calibrationGuid,
            reason: reason,
        }

        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isoberdev.web.app/cal/void', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(payload)
                })
                    .catch((err) => {
                        setFormLoading(false);
                        showErrorBanner("Error " + err);
                    })
                    .then(response => response.json())
                    .then(data => {
                        setFormLoading(false);
                        if (data.error) {
                            showErrorBanner(data.message);
                        } else {
                            setFormLoading(false);
                            showVoidSuccessDialog();
                        }
                    });
            })
            .catch((errorJwt) => {
                setFormLoading(false);
                showErrorBanner("Error - invalid auth token");
            });
    }

    // ITEM HANDLERS (FROM DEVICEACTIONMENU)
    // In the below, deviceId = jobcards.devices.Id

    // NB remember that there is also a scrap option in Calibrate's Actions menu
    const handleScrapItem = (e, deviceId) => {
        e.stopPropagation();

        // hunt and peck; every is like forEach but can exit loop by returning false; make sure to return true
        devicesRecord.every(async (item) => {
            if (item.id === deviceId) {
                await setSelectedDeviceDocId(item.id);
                setConfirmScrapOpen(true);
                return false;
            } else {
                return true;
            }
        });
    }

    const handleCalibrateItem = (e, deviceId) => {
        e.stopPropagation();
        gotoCalibrate(jobcardId, deviceId)
    }

    const handleItemInfo = (e, deviceId) => {
        e.stopPropagation();

        // hunt and peck; every is like forEach but can exit loop by returning false; make sure to return true
        devicesRecord.every((item) => {
            if (item.id === deviceId) {
                openCertificateDetails(item)
                return false;
            } else {
                return true;
            }
        });
    }

    const handleVoidItem = (e, deviceId) => {
        e.stopPropagation();

        // hunt and peck; every is like forEach but can exit loop by returning false; make sure to return true
        devicesRecord.every((item) => {
            if (item.id === deviceId) {
                setSelectedCalibrationDetails({ "deviceId": deviceId, "calibrationGuid": item.calibrationGuid });
                setConfirmVoidOpen(true);
                return false;
            } else {
                return true;
            }
        });
    }

    const handleGenerateItem = (e, deviceId) => {
        e.stopPropagation();
        // todo find function call
    }

    const handleDownloadItem = (e, deviceId) => {
        e.stopPropagation();

        // hunt and peck; every is like forEach but can exit loop by returning false; make sure to return true
        devicesRecord.every((item) => {
            if (item.id === deviceId) {
                servePdf(item.certificateGuid);
                return false;
            } else {
                return true;
            }
        });
    }

    const handlePrintItem = (e, deviceId) => {
        e.stopPropagation();

        // hunt and peck; every is like forEach but can exit loop by returning false; make sure to return true
        devicesRecord.every(async (item) => {
            if (item.id === deviceId) {
                // fetch the calibration record
                const calibration = await getCalibrationRecord(item.calibrationGuid);
                if (calibration !== undefined) {
                    setSelectedCalibrationDetails(calibration);
                    handlePrint();
                } else {
                    window.alert("Calibration record not found");
                }

                return false;
            } else {
                return true;
            }
        });
    }

    const handleRemoveItem = (e, deviceId) => {
        e.stopPropagation();

        // hunt and peck; every is like forEach but can exit loop by returning false; make sure to return true
        devicesRecord.every(async (item) => {
            if (item.id === deviceId) {
                if (window.confirm('Are you sure? This operation cannot be undone.')) {
                    removeFromJobCard(jobcardId, deviceId, item.serial, item.sensorSerial);
                } else {
                    return false;
                }
            } else {
                return true;
            }
        });
    }

    const handleEditNote = (e, deviceId) => {
        e.stopPropagation();

        // show edit dialog; hunt and peck for jobcard device record
        devicesRecord.every(async (item) => {
            if (item.id === deviceId) {
                const newNote = window.prompt("Edit note", (item.note) ? item.note.trim() : "");
                if (newNote.trim()) {
                    setDeviceNote(jobcardId, deviceId, newNote);
                }

                return false;
            } else {
                return true;
            }
        });
    }

    const handleShowHistory = (e, deviceId) => {
        e.stopPropagation();

        // hunt and peck for jobcard device record
        devicesRecord.every(async (item) => {
            if (item.id === deviceId) {
                await setSelectedDeviceSerials({ unit: item.serial, sensor: item.sensorSerial });

                return false;
            } else {
                return true;
            }
        });

        setCalHistoryOverlayOpen(true);
    }

    const handleHideHistory = () => {
        setCalHistoryOverlayOpen(false);
        setSelectedDeviceSerials(undefined);
    }

    const getCalibrationRecord = async (calibrationGuid) => {
        if (!calibrationGuid) {
            return undefined;
        }

        const calibrationDocRef = doc(firestore, `calibrations`, calibrationGuid);
        const docSnap = await getDoc(calibrationDocRef);
        if (!docSnap.exists()) {
            return undefined;
        }

        return docSnap.data();
    }

    // Export the device list to CSV
    const exportToCSV = () => {
        if (!devicesRecord || devicesRecord.length === 0) {
            alert('No data available to export');
            return;
        }

        const headers = "Model,Device serial,Sensor serial,Status,Calibrated,Certificate ID,Note";
        const rows = devicesRecord.map(device => {
            return `${device['model'] || ''},${device['serial'] || ''},${device['sensorSerial'] || ''},${device['status'] || ''},${device['calibrationDts'] ? formatDate(device['calibrationDts'].toDate()) : ''},${device['certificateGuid'] || ''},"${device['note'] || ''}"`;
        }
        ).join('\n');
        const csvContent = `data:text/csv;charset=utf-8,${headers}\n${rows}`;
        const encodedUri = encodeURI(csvContent);
        const friendlyCompanyName = friendlyCompanyForFilename(customerName || jobcardId);
        const fileName = `Devices_${friendlyCompanyName}.csv`;
        FileSaver.saveAs(encodedUri, fileName);
    }

    return (
        <div>
            {confirmGenerateOpen === true && <ConfirmGenerate onConfirmOpenChange={handleConfirmOpenChange} />}
            {selectedCalibrationDetails !== undefined && detailsOverlayOpen === true && <CalDetailsOverlay open={detailsOverlayOpen} onDetailsOpenChange={handleDetailsOpenChange} calibrationGuid={selectedCalibrationDetails.calibrationGuid} handlePrint={handlePrint} />}

            <EditDeviceSerialDialog open={editDeviceSerialOpen} initialValue={editingDeviceSerial} deviceGuid={editingDeviceGuid} handler={handleDeviceSerialChange} />

            <JobcardAddDeviceDialog open={addDeviceDialogOpen} handler={handleAddDevice} />

            <EnqueueConfirmationDialog open={enqueueSuccessOpen} onChange={handleEnqueueConfirm} />

            <ConfirmVoid open={confirmVoidOpen} handler={handleVoidCalibration} />
            <ConfirmScrap open={confirmScrapOpen} handler={handleScrapDevice} />

            <VoidSuccessDialog open={voidSuccessDialogOpen} onChange={handleVoidConfirm} />
            <ScrapSuccessDialog open={scrapSuccessDialogOpen} onChange={handleScrapConfirm} />

            {selectedCalibrationDetails !== undefined && printOverlayOpen === true && <PrintLabelOverlay open={printOverlayOpen} onPrintClosed={handlePrintClosed} item={selectedCalibrationDetails} />}

            {calHistoryOverlayOpen && selectedDeviceSerials && <CalHistoryOverlay isOpen={calHistoryOverlayOpen} handler={handleHideHistory} unitSerial={selectedDeviceSerials.unit} sensorSerial={selectedDeviceSerials.sensor} />}

            <form>
                <div className="mt-6 shadow sm:rounded-md  bg-white px-4 space-y-4 sm:p-4">

                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Devices
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                                {devicesRecord.size === 0 && "This job card contains no devices."}
                                {devicesRecord.size > 0 && "This job card contains the following devices:"}
                            </p>
                        </div>
                        <div className="mt-3 flex sm:mt-0 sm:ml-4">
                            <button type="button" onClick={() => exportToCSV()} className="mr-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                CSV
                            </button>
                            <button type="button" onClick={() => showAddDevice()} className="mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                Add device
                            </button>
                        </div>
                    </div>

                    {formLoading === true && <LoadingBanner />}
                    {errorBannerOpen === true && <ErrorBanner message={lastErrorMessage} />}

                    {devicesRecord.length > 0 && <table className="mt-6 min-w-full divide-y divide-gray-200 table-auto">
                        <thead className="bg-gray-50">
                            <tr key='hdrDevices'>
                                <th scope="col">
                                    <input type="checkbox" ref={selectAllRef} name="selectAll" onClick={(e) => toggleCheckboxes()} className="focus:ring-isober-900 h-4 w-4 text-isober-900 border-gray-300 rounded" />
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Model</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Device serial</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sensor serial</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Job card note</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {devicesRecord
                                .sort((a, b) => {
                                    const serialA = a.serial || ""; // Fallback to empty string if serial is missing
                                    const serialB = b.serial || "";
                                    const sensorSerialA = a.sensorSerial || ""; // Fallback to empty string if sensorSerial is missing
                                    const sensorSerialB = b.sensorSerial || "";

                                    if (serialA === serialB) {
                                        // If serials are equal, sort by sensorSerial
                                        return sensorSerialA.localeCompare(sensorSerialB);
                                    } else {
                                        // Otherwise, sort by serial
                                        return serialA.localeCompare(serialB);
                                    }
                                })
                                .map((record, idx) => (
                                    <tr key={record.id} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        <td>
                                            {/* checked={selectedForGeneration.includes(record.data().calibrationGuid)} onChange={selectForGeneration(record.data().calibrationGuid)} */}
                                            {record.calibrationGuid !== undefined && (record.certificategenerated === undefined || record.certificategenerated === false) && <input checked={selectedForGeneration.includes(record.calibrationGuid)} onChange={(evt) => { selectForGeneration(evt) }} id={`checkbox-${idx}`} name="selectedCalibrations" value={record.calibrationGuid} type="checkbox" className="focus:ring-isober-900 h-4 w-4 text-isober-900 border-gray-300 rounded" />}
                                        </td>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                                            {record.model /* .model */}
                                        </td>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                                            <div>
                                                {record.serial && record.serial}
                                                {!record.serial && "—"} &nbsp;
                                                {(allowEdits && record.status === 'Not started') && record.serial && <a href="#1" onClick={(e) => showEditDeviceSerial(e, record.id, record.serial)} className='text-isober-900'>Edit</a>}
                                            </div>
                                        </td>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                                            <div>
                                                {record.sensorSerial && record.sensorSerial}
                                                {!record.sensorSerial && "—"} &nbsp;
                                                {/*(allowEdits && record.data().status === 'Not started') && <a href="#1" onClick={(e) => showEditDeviceSerial(e, record.id, record.data().serial)} className='text-isober-900'>Edit</a>*/}
                                            </div>
                                        </td>
                                        <td className="px-4 py-2 whitespace-wrap text-sm text-gray-900">
                                            {record.note !== undefined && record.note}
                                            {record.note === undefined && "—"}
                                        </td>
                                        <td className="px-4 py-2 text-sm text-gray-900">
                                            <p id="pricing-plans-0-description-1" className="ml-6 pl-1 text-sm md:ml-0 md:pl-0">
                                                {record.status === 'Repair complete' && <span className="mt-4 px-2 inline-flex items-center rounded-full text-xs font-medium bg-pink-100 text-pink-800"> Awaiting repair quote </span>}
                                                {record.status === 'Not started' && <span className="mt-4 px-2 inline-flex items-center rounded-full text-xs font-medium bg-blue-100 text-blue-800"> Not&nbsp;started </span>}
                                                {record.status === 'In progress' && <span className="mt-4 px-2 inline-flex items-center rounded-full text-xs font-medium bg-blue-100 text-blue-800"> In&nbsp;progress </span>}
                                                {record.status === 'Calibration complete' && <span className="mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> Calibration&nbsp;complete </span>}
                                                {record.status === 'Scrapped' && <span className="mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800"> Scrapped </span>}
                                                {record.status === 'Void' && <span className="mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"> Void </span>}
                                                {record.certificategenerated === true && record.certificateGuid !== undefined && <span className="ml-2 mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800"> Certificate </span>}
                                                {record.dispatchStatus && record.dispatchStatus === "Dispatched" && <span className="ml-2 mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-pink-100 text-pink-800"> Dispatched </span>}
                                            </p>
                                        </td>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                                            {/* fixme: pass allowEdits in */}
                                            <DeviceActionMenu recordId={record.id} calibrationGuid={record.calibrationGuid} certificateGuid={record.certificateGuid} status={record.status} calibrateHandler={handleCalibrateItem} infoHandler={handleItemInfo} voidHandler={handleVoidItem} generateHandler={handleGenerateItem} downloadHandler={handleDownloadItem} printHandler={handlePrintItem} removeHandler={handleRemoveItem} noteHandler={handleEditNote} historyHandler={handleShowHistory} scrapHandler={handleScrapItem} />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>}

                    {devicesRecord.length === 0 && <p>This job card contains no devices.</p>}
                </div>

                {/* Button actions */}
                {selectedForGeneration.length > 0 && <div className="mt-5 flex justify-end">
                    <button type="button" onClick={() => confirmGenerate()} disabled={formLoading} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-900">
                        {selectedForGeneration.length === 1 && "Generate certificate"}
                        {selectedForGeneration.length > 1 && "Generate certificates"}
                    </button>
                </div>}

                {/* end form */}
            </form>

        </div>
    )
}