import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom';

export default function PageHeader(props) {
    var headerTitle = "Stock Control";
    var breadcrumbs = [];

    if (props.location.startsWith("/stock/sku")) {
        headerTitle = "SKU Details";
        breadcrumbs = [{ title: "Dashboard", href: "/stock" }];
    } else if (props.location.startsWith("/stock/category/")) {
        headerTitle = "Category Details";
        breadcrumbs = [{ title: "Admin", href: "/stock/admin" }];
    } else if (props.location.startsWith("/stock/admin/download")) {
        headerTitle = "Download SKU Report";
        breadcrumbs = [{ title: "Admin", href: "/stock/admin" }];
    } else if (props.location.startsWith("/stock/admin/skus")) {
        headerTitle = "Manage SKUs";
        breadcrumbs = [{ title: "Admin", href: "/stock/admin" }];
    } else if (props.location.startsWith("/stock/admin")) {
        headerTitle = "Stock Control Admin";
        breadcrumbs = [];
    }

    return (
        <div>
            <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{headerTitle}</h2>
                </div>
            </div>

            <Breadcrumbs items={breadcrumbs} />
        </div>
    )
}

export function Breadcrumbs(props) {
    if (props.items === null || props.items.length === 0) {
        return <div />;
    }

    return (
        <div>
            {props.items.length >= 1 &&
                <nav className="sm:hidden" aria-label="Back">
                    <Link to={props.items[props.items.length - 1].href} className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
                        <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />Back
                    </Link>
                </nav>
            }

            <nav className="hidden sm:flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-2">
                    {props.items.map((item, i) => {
                        if (props.items.length === 1) {
                            return (<li key={item.title}>
                                <div className="flex items-center">
                                    <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <Link to={item.href} className="text-sm font-medium text-gray-500 hover:text-gray-700">{item.title}</Link>
                                </div>
                            </li>);
                        }
                        if (i > 0) {
                            return (<li key={item.title}>
                                <div className="flex items-center">
                                    <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <Link to={item.href} className="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700">{item.title}</Link>
                                </div>
                            </li>);
                        } else {
                            return (<li key={item.title}>
                                <div className="flex items-center">
                                    <Link to={item.href} className="text-sm font-medium text-gray-500 hover:text-gray-700">{item.title}</Link>
                                </div>
                            </li>);
                        }
                    })
                    }
                </ol>
            </nav>
        </div >
    )
}