import React, { useState } from 'react';
import { ErrorBanner } from '../Helpers/index.js';
import { collection, query, where, orderBy, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { formatDate } from '../../../Shared/Components/functions';
import FileSaver from "file-saver";

export default function CalibrationsByCustomer(props) {
    // FIRESTORE
    const firestore = useFirestore();
    const calibrationCollection = collection(firestore, `calibrations`);
    const activeQuery = query(calibrationCollection, where('clientGuid', '==', props.query), orderBy('dts', 'desc'));
    const { status, data: calevents } = useFirestoreCollection(activeQuery);

    // STATE
    const [isDownloading, setIsDownloading] = useState(false);

    const servePdf = async (certificateGuid) => {
        setIsDownloading(true);
        const docRef = doc(firestore, `certificates`, certificateGuid);
        const docSnap = await getDoc(docRef);

        // existence check
        if (!docSnap.exists()) {
            window.alert("Not exists");
        }

        // certificate data should be truthy from here
        const certificate = docSnap.data();

        /* {
            "serial": "2022/45",
            "deviceGuid": "osvxtCwTKuvGAhnBEIQm",
            "modelName": "EBS 010",
            "calibrationGuid": "nmvq26CQEjesRg5iVMsY",
            "certificateCustomerName": "Alcohol Breathalysers Pty Ltd",
            "created": {
                "seconds": 1665301477,
                "nanoseconds": 460000000
            },
            "customerGuid": "eImAqdAErCKEpmia8gnj",
            "status": "Complete",
            "expiry": {
                "seconds": 1695127801,
                "nanoseconds": 823000000
            },
            "serialIdx": [
                "EBC01010K001"
            ]
        } */

        // compose filename overloaded with fields from the record:
        var friendlyCompanyName = friendlyCompanyForFilename(certificate.certificateCustomerName);
        var friendlyCertificateNo = friendlySerialForFilename(certificate.serial);
        
        var friendlySerialNo = friendlySerialForFilename(certificate.serialIdx.length > 1 ? `${certificate.serialIdx[0]}_${certificate.serialIdx[1]}` : certificate.serialIdx[0]);
        // var friendlySerialNo = friendlySerialForFilename(certificate.serialIdx[0]);
        
        var friendlyFileName = `Certificate_${friendlyCompanyName}_${friendlySerialNo}_${friendlyCertificateNo}.pdf`;

        const storage = getStorage();

        getDownloadURL(ref(storage, `certificates/${certificateGuid}.pdf`))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    setIsDownloading(false);
                    FileSaver.saveAs(blob, friendlyFileName);
                };
                xhr.onerror = (error) => {
                    setIsDownloading(false);
                    window.alert("Error: could not download file");
                }
                xhr.open('GET', url);
                xhr.send();
            })
            .catch((error) => {
                setIsDownloading(false);
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        window.alert("Error: object not found");
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        window.alert("Error: unauthorized");
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        window.alert("Error: request cancelled");
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        window.alert("Unknown error occurred");
                        break;
                    default:
                        window.alert("Unknown error occurred");
                        break;
                }
            });

        setIsDownloading(false);
    }



    // HELPERS

    const friendlyCompanyForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Company";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";

        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;

            if (alphaNumeric.test(str[i])) {
                retval += str[i];
            } else if (retval.slice(-1) !== "_") {
                retval += "_";
            }
        }

        // remove leading/trailing underscores
        retval = trim(retval, "_");

        // return something if end up with nothing
        if (!retval) {
            return "Company";
        }

        return retval;
    }

    const friendlySerialForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Number";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";

        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;

            if (alphaNumeric.test(str[i])) {
                retval += str[i];
            } else if (retval.slice(-1) !== "-") {
                retval += "-";
            }
        }

        // remove leading/trailing dashes
        retval = trim(retval, "-");

        // return something if end up with nothing
        if (!retval) {
            return "Number";
        }

        return retval;
    }

    const trim = (str, ch) => {
        var start = 0,
            end = str.length;

        while (start < end && str[start] === ch)
            ++start;

        while (end > start && str[end - 1] === ch)
            --end;

        return (start > 0 || end < str.length) ? str.substring(start, end) : str;
    }

    function formatLedStyle(n) {
        switch (n) {
            case "0":
                return "PASS";
            case "1":
                return "WARN";
            case "2":
                return "FAIL";
            case "3":
                return "WARN/FAIL";
            default:
                return "UNKNOWN";
        }
    }

    // DONE

    if (status === 'loading') {
        return 'Loading...';
    }

    return (
        <div>
            {calevents.size > 0 && <div>
                <div className="mt-6 font-bold text-gray-900">The following calibrations were found:</div>

                <table className="mt-6 min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr key='headerrow'>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date, model</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Serials</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Measurements</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pre zero</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pre alcohol</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sensor condition</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Post zero</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Post alcohol</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Certificate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {calevents.docs.map((calibrationItem, itemIdx) => (
                            <React.Fragment key={`F_${itemIdx}`}>
                                <tr key={`A_${itemIdx}`} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                    <td className="px-6 pt-2 whitespace-nowrap text-sm text-gray-900">{formatDate(calibrationItem.data().dts.toDate())}</td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                        {calibrationItem.data().unitserial && `Unit: ${calibrationItem.data().unitserial}`}
                                        {calibrationItem.data().sensorserial && `Sensor: ${calibrationItem.data().sensorserial}`}
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().readingscount}</td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                        {(calibrationItem.data().ledStyle === null || calibrationItem.data().ledStyle === false) && calibrationItem.data().precalzero && calibrationItem.data().unitScale && `${calibrationItem.data().precalzero} ${calibrationItem.data().unitScale}`}
                                        {(calibrationItem.data().ledStyle !== null && calibrationItem.data().ledStyle === true) && calibrationItem.data().precalzero && formatLedStyle(calibrationItem.data().precalzero)}
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                        {(calibrationItem.data().ledStyle === null || calibrationItem.data().ledStyle === false) && calibrationItem.data().precalalcohol && calibrationItem.data().unitScale && `${calibrationItem.data().precalalcohol} ${calibrationItem.data().unitScale}`}
                                        {(calibrationItem.data().ledStyle !== null && calibrationItem.data().ledStyle === true) && calibrationItem.data().precalalcohol && formatLedStyle(calibrationItem.data().precalalcohol)}
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().calvalue}</td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                        {(calibrationItem.data().ledStyle === null || calibrationItem.data().ledStyle === false) && calibrationItem.data().postcalzero && calibrationItem.data().unitScale && `${calibrationItem.data().postcalzero} ${calibrationItem.data().unitScale}`}
                                        {(calibrationItem.data().ledStyle !== null && calibrationItem.data().ledStyle === true) && calibrationItem.data().postcalzero && formatLedStyle(calibrationItem.data().postcalzero)}
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                        {(calibrationItem.data().ledStyle === null || calibrationItem.data().ledStyle === false) && calibrationItem.data().postcalreading && calibrationItem.data().unitScale && `${calibrationItem.data().postcalreading} ${calibrationItem.data().unitScale}`}
                                        {(calibrationItem.data().ledStyle !== null && calibrationItem.data().ledStyle === true) && calibrationItem.data().postcalreading && formatLedStyle(calibrationItem.data().postcalreading)}
                                    </td>
                                    <td rowSpan={2}>
                                        {calibrationItem.data().certificategenerated && calibrationItem.data().certificateGuid && calibrationItem.data().certificategenerated === true &&
                                            <button onClick={() => servePdf(calibrationItem.data().certificateGuid)} disabled={isDownloading} className="disabled:opacity-50 ml-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                                Download
                                            </button>}

                                        {(!calibrationItem.data().certificategenerated || !calibrationItem.data().certificateGuid) && <p className='whitespace-nowrap text-sm text-gray-900'>Not generated</p>}
                                    </td>
                                </tr>
                                <tr key={`B_${itemIdx}`} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                    <td className="px-6 pb-2 whitespace-nowrap text-sm text-gray-900">
                                        <span className='font-bold'>{calibrationItem.data().modelName}</span>
                                    </td>
                                    <td></td>
                                    <td colSpan={6} className="px-6 pb-2 whitespace-nowrap text-sm text-gray-900">
                                        {calibrationItem.data().technicianNote && calibrationItem.data().technicianNote}
                                        {!calibrationItem.data().technicianNote && <span className='italic'>No note</span>}
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>}

            {calevents.size === 0 && <ErrorBanner message='No devices match your customer search.' />}
        </div>
    )
}