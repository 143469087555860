import React from 'react';
import { ErrorBanner } from '../Components/Helpers/index.js';
import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { formatDate } from '../../Shared/Components/functions.js';

export default function CalibrationsByDeviceSerials(props) {
    // FIRESTORE
    const firestore = useFirestore();
    const calibrationCollection = collection(firestore, `calibrations`);
    const activeQuery = query(calibrationCollection, where('serialidx', 'array-contains-any', props.query));
    const { status, data: calevents } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return 'Loading...';
    }

    return (
        <div>

            {calevents.size === 0 && <ErrorBanner message='No devices match your serial number search.' />}

            {calevents.size > 0 && <div>
                {props.heading && <div className="mt-4 font-bold text-gray-900">{props.heading}</div>}

                <table className="mt-6 min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr key='headerrow'>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date, model</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cal count</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pre zero</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pre alcohol</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sensor condition</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Post zero</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Post alcohol</th>
                        </tr>
                    </thead>
                    <tbody>
                        {calevents.docs.map((calibrationItem, itemIdx) => (
                            <React.Fragment key={`F_${itemIdx}`}>
                                <tr key={`A_${itemIdx}`} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                    <td className="px-6 pt-2 whitespace-nowrap text-sm text-gray-900">{formatDate(calibrationItem.data().dts.toDate())}</td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().readingscount}</td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().precalzero}</td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().precalalcohol}</td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().calvalue}</td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().postcalzero}</td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().postcalreading}</td>
                                </tr>
                                <tr key={`B_${itemIdx}`} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                    <td className="px-6 pb-2 whitespace-nowrap text-sm text-gray-900">
                                        <span className='font-bold'>{calibrationItem.data().modelName}</span>
                                    </td>
                                    <td colSpan={6} className="px-6 pb-2 whitespace-nowrap text-sm text-gray-900">
                                        {calibrationItem.data().technicianNote && calibrationItem.data().technicianNote}
                                        {!calibrationItem.data().technicianNote && <span className='italic'>No note</span>}
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>}

        </div>
    )
}