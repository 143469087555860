import React from 'react';
import Hero from './Components/Hero/index';
import Footer from './Components/Footer/index';
import Pricing from './Components/Pricing/index';
import Content from './Components/Content/index';
import FeaturesSocial from './Sections/FeaturesSocial';
import FeaturesEnterprise from './Sections/FeaturesEnterprise';
import BrandPanel from './Components/Brandpanel/index';
import Devices from './Components/Devices';

function LandingPage(props) {

    return (
        <div>
            <Hero />
            <Content />
            <FeaturesSocial />
            <FeaturesEnterprise />
            <Devices />
            <BrandPanel />
            <Pricing />
            <Footer />
        </div>
    )
}

export default LandingPage;