import React, { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid'
import { Link, useParams } from 'react-router-dom';
import ReadingChip from '../Components/ReadingChip/index.js'
import QuickChip from '../Components/ReadingChip/quickchip.js'
import { formatDate } from '../../Shared/Components/functions.js'
import useClientUsers from '../Components/UserInfo/clientusers.js';
import { usePaginatedUserReadings } from './paginatedreadings.js';
import { EmptyStateReadings } from '../Components/emptystates.js';
import UserReadingsMap from './userreadingsmap.js';

export default function ReadingsByUser(props) {
    const resultsPerPage = 10;
    const { userId } = useParams();
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedClientReadings()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);

    // get current user's userinfo record from its context
    const userinfoData = props.userinfo;

    // get map of users in the account to map reading userids to their names
    const clientUsers = useClientUsers(userinfoData.clientid);

    // this component can be called by a client admin where :userId is set,
    // or it can be called by a normal user role where user id comes from userinfo record
    const userIdPrecicate = (userId !== null && userId !== undefined) ? userId : userinfoData.NO_ID_FIELD;

    // set up firestore query
    const collectionPath = `clients/${userinfoData.clientid}/readings`;
    const readings = usePaginatedUserReadings(collectionPath, userIdPrecicate, resultsPerPage, referenceItem, include);
    const currentLastVisibleItem = (readings !== undefined) ? readings.docs[readings.size - 1] : null;

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, readings.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (readings !== undefined && readings.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (readings !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, readings]);

    // create a structure of readings to pass into the map component
    const thisPageReadings = readings.docs.map((reading) => {
        if (reading.data().location !== null && reading.data().location !== undefined && reading.data().location.latitude !== null && reading.data().location.longitude !== null) {
            const displayName = (clientUsers.has(reading.data().useruid)) ? clientUsers.get(reading.data().useruid) : "No name";
            return { "id": reading.id, "pos": { "lat": reading.data().location.latitude, "lng": reading.data().location.longitude }, "dts": reading.data().created, "level": reading.data().baclevel, "scale": reading.data().bacscale, "name": displayName };
        }
        return reading.id;
    });

    return (
        <div>
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="mt-2 text-sm text-gray-500">Welcome to iSober. Your most recent measurements appear below. Select a reading to view detailed information about each measurement.</p>
                </div>
            </div>

            {   // empty state
                readings.docs.length === 0 && <EmptyStateReadings />
            }

            <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-md">
                {readings === undefined && "Loading"}
                <ul className="divide-y divide-gray-200">
                    {readings !== undefined && readings.docs.map((reading) => (

                        <li key={reading.id}>
                            <Link to={`/app/reading/${reading.id}`} className="block hover:bg-gray-50">
                                <div className="flex items-center px-4 py-4 sm:px-6">
                                    <div className="min-w-0 flex-1 flex items-center">
                                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                            <div>
                                                <p className="text-sm font-medium text-isober-900 truncate">
                                                    {clientUsers.has(reading.data().useruid) === true && clientUsers.get(reading.data().useruid)}
                                                    {clientUsers.has(reading.data().useruid) !== true && "No name"}
                                                </p>
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    <span className="truncate">{formatDate(reading.data().created.toDate())}</span>
                                                </p>
                                            </div>
                                            <div className="hidden md:block">
                                                <div>
                                                    <p className="text-sm text-gray-900">
                                                        {reading.data().placemark && reading.data().placemark.name && reading.data().placemark.name}
                                                        {(!reading.data().placemark || !reading.data().placemark.name) && "—"}
                                                    </p>
                                                    <p className="mt-2 flex items-center text-sm text-gray-500">
                                                        {reading.data().subject && reading.data().subject.name && `Testing ${reading.data().subject.name}`}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {reading.data().quickmode !== undefined && reading.data().quickresult !== undefined && reading.data().quickmode === true && <QuickChip result={reading.data().quickresult} /> }
                                    {(reading.data().quickmode === undefined || (reading.data().quickmode !== undefined && reading.data().quickmode === false)) && <ReadingChip value={reading.data().baclevel} unit={reading.data().bacscale} /> }

                                    <div>
                                        <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                </div>
                            </Link>
                        </li>
                    ))
                    }
                </ul>
            </div>

            {readings.docs.length > 0 && /* pagination start */
                <nav
                    className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{(firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>

                        <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            }

            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="px-3 py-6 text-lg font-medium text-gray-900">Map Overview</span>
                </div>
            </div>

            <UserReadingsMap readings={thisPageReadings} />
        </div>
    )
}