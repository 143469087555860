import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

const categories = [
    { name: 'breathalyser' },
    { name: 'accessory' },
    { name: 'drug tester' },
    { name: 'hygiene product' },
    { name: 'crm' },
    { name: 'parts' },
]

export function CategoryDropdown({ initialValue, handler, sfv, sft }) {
    // STATE
    const [selected, setSelected] = useState((initialValue) ? {category: {name: initialValue}} : null);

    // HELPERS

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    function dropdownChanged(newValue) {
        setSelected(newValue);
        handler(newValue, sfv, sft);
    }

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div className="mt-4 text-sm text-gray-900">
            <Listbox value={selected} onChange={dropdownChanged}>
                {({ open }) => (
                    <>
                        <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500 sm:text-sm">
                                <span className="block truncate">{(selected === null || selected.category === null) ? 'Choose category...' : capitalize(selected.category.name) }</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </Listbox.Button>

                            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {categories.map((category) => (
                                        <Listbox.Option key={category.name} className={({ active }) =>
                                            classNames(active ? 'text-white bg-isober-600' : 'text-gray-900', 'text-left cursor-default select-none relative py-2 pl-8 pr-4')
                                        }
                                            value={{ category }}>
                                            {({ selected, active }) => (
                                                <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                        {capitalize(category.name)}
                                                    </span>

                                                    {selected ? (
                                                        <span className={classNames(active ? 'text-white' : 'text-isober-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5')}>
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        </div>
    )
}