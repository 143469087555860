import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
import { formatDate } from '../../Shared/Components/functions.js';
import { Formik, ErrorMessage } from 'formik';
import { getIdToken } from "firebase/auth";
import { useAuth } from 'reactfire';
import * as Yup from "yup"

export default function UserTab(props) {
    // hooks
    // const currentLocation = useLocation();
    const auth = useAuth();

    // state
    const [userDetails, setUserDetails] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);
    const [noSuchUserDialogOpen, setNoSuchUserDialogOpen] = useState(false);
    const [userDetailsDialogOpen, setUserDetailsDialogOpen] = useState(false);

    // formik
    const searchInitialValues = { email: "" };
    const searchSchema = Yup.object({
        email: Yup.string("Enter a valid email address").email("Invalid").required("Required")
    });

    // functions

    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    function hideDialog() {
        setNoSuchUserDialogOpen(false);
    }

    function hideUserDetailsDialog() {
        setUserDetailsDialogOpen(false);
    }

    function showUserDetailsDialog(userDetails) {
        setUserDetails(userDetails);
        setUserDetailsDialogOpen(true);
    }

    async function handleSubmit(form) {
        closeErrorBanner();
        setFormLoading(true);

        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isober.co.za/api/socialsearch', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(form)
                })
                    .then(response => response.json())
                    .then(data => {
                        setFormLoading(false);
                        if (data.error) {
                            showErrorBanner(data.message);
                        } else {
                            alert(JSON.stringify(data));
                            showUserDetailsDialog(data.user);
                        }
                    })
                    .catch((errorJson) => {
                        // auth/user-not-found
                        setFormLoading(false);
                        showErrorBanner("Error: " + errorJson);
                    });
            })
            .catch((errorJwt) => {
                showErrorBanner("Error - invalid auth token");
            });
    }

    return (
        <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Search by user email address</h3>
                {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Subtitle</p>*/}
            </div>
            <div className="border-t border-gray-200">
                <div className="py-8 px-8">

                    {/* User details modal dialog */}
                    {userDetailsDialogOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                            {/*  This element is to trick the browser into centering the modal contents. */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                        {/* Heroicon name: outline/check */}
                                        <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                        </svg>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                            {(userDetails === null || userDetails.email === null || userDetails.email === undefined) && "Email address not set"}
                                            {(userDetails !== null && userDetails.email !== null) && userDetails.email}
                                        </h3>


                                        <div className="flex-shrink-0 group block">
                                            <div className="my-4 flex justify-center">
                                                <div className="flex items-center">
                                                    <div>
                                                        <svg className="h-10 w-8 overflow-hidden text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                        </svg>
                                                        {(userDetails !== null && userDetails.photoUrl !== null) && <img className="inline-block h-10 w-10 rounded-md" src="{userDetails.photoUrl}" alt="" />}
                                                    </div>
                                                    <div className="ml-3">
                                                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                                            {(userDetails === null || userDetails.displayName === null || userDetails.displayName === undefined) && "Display name not set"}
                                                            {(userDetails !== null && userDetails.displayName !== null) && userDetails.displayName}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="text-sm text-gray-500">
                                            <span className="font-medium">Date created: </span>
                                            {(userDetails === null || userDetails.createdDate === null || userDetails.createdDate === undefined) && "not set"}
                                            {(userDetails !== null && userDetails.createdDate !== undefined) && formatDate(new Date(userDetails.createdDate))}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            <span className="font-medium">Last sign-in: </span>
                                            {(userDetails === null || userDetails.lastSigninDate === null || userDetails.lastSigninDate === undefined) && "not set"}
                                            {(userDetails !== null && userDetails.lastSigninDate !== undefined) && formatDate(new Date(userDetails.lastSigninDate))}
                                        </p>

                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button onClick={hideUserDetailsDialog} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none sm:text-sm">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {/* Update user modal confirmation */}
                    {noSuchUserDialogOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                            {/*  This element is to trick the browser into centering the modal contents. */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                A user with the specified email address was not found.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button onClick={hideDialog} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none sm:text-sm">
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {/* Error banner */}
                    {errorBannerOpen && <div className="sm:col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700">{lastErrorMessage}</p>
                            </div>
                        </div>
                    </div>
                    }

                    <p>Enter an email address below to search. <span className="text-gray-500">Note: all requests for personally-identifying information are logged.</span></p>

                    {/* Loading spinner */}
                    {formLoading &&
                        <div className="mt-4 sm:col-span-6">
                            <img src="/images/loading.gif" width="40" height="40" alt="" className="mb-4" />
                        </div>
                    }

                    <Formik initialValues={searchInitialValues} onSubmit={handleSubmit} validationSchema={searchSchema} >
                        {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values }) => (
                            <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">

                                <div className="mt-4">
                                    <ErrorMessage name="email" component="span" className="text-red-700 italic" />
                                    <div className="m-0 relative rounded-md shadow-sm">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                            </svg>
                                        </div>

                                        <input type="email" name="email" id="email" onChange={handleChange} onBlur={handleBlur} className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="you@example.com" />
                                    </div>
                                </div>

                                <div className="mt-6 sm:border-t sm:border-gray-200">
                                    <div className="pt-4 flex justify-end">
                                        <button type="submit" disabled={!dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Search</button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}