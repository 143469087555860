import React from 'react';

export default function Devices(props) {
    return (
        <div className="bg-white">
            <div className="mx-auto py-4 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-4">
                <div className="space-y-12">
                    <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Compatible breathalysers</h2>

                    <ul className="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0">
                        {/* Device 1 */}
                        <li>
                            <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                                <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                                    <img className="object-cover shadow-lg rounded-lg" src="/images/iblow10d.png" alt="" />
                                </div>
                                <div className="sm:col-span-2">
                                    <div className="space-y-4">
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>iBlow 10 Pro</h3>
                                            <p className="text-isober-900">Industry and Law enforcement</p>
                                        </div>
                                        <div className="text-lg">
                                            <p className="text-gray-500">The iBlow10 Pro is our latest no-contact high-speed alcohol tester for testing the breath of motorists at road blocks, drivers in fleets & employees entering workplaces.</p>
                                        </div>
                                        <button type="button" className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-isober-900 bg-isober-100 hover:bg-isoberblue-50 focus:outline-none focus:border-isoberblue-300 focus:shadow-outline-isoberblue active:bg-isoberblue-200 transition ease-in-out duration-150">
                                            <a href="https://breathalysers.co.za/collections/law-enforcement/products/breathalyser-iblow10-pro-with-ble-for-industry-and-law-enforcement" target="_new">Details</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                        {/* Device 2 */}
                        <li>
                            <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                                <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                                    <img className="object-cover shadow-lg rounded-lg" src="/images/isobers-web.png" alt="" />
                                </div>
                                <div className="sm:col-span-2">
                                    <div className="space-y-4">
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>iSober S Pro</h3>
                                            <p className="text-isober-900">Small Business and Personal Use</p>
                                        </div>
                                        <div className="text-lg">
                                            <p className="text-gray-500">Offers the highest accuracy levels available for portable alcohol testers with a long calibration cycle and is suitable for use in the workplace and as a high-end personal breathalyser. Compatible with iOS and Android.</p>
                                        </div>
                                        <button type="button" className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-isober-900 bg-isober-100 hover:bg-isoberblue-50 focus:outline-none focus:border-isoberblue-300 focus:shadow-outline-isoberblue active:bg-isoberblue-200 transition ease-in-out duration-150">
                                            <a href="https://breathalysers.co.za/collections/small-business/products/isobers-pro-ble-premium-breathalyser" target="_new">Details</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>

                        {/* Device 3 */}
                        <li>
                            <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                                <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                                    <img className="object-cover shadow-lg rounded-lg" src="/images/alp1-web.png" alt="" />
                                </div>
                                <div className="sm:col-span-2">
                                    <div className="space-y-4">
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>Alcoscan ALP1 BT</h3>
                                            <p className="text-isober-900">Industry and Law enforcement</p>
                                        </div>
                                        <div className="text-lg">
                                            <p className="text-gray-500">Fully portable breathalyser designed for Industry and law enforcement. Can operate both in a passive no-mouthpiece fast mode for quick road side testing or for entry point screening. Compatible with Android only.</p>
                                        </div>
                                        <button type="button" className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-isober-900 bg-isober-100 hover:bg-isoberblue-50 focus:outline-none focus:border-isoberblue-300 focus:shadow-outline-isoberblue active:bg-isoberblue-200 transition ease-in-out duration-150">
                                            <a href="https://breathalysers.co.za/collections/law-enforcement/products/alcoscan-alp-1-bt" target="_new">Details</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>                        

                        {/* Device 4 */}
                        <li>
                            <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                                <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                                    <img className="object-cover shadow-lg rounded-lg" src="/images/al8800-web.png" alt="" />
                                </div>
                                <div className="sm:col-span-2">
                                    <div className="space-y-4">
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>Alcoscan AL8800BT Compact</h3>
                                            <p className="text-isober-900">Personal Use</p>
                                        </div>
                                        <div className="text-lg">
                                            <p className="text-gray-500">Provides high-accuracy breathalyser tests with a long calibration cycle, all in a small, light device which is suitable for use in the workplace or as a high-end personal breathalyser. Compatible with iOS and Android.</p>
                                        </div>
                                        <button type="button" className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-isober-900 bg-isober-100 hover:bg-isoberblue-50 focus:outline-none focus:border-isoberblue-300 focus:shadow-outline-isoberblue active:bg-isoberblue-200 transition ease-in-out duration-150">
                                            <a href="https://breathalysers.co.za/collections/small-business/products/isobers-pro-ble-premium-breathalyser" target="_new">Details</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    )
}