import React from 'react';

function BrandPanel(props) {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-0 px-4 sm:px-6 lg:px-8">
                <div className="bg-isober-600 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
                    <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                        <div className="lg:self-center">
                            <h2 className="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10">
                                <span className="block">Ready to try iSober?</span>
                            </h2>

                            <p className="mt-4 text-lg leading-6 text-white">Download the mobile app for iOS, Huawei, or Android.</p>

                            <a href="https://apps.apple.com/us/app/isober/id1457499083" className="mt-8 inline-flex"><img src="images/appstore.png" alt="App Store" width="165" height="55" /></a>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="https://play.google.com/store/apps/details?id=za.co.breathalysers.isober" className="mt-8 inline-flex"><img src="images/playstore.png" alt="Google Play" width="165" height="55" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrandPanel;