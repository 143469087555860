import React, { useState, useEffect } from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { useLocation } from 'react-router-dom';
import { LoadingBanner } from '../Components/Helpers/index.js';
import { formatDate } from '../../Shared/Components/functions.js';
import { usePaginatedLogs } from './usePaginatedLogs.js';
import FilterDialog from './filterdialog.js';
import LogDetailDialog from './LogDetailDialog.js';

import { LogDetailCalibration, LogDetailCreateCustomer, LogDetailReceive, LogDetailReassign, LogDetailScrap, LogDetailAmend, LogDetailCrm } from './LogDetails.js';

export default function LogViewerPage(props) {
    const resultsPerPage = 10;

    // STATE
    const [formLoading /*, setFormLoading */] = useState(false);
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedJobcards()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [filterOpen, setFilterOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [selectedUserGuid, setSelectedUserGuid] = useState(undefined);
    
    const [selectedLogEntry, setSelectedLogEntry] = useState(undefined);
    const [showLogDetail, setShowLogDetail] = useState(false);
    const [showLogDetailCalibration, setShowLogDetailCalibration] = useState(false);
    const [showLogDetailReceive, setShowLogDetailReceive] = useState(false);
    const [showLogDetailCreateCustomer, setShowLogDetailCreateCustomer] = useState(false);
    const [showLogDetailReassign, setShowLogDetailReassign] = useState(false);
    const [showLogDetailScrap, setShowLogDetailScrap] = useState(false);
    const [showLogDetailAmend, setShowLogDetailAmend] = useState(false);
    const [showLogDetailCrm, setShowLogDetailCrm] = useState(false);
    

    // HOOKS
    const currentLocation = useLocation();
    const logevents = usePaginatedLogs(selectedUserGuid, referenceItem, include, resultsPerPage);
    const currentLastVisibleItem = (logevents !== undefined) ? logevents.docs[logevents.size - 1] : null;

    // PAGINATION HELPERS

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, logevents.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    const showFilter = () => {
        setFilterOpen(true);
    }

    const clearFilter = () => {
        setSelectedUser(undefined);
        setSelectedUserGuid(undefined);
    }

    const closeFilter = (user) => {
        if (!user) {
            return;
        }

        setSelectedUser(user);
        setSelectedUserGuid(user.id);
        setFilterOpen(false);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (logevents !== undefined && logevents.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (logevents !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, logevents]);

    const showEntryDetail = (logEntry) => {
        if (logEntry === undefined) {
            window.alert("Invalid log entry");
            return;
        }

        if (logEntry.action === 'Calibration') {
            setSelectedLogEntry(logEntry);
            setShowLogDetailCalibration(true);
        } else if (logEntry.action.startsWith('Receive units')) {
            setSelectedLogEntry(logEntry);
            setShowLogDetailReceive(true);
        } else if (logEntry.action === 'Create customer record') {
            setSelectedLogEntry(logEntry);
            setShowLogDetailCreateCustomer(true);
        } else if (logEntry.action === 'Reassign unit to client') {
            setSelectedLogEntry(logEntry);
            setShowLogDetailReassign(true);
        } else if (logEntry.action === 'Scrap unit') {
            setSelectedLogEntry(logEntry);
            setShowLogDetailScrap(true);
        } else if (logEntry.action === 'Amend certificate customer name') {
            setSelectedLogEntry(logEntry);
            setShowLogDetailAmend(true);
        } else if (logEntry.action === 'Create CRM' || logEntry.action === 'Deactivate CRM') {
            setSelectedLogEntry(logEntry);
            setShowLogDetailCrm(true);
        } else {
            setShowLogDetail(true);
        }
    }

    const entryDetailCloseHandler = () => {
        setSelectedLogEntry(undefined);

        setShowLogDetailCalibration(false);
        setShowLogDetailReceive(false);
        setShowLogDetailCreateCustomer(false);
        setShowLogDetailReassign(false);
        setShowLogDetailScrap(false);
        setShowLogDetailAmend(false);
        setShowLogDetailCrm(false);
        setShowLogDetail(false);
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">

            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <div className="pb-3 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            {!selectedUser && <p className="mt-2 max-w-4xl text-sm text-gray-500">Showing log events for all users.</p>}
                            {selectedUser && <p className="mt-2 max-w-4xl text-sm text-isober-900">Showing log events for {selectedUser.fname} {selectedUser.lname}: <button type="button" onClick={clearFilter} className="rounded-full bg-white mx-1 px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Clear</button></p>}
                        </div>
                        <div className="mt-2 flex sm:mt-0 sm:ml-4">
                            <button type="button" onClick={() => showFilter()} className="mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                Filter
                            </button>
                        </div>
                    </div>

                    {formLoading === true && <LoadingBanner />}

                    {logevents.size === 0 && <div className="mt-5 sm:col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700">No log events found.</p>
                            </div>
                        </div>
                    </div>
                    }

                    {filterOpen && <FilterDialog closeHandler={closeFilter} />}
                    {showLogDetail && <LogDetailDialog closeHandler={entryDetailCloseHandler} />}
                    {showLogDetailCalibration && <LogDetailCalibration open={showLogDetailCalibration} closeHandler={entryDetailCloseHandler} logEntry={selectedLogEntry} />}
                    {showLogDetailReceive && <LogDetailReceive open={showLogDetailReceive} closeHandler={entryDetailCloseHandler} logEntry={selectedLogEntry} />}
                    {showLogDetailCreateCustomer && <LogDetailCreateCustomer open={showLogDetailCreateCustomer} closeHandler={entryDetailCloseHandler} logEntry={selectedLogEntry} />}
                    {showLogDetailReassign && <LogDetailReassign open={showLogDetailReassign} closeHandler={entryDetailCloseHandler} logEntry={selectedLogEntry} />}
                    {showLogDetailScrap && <LogDetailScrap open={showLogDetailScrap} closeHandler={entryDetailCloseHandler} logEntry={selectedLogEntry} />}
                    {showLogDetailAmend && <LogDetailAmend open={showLogDetailAmend} closeHandler={entryDetailCloseHandler} logEntry={selectedLogEntry} />}
                    {showLogDetailCrm && <LogDetailCrm open={showLogDetailCrm} closeHandler={entryDetailCloseHandler} logEntry={selectedLogEntry} />}

                    {logevents.size > 0 &&
                        <table className="mt- min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logevents.docs.map((item, logeventIdx) => (
                                    <tr key={item.id} className={logeventIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{formatDate(item.data().dts.toDate())}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {item.data().username && item.data().username}
                                            {!item.data().username && <span className='italic'>Not set</span>}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.data().action}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {item.data().success === true && "Success"}
                                            {item.data().success === false && "Error"}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            <div className="flex-shrink-0">
                                                <button onClick={() => showEntryDetail(item.data())} className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                                    Event details
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }

                    {/* pagination area */}
                    {logevents.docs.length > 0 &&
                        <nav
                            className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                            <div className="hidden sm:block">
                                <p className="text-sm text-gray-700">
                                    Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{(firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                                </p>
                            </div>
                            <div className="flex-1 flex justify-between sm:justify-end">
                                {firstItemStack.length > 1 &&
                                    <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                                }

                                <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>
                                <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                            </div>
                        </nav>
                    }
                </div>
            </div>
        </main>
    )
}