import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';

export default function useClientInfo(clientId) {

    // set up firestore query
    const firestore = useFirestore();
    const clientInfoDocRef = doc(firestore, 'clients', clientId);
    const { status, data: clientinfo } = useFirestoreDocDataOnce(clientInfoDocRef, {
        idField: 'clientid', // merge id into object created for each document
    });

    if (status === 'loading') {
        return undefined;
    }

    return clientinfo;
}