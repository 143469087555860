import React, { useState } from 'react';
import PageHeader from './PageHeader';
import { useLocation } from 'react-router-dom';
import { TableIcon } from '@heroicons/react/solid';
import DashboardTab from './DashboardTab';

const tabs = [
    { name: 'Breathalysers', icon: TableIcon },
    { name: 'Accessories', icon: TableIcon },
    { name: 'Drug test kits', icon: TableIcon },
    { name: 'Hygiene', icon: TableIcon },
    { name: 'CRM', icon: TableIcon },
    { name: 'Parts', icon: TableIcon },
]

function DashboardPage(props) {
    const [currentTab, setCurrentTab] = useState('Breathalysers');
    var [viewMode, setViewMode] = useState(0); // 0=table, 1=grid

    // HOOKS
    const currentLocation = useLocation();

    // HELPERS

    const viewModeGrid = () => {
        setViewMode(1);
    }

    const viewModeTable = () => {
        setViewMode(0);
    }

    function selectTab(e, tabName) {
        e.preventDefault();

        setCurrentTab(tabName);
    }

    function dropdownChange(e) {
        setCurrentTab(e.target.value);
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div>
                    {/* start tab control */}
                    <div>
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">Select a tab</label>
                            <select id="tabs" name="tabs" onChange={dropdownChange}
                                className="block mt-4 mb-2 w-full focus:ring-isober-500 focus:border-isober-500 border-gray-300 rounded-md"
                                defaultValue={tabs.find((tab) => tab.name).name}>
                                {tabs.map((tab) => (
                                    <option key={tab.name}>{tab.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <div className="border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                    {tabs.map((tab) => (
                                        <a key={tab.name} href="#0" onClick={(e) => selectTab(e, tab.name)}
                                            className={classNames(
                                                tab.name === currentTab
                                                    ? 'border-isober-500 text-isober-600'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                                            )}
                                            aria-current={tab.name === currentTab ? 'page' : undefined}>
                                            <tab.icon
                                                className={classNames(
                                                    tab.name === currentTab ? 'text-isober-500' : 'text-gray-400 group-hover:text-gray-500',
                                                    '-ml-0.5 mr-2 h-5 w-5'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span>{tab.name}</span>
                                        </a>
                                    ))}
                                </nav>

                                <div className="mt-3 flex sm:mt-0 sm:ml-4">
                                    {viewMode === 0 && <button type="button" onClick={viewModeGrid} className="mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                        Grid
                                    </button>}
                                    {viewMode === 1 && <button type="button" onClick={viewModeTable} className="mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                        Table
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end tab control */}

                    {(currentTab === "Breathalysers") && <DashboardTab category='breathalyser' viewMode={viewMode} />}
                    {(currentTab === "Accessories") && <DashboardTab category='accessory' viewMode={viewMode} />}
                    {(currentTab === "Drug test kits") && <DashboardTab category='drug tester' viewMode={viewMode} />}
                    {(currentTab === "Hygiene") && <DashboardTab category='hygiene product' viewMode={viewMode} />}
                    {(currentTab === "CRM") && <DashboardTab category='crm' viewMode={viewMode} />}
                    {(currentTab === "Parts") && <DashboardTab category='parts' viewMode={viewMode} />}
                </div>
            </div>
        </main >
    )
}

export default DashboardPage;