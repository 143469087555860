import React, { useState } from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from "yup"
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { useFirestore, useAuth } from 'reactfire';
import { LoadingBanner, ErrorBanner } from '../Components/Helpers/index.js';
import ConfirmCreateDevice from './confirmcreatedevice.js';

export default function CreateDevicePage(props) {
    // HOOKS
    const currentLocation = useLocation();
    const auth = useAuth();
    const navigate = useNavigate();
    const firestore = useFirestore();
    const devicesConfiguration = collection(firestore, 'devicemodels');

    // STATE

    const [formLoading, setFormLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);

    // FORMS

    const initialValues = { gasType: 'Wet', presetLimit: '', manufacturer: 'Sentech', displayname: '', calrangeGl: '', calrangeMgl: '', calrangeBac: '', ledStyle: false };
    const validationSchema = Yup.object({
        manufacturer: Yup.string("Enter manufacturer").required("Required"),
        displayname: Yup.string("Enter model").required("Required"),
        calrangeGl: Yup.string("Enter range").required("Required"),
        calrangeMgl: Yup.string("Enter range").required("Required"),
        calrangeBac: Yup.string("Enter range").required("Required"),
        validityCount: Yup.number("Enter number").required("Required").min(0).max(200000).typeError('A number is required'),
        presetLimit: Yup.string("Enter limit").required("Required"),
        gasType: Yup.string("Enter gas type").required("Required"),
        ledStyle: Yup.bool("Enter device kind").optional()
    });

    async function handleSubmit(form) {
        closeErrorBanner();
        setFormLoading(true);

        var calrange = {}
        if (form.calrangeGl !== null && form.calrangeGl !== "") {
            calrange.calrangeGl = form.calrangeGl;
        }
        if (form.calrangeMgl !== null && form.calrangeMgl !== "") {
            calrange.calrangeMgl = form.calrangeMgl;
        }
        if (form.calrangeBac !== null && form.calrangeBac !== "") {
            calrange.calrangeBac = form.calrangeBac;
        }

        const ledStyle = form.ledStyle === true ? true : false;

        const docRef = await addDoc(devicesConfiguration, {
            creator: auth.currentUser.uid,
            created: serverTimestamp(),
            deleted: false,
            manufacturer: form.manufacturer,
            displayname: form.displayname,
            calrange: calrange,
            validityCount: form.validityCount,
            presetLimit: form.presetLimit,
            gasType: form.gasType,
            ledStyle: ledStyle
        }).catch((error) => {
            setFormLoading(false);
            showErrorBanner("Error: could not save device " + error.message);
            console.log(error);
        })

        if (docRef !== undefined) {
            setFormLoading(false);
            showSuccessDialog();
        }
    }

    // HELPERS

    function showSuccessDialog() {
        setFormLoading(false);
        setSuccessDialogOpen(true);
    }

    function hideSuccessDialog() {
        setSuccessDialogOpen(false);
        navigate('/calibrations/admin/devices'); // outta here
    }

    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    // LIFTING STATE

    const handleConfirmChange = (newVal) => {
        setSuccessDialogOpen(newVal);

        if (newVal === false) {
            hideSuccessDialog();
        }
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <ConfirmCreateDevice open={successDialogOpen} onConfirmOpenChange={handleConfirmChange} />

                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                        {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values }) => (
                            <form onSubmit={handleSubmit} className="">

                                {/* Device area */}
                                <div className="mt-6 bg-white shadow overflow-y-visible sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Enter device details</h3>
                                        {formLoading && <LoadingBanner />}
                                        {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />}
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Manufacturer:&nbsp;
                                                    <ErrorMessage name="manufacturer" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="manufacturer" id="manufacturer" defaultValue={initialValues.manufacturer} autoComplete="off" onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Model:&nbsp;
                                                    <ErrorMessage name="displayname" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="displayname" id="displayname" autoComplete="off" onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Accuracy range:&nbsp;
                                                    <ErrorMessage name="calrangeBac" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input type="text" name="calrangeBac" id="calrangeBac" onChange={handleChange} onBlur={handleBlur}
                                                            className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                % BAC
                                                            </span>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Accuracy range:&nbsp;
                                                    <ErrorMessage name="calrangeGl" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input type="text" name="calrangeGl" id="calrangeGl" onChange={handleChange} onBlur={handleBlur}
                                                            className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                g/L
                                                            </span>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Accuracy range:&nbsp;
                                                    <ErrorMessage name="calrangeMgl" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input type="text" name="calrangeMgl" id="calrangeMgl" onChange={handleChange} onBlur={handleBlur}
                                                            className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                mg/L
                                                            </span>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Validity:&nbsp;
                                                    <ErrorMessage name="validityCount" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input type="text" name="validityCount" id="validityCount" defaultValue={initialValues.validityCount} onChange={handleChange} onBlur={handleBlur}
                                                            className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                tests
                                                            </span>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Pre-set PASS/FAIL limit: &nbsp; <ErrorMessage name="presetLimit" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="presetLimit" id="presetLimit" autoComplete="off" onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    CRM type: &nbsp; <ErrorMessage name="gasType" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 py-2">
                                                        <div class="flex items-center">
                                                            <input id="wet" name="gasType" value="Wet" type="radio" onChange={handleChange} onBlur={handleBlur} checked={initialValues.gasType === 'Wet'} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                                <label for="email" class="ml-3 block text-sm font-medium text-gray-700"> Wet gas </label>
                                                        </div>

                                                        <div class="flex items-center">
                                                            <input id="dry" name="gasType" value="Dry" type="radio" onChange={handleChange} onBlur={handleBlur} checked={initialValues.gasType === 'Dry'} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                                <label for="sms" class="ml-3 block text-sm font-medium text-gray-700"> Dry gas </label>
                                                        </div>

                                                        <div class="flex items-center">
                                                            <input id="na" name="gasType" value="N/A" type="radio" onChange={handleChange} onBlur={handleBlur} checked={initialValues.gasType === 'N/A'} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                                <label for="push" class="ml-3 block text-sm font-medium text-gray-700"> N/A </label>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Device indicators: &nbsp; <ErrorMessage name="ledStyle" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 py-2">
                                                        <div class="flex items-center">
                                                            <input id="ledStyle" name="ledStyle" value={true} type="checkbox" defaultChecked={initialValues.ledStyle} onChange={handleChange} onBlur={handleBlur} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                                <label for="ledStyle" class="ml-3 block text-sm text-gray-700"> Use LED indicators </label>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>

                                {/* Button actions */}
                                <div className="mt-4 flex justify-end">
                                    <Link to="/calibrations/admin/devices">
                                        <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                            Cancel
                                        </button>
                                    </Link>
                                    <button type="submit" disabled={formLoading || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-900">
                                        Save device
                                    </button>
                                </div>

                            </form>
                        )}
                    </Formik>

                </div>
            </div>
        </main>
    )
}