import React from 'react';
import Breadcrumbs from '../Breadcrumbs/index.js';

export default function PageHeader(props) {
    var headerTitle = "Calibrations Dashboard";
    var breadcrumbs = [];

    if (props.location && props.location.startsWith("/calibrations/client")) {
        headerTitle = "Client Dashboard"
        breadcrumbs = [{title: "Back to Dashboard", href: "/calibrations/"}];
    } else if (props.location && props.location.startsWith("/calibrations/receiving")) {
        headerTitle = "Receive Units"
        // breadcrumbs = [];
    } else if (props.location && props.location.startsWith("/calibrations/dispatch/card")) {
        headerTitle = "Dispatch job card details"
        breadcrumbs = [{title: "Back to Dashboard", href: "/calibrations/"}];
    } else if (props.location && props.location.startsWith("/calibrations/dispatch")) {
        headerTitle = "Dispatch"
        // breadcrumbs = [];
    } else if (props.location && props.location.startsWith("/calibrations/search")) {
        headerTitle = "Search"
        // breadcrumbs = [];
    } else if (props.location && props.location.startsWith("/calibrations/calibrate")) {
        headerTitle = "Calibrate a device"
        // breadcrumbs = [];
    } else if (props.location && props.location.startsWith("/calibrations/calibrations")) {
        headerTitle = "Calibrations history"
        // breadcrumbs = [];
    } else if (props.location && props.location.startsWith("/calibrations/certificates")) {
        headerTitle = "Certificates history"
        // breadcrumbs = [];
    } else if (props.location && props.location.startsWith("/calibrations/admin/logviewer")) {
        headerTitle = "System log viewer"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin/devices/create")) {
        headerTitle = "Add new device model"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}, {title: "Manage devices", href: "/calibrations/admin/devices"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin/devices/model")) {
        headerTitle = "Device model information"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}, {title: "Manage devices", href: "/calibrations/admin/devices"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin/devices/edit")) {
        headerTitle = "Edit device model"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin/devices")) {
        headerTitle = "Manage devices"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin/lost/create")) {
        headerTitle = "Add lost or stolen device"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}, {title: "Lost and stolen devices", href: "/calibrations/admin/lost"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin/lost")) {
        headerTitle = "Manage lost and stolen devices"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin/crm/create")) {
        headerTitle = "Add new certified reference material"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}, {title: "Manage Certified Reference Material", href: "/calibrations/admin/crm"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin/crm/edit")) {
        headerTitle = "Edit certified reference material"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}, {title: "Manage Certified Reference Material", href: "/calibrations/admin/crm"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin/crm/view")) {
        headerTitle = "View certified reference material"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}, {title: "Manage Certified Reference Material", href: "/calibrations/admin/crm"}];
    }  else if (props.location && props.location.startsWith("/calibrations/admin/crm")) {
        headerTitle = "Manage certified reference material"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin/clients/create")) {
        headerTitle = "Add new customer"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}, {title: "Customer directory", href: "/calibrations/admin/clients"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin/clients/edit")) {
        headerTitle = "Edit customer details"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}, {title: "Customer directory", href: "/calibrations/admin/clients"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin/clients/")) {
        headerTitle = "Customer details"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}, {title: "Customer directory", href: "/calibrations/admin/clients"}];
    } else if (props.location && props.location === "/calibrations/admin/clients") {
        headerTitle = "Customer directory"
        breadcrumbs = [{title: "Module administration", href: "/calibrations/admin"}];
    } else if (props.location && props.location.startsWith("/calibrations/admin")) {
        headerTitle = "Module administration"
        // breadcrumbs = [];
    }

    return (
        <div>
            <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{headerTitle}</h2>
                </div>
            </div>

            <Breadcrumbs items={breadcrumbs} />
        </div>
    )
}