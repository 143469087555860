import React from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { Routes, Route, useLocation } from 'react-router-dom';
import JobCardLocator from './jobcardlocator.js';
import CalibrateDevice from './calibratedevice.js';

export default function CalibratePage(props) {
    const currentLocation = useLocation();

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}
                    
                    <Routes>
                        <Route path="*" element={<JobCardLocator userinfo={props.userinfo} />} />
                        <Route path=":jobcardId/:deviceId" element={<CalibrateDevice userinfo={props.userinfo} />} />
                    </Routes>

                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}