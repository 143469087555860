import { doc } from 'firebase/firestore';
import { useFirestoreDocData, useFirestore } from 'reactfire';

// hook to get clientid, fname, lname from firestore userinfo
// via: import useUserInfo from './Components/UserInfo';
//      const wakaX = useUserInfo(authUserData);

export default function useUserInfo(userGuid) {
    console.log("[useUserInfo] for " + userGuid);

    // a parent component contains a `FirebaseAppProvider`
    const userInfoDocRef = doc(useFirestore(), 'userinfo', userGuid);
    const { status, data: userinfo } = useFirestoreDocData(userInfoDocRef);

    if (status === 'loading') {
        return undefined;
    }
    
    return userinfo;
}