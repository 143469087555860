import { collection, query, orderBy, limit, startAfter, startAt, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function useSocialSerial(deviceserial, startItem, include, rpp) {
    const firestore = useFirestore();
    const readingsCollection = collection(firestore, 'clients/GbWKhyO2B4LqhYnnw0rS/readings');

    var activeQuery = undefined;
    if (startItem === null) {
        activeQuery = query(readingsCollection, where('deviceserial', '==', deviceserial), orderBy('created', 'desc'), limit(rpp));
    } else {
        activeQuery = (include) ? query(readingsCollection, where('deviceserial', '==', deviceserial), orderBy('created', 'desc'), limit(rpp), startAt(startItem)) : query(readingsCollection, where('deviceserial', '==', deviceserial), orderBy('created', 'desc'), limit(rpp), startAfter(startItem));
    }

    const { status, data: readings } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return readings;
}
