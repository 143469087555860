import React, { useState, useEffect } from 'react';

export const Base64Image = ({ base64Data }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (base64Data) {
      const blob = b64toBlob(base64Data, 'image/jpeg');
      const imageUrl = URL.createObjectURL(blob);
      setImageUrl(imageUrl);
    }
  }, [base64Data]);

  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  return (
    <dd className="mt-1 text-sm text-gray-900">
      {imageUrl && <img className='w-[10rem]' src={imageUrl} alt="Signature" />}
      {!imageUrl && "(Not set)"}
    </dd>
  );
}