import React from 'react';
import { useSigninCheck } from 'reactfire';
import SignInPage from '../Application/Components/SignInPage/index.js';
import { useUser } from 'reactfire';
import StockHome from './StockHome';

export default function AuthCheck() {
    const { status, data: signInCheckResult } = useSigninCheck();
    const { data: authUser } = useUser();

    if (status === 'loading') {
        return "LOADING (AuthCheck)...";
    } 
    
    if (signInCheckResult.signedIn === true && authUser != null && authUser.uid != null) {
        console.log("[AuthCheck] will render StockHome");
        return <StockHome authUser={authUser} />;
    } else {
        console.log("[AuthCheck] will render SignInPage");
        return <SignInPage />;
    }
}