import React from 'react';
import { Link } from 'react-router-dom';
import ClientCard from './clientcard.js';
import useClientList from './useclientlist.js';

export default function ClientListPage() {
    const clientList = useClientList();

    return (
        <div>
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500">Below is a list of clients loaded on the iSober Cloud platform.</p>
                </div>
                <div className="mt-3 flex sm:mt-0 sm:ml-4">
                    <Link to="create">
                        <button type="button" className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Create&nbsp;client</button>
                    </Link>
                </div>
            </div>

            <ul className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {clientList.map((client) => (
                // ignoring social users client
                (client.id !== 'GbWKhyO2B4LqhYnnw0rS') && <ClientCard key={client.id} client={client} />
            ))}
        </ul>

        </div>
    )
}