import React from 'react';
import PageHeader from './PageHeader';
import { useLocation, Link } from 'react-router-dom';

const categories = [
    { name: 'breathalyser', display: 'Breathalysers' },
    { name: 'accessory', display: 'Accessories' },
    { name: 'drug tester', display: 'Drug tester' },
    { name: 'hygiene product', display: 'Hygiene products' },
    { name: 'crm', display: 'CRM' },
    { name: 'parts', display: 'Parts' },
]

export default function AdminPage({ idToken }) {
    // STATE
    // const [showEditSkuModal, setShowEditSkuModal] = useState(false);

    // HOOKS
    const currentLocation = useLocation();

    // HELPERS

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                    <div>
                        <p className="mt-2 max-w-4xl text-sm text-gray-500">The following categories are loaded on the Stock Control system:</p>
                    </div>
                    <div className="mt-3 flex sm:mt-0 sm:ml-4">
                        {/*<button type="button" onClick={openAddSkuModal} className="mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                            Add Category
                        </button>*/}
                    </div>
                </div>

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}

                    {categories.length > 0 && <ul className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                        {categories.map((item, deviceIdx) => (
                            <Link to={`/stock/category/${item.name}`} key={`a_${deviceIdx}`}>
                                <li key={`li_${deviceIdx}`} className="col-span-1 bg-isober-900 rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                                        <div className="flex-1 truncate">
                                            <div className="flex justify-center space-x-3">
                                                <span className="text-white text-md font-medium truncate">{item.display}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </Link>
                        ))}
                    </ul>}

                    <div className='mt-4 font-underline'>
                        <Link to='./download' className='underline'>Download Excel stock report</Link>
                    </div>

                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}