import React, { useState } from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LoadingBanner, ErrorBanner } from '../Components/Helpers/index.js';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from "yup"
import ConfirmCreateLost from './confirmcreatelost.js';
import { useFirestore, useAuth } from 'reactfire';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

export default function CreateLostDevicePage(props) {
    // HOOKS

    const currentLocation = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const firestore = useFirestore();
    const devicesConfiguration = collection(firestore, 'loststolenunits');

    // FORMS

    const initialValues = { sensorserial: '', deviceserial: '', notes: '' };
    const validationSchema = Yup.object({
        sensorserial: Yup.string("Enter a unit serial number").required("Required").test('len', 'Too short', val => (val !== undefined && val.trim().length >= 4)),
        deviceserial: Yup.string("Enter a device serial number").required("Required").test('len', 'Too short', val => (val !== undefined && val.trim().length >= 4)),
        notes: Yup.string("Enter notes").optional()
    });

    async function handleSubmit(form) {
        closeErrorBanner();
        setFormLoading(true);

        const docRef = await addDoc(devicesConfiguration, {
            creator: auth.currentUser.uid,
            created: serverTimestamp(),
            sensorserial: form.sensorserial,
            deviceserial: form.deviceserial,
            notes: form.notes,
            deleted: false
        }).catch((error) => {
            setFormLoading(false);
            showErrorBanner("Error: could not save lost/stolen device " + error.message);
            console.log(error);
        })

        if (docRef !== null) {
            console.log("Then block " + docRef);
            setFormLoading(false);
            showSuccessDialog();
        }
    }

    // HELPERS

    function showSuccessDialog() {
        setFormLoading(false);
        setSuccessDialogOpen(true);
    }

    function hideSuccessDialog() {
        setSuccessDialogOpen(false);
        navigate('/calibrations/admin/lost'); // outta here
    }

    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    // STATE

    const [formLoading, setFormLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);

    // LIFTING STATE
    
    const handleConfirmDeactivateChange = (newVal) => {
        setSuccessDialogOpen(newVal);

        if (newVal === false) {
            hideSuccessDialog();
        }
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">Log a device as lost or stolen using the information below.</p>
                        </div>
                    </div>

                    <ConfirmCreateLost open={successDialogOpen} onConfirmDeactivateOpenChange={handleConfirmDeactivateChange} />

                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                        {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values }) => (
                            <form onSubmit={handleSubmit} className="">
                                {/* Calibration details area */}
                                <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Device information</h3>
                                        {formLoading && <LoadingBanner />}
                                        {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />}
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Sensor serial number &nbsp;
                                                    <ErrorMessage name="sensorserial" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="sensorserial" id="sensorserial" autoComplete="off" onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Unit serial number &nbsp;
                                                    <ErrorMessage name="deviceserial" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="deviceserial" id="deviceserial" autoComplete="off" onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500"
                                                >Notes &nbsp;
                                                    <ErrorMessage name="notes" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="notes" id="notes" autoComplete="off" onChange={handleChange} onBlur={handleBlur} placeholder='Optional' className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>

                                {/* Button actions */}
                                <div className="mt-4 flex justify-end">
                                    <Link to="/calibrations/admin/lost">
                                        <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                            Cancel
                                        </button>
                                    </Link>
                                    <button type="submit" disabled={!dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-900">
                                        Save
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>

                </div>
            </div>
        </main>
    )
}