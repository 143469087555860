import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useFirestore } from 'reactfire';
import { serverTimestamp, updateDoc, doc } from 'firebase/firestore';

export default function DeactivateSkuModal({ handler, idToken, sku=undefined }) {
    // STATE
    const [open, setOpen] = useState(true)

    // HOOKS
    const firestore = useFirestore();

    // HELPERS

    const cancelPressed = () => {
        setOpen(false);
        handler(undefined);
    }

    const deactivatePressed = async () => {
        // the part that works
        // {"sku":"AL2500","warning":"26","enabled":true,"category":"breathalyser","created":{"seconds":1704366481,"nanoseconds":935000000},"disabled":false,"id":"KztxyzOgZ9vqn7M3NVuN"}
        if (sku && sku.id) {
            window.alert("SKU is set, will deactivate");
            const docReference = doc(firestore, "stock", sku.id);
            await updateDoc(docReference, {
                enabled: false,
                disabledAt: serverTimestamp(),
                // FIXME disabledBy: serverTimestamp(),
            })
                .catch((error) => {
                    window.alert("Error uploading " + error);
                });
        }

        // on completion
        setOpen(false);
        handler(undefined);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => { }}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 overflow-y-visible">

                                <div>
                                    <div className="text-center">
                                        <Dialog.Title as="h3" className="justify-left text-left text-base font-semibold leading-6 text-gray-900">
                                            Deactivate a SKU
                                        </Dialog.Title>

                                        <div className="text-left relative mt-5">
                                            <p className="text-gray-900">Are you sure you want to deactivate the SKU <span className='font-medium'>{sku.sku}</span>?</p>
                                            <p className="mt-2 text-gray-900">This cannot be undone.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button type="button" onClick={() => deactivatePressed()} className="disabled:opacity-50 disabled:bg-isober-1000 inline-flex w-full justify-center rounded-md bg-isober-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-isober-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-isober-800 sm:col-start-2">
                                        Deactivate
                                    </button>
                                    <button type="button" onClick={() => cancelPressed()} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}