import React from 'react';
import LogsList from './LogsList.js';
import { PageHeaderAlt } from '../Components/PageHeader/index.js';
import { useLocation } from 'react-router-dom';

export default function LogsPage(props) {
    const currentLocation = useLocation();

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeaderAlt location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}
                    <div className="py-4">
                        <LogsList />
                    </div>
                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}