import { useState } from 'react'
import { useParams } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { PencilIcon } from '@heroicons/react/solid'
import AlertsById from './alertsbyid';
import { Link } from 'react-router-dom';

export default function AlertDetails(props) {
    const { alertId } = useParams();
    const clientId = props.userinfo.clientid;

    // set up firestore query for the document
    const firestore = useFirestore();
    const configCollectionPath = `clients/${clientId}/alertsconfig`;
    const alertsCollectionPath = `clients/${clientId}/alerts`;
    const documentRef = doc(firestore, configCollectionPath, alertId);
    const { status, data: alert } = useFirestoreDocData(documentRef);

    // state
    const [alertEnabled, setAlertEnabled] = useState(alert.enabled);

    async function toggleAlert(e) {
        e.preventDefault();

        const newValue = !alertEnabled;
        setAlertEnabled(newValue);

        // validate to catch potential issues
        if (!alertId || !clientId) {
            return;
        }

        // change state in firestore
        await updateDoc(documentRef, { "enabled": newValue });

        // FIXME log this event
        // See https://firebase.google.com/docs/firestore/manage-data/add-data#add_a_document
    }

    const formatThreshold = (t) => {
        if (t === 'L_red') {
            return 'Greater than 0.24 Mg/l';
        } else if (t === 'L_amber') {
            return 'Up to 0.24 Mg/l';
        } else {
            return 'Any positive reading';
        }
    }

    function usersuidlookup(guid) {
        if (guid === '*') {
            return "Any user";
        }

        if (props.clientUsers.has(guid)) {
            return <div>{props.clientUsers.get(guid)}</div>;
        } else {
            return <div>—</div>;
        }
    }

    function formatTime(time) {
        if (time.match(/[0-9][0-9]:[0-9][0-9]/)) {
            return (parseInt(time, 10) >= 12) ? time + " PM" : time + " AM"
        }

        return "—";
    }

    if (status === 'loading') {
        return <span>Loading...</span>;
    }

    return (
        <div>
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500">The alert details appear below.
                        {alertEnabled === true && " This alert is currently enabled; select the Disable button to pause this alert."}
                        {alertEnabled === false && " This alert is currently disabled; select the Enable button to resume this alert."}
                    </p>
                </div>
                <div className="mt-3 flex sm:mt-0 sm:ml-4">
                    {alertEnabled === true && <button type="button" onClick={(e) => toggleAlert(e)} className="whitespace-nowrap ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-700 hover:bg-yellow-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >Disable</button>}
                    {alertEnabled === false && <button type="button" onClick={(e) => toggleAlert(e)} className="whitespace-nowrap ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >Enable</button>}
                    <Link to={`/app/alerts/edit/${alertId}`}>
                        <button type="button" className="whitespace-nowrap ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Edit details
                            <PencilIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
                        </button>
                    </Link>
                </div>
            </div>

            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6 flex items-center justify-between">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 inline-flex items-center">Alert Details</h3>

                    {alert.enabled && alert.enabled === true &&
                        <span className="inline-flex items-center flex-shrink-0 inline-block px-2 py-0.5 text-sm rounded-full bg-green-100 text-green-800 inline-flex items-center">
                            <svg className="mr-1.5 h-2 w-2 text-green-800" fill="currentColor" viewBox="0 0 8 8"><circle cx={4} cy={4} r={3} /></svg>
                            Alert is active
                        </span>
                    }

                    {alert.enabled === false &&
                        <span className="flex-shrink-0 inline-block px-2 py-0.5 text-sm rounded-full bg-gray-100 text-gray-800 inline-flex items-center">Alert is disabled</span>
                    }
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Send an alert to</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {typeof alert.recipient === 'string' ? usersuidlookup(alert.recipient) : alert.recipient.map((item) => { return usersuidlookup(item) })}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">When a reading by</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {typeof alert.subject === 'string' ? usersuidlookup(alert.subject) : alert.subject.map((item) => { return usersuidlookup(item) })}
                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Threshold</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {formatThreshold(alert.threshold)}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Notify by</dt>
                            <dd className="mt-1 text-sm text-gray-900">Push notification</dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Measurement time is at or after</dt>
                            <dd className="mt-1 text-sm text-gray-900">{(alert.notbefore) ? formatTime(alert.notbefore) : "—"}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Measurement time is before</dt>
                            <dd className="mt-1 text-sm text-gray-900">{(alert.notafter) ? formatTime(alert.notafter) : "—"}</dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Day of week is</dt>
                            <dd className="mt-1 text-sm text-gray-900">{(alert.weekday) ? alert.weekday : "Any"}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Created by</dt>
                            <dd className="mt-1 text-sm text-gray-900">{typeof alert.createdby === 'string' ? usersuidlookup(alert.createdby) : "Not set"}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="px-3 py-6 text-lg font-medium text-gray-900">Recently triggered measurements</span>
                </div>
            </div>

            <AlertsById collectionPath={alertsCollectionPath} alertuid={alertId} clientusers={props.clientUsers} />

        </div>
    )
}