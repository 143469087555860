import React from 'react';
import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import MapCanvas from './mapcanvas.js';
import { formatDate } from '../../Shared/Components/functions.js'
import { useParams, Link } from 'react-router-dom';
import ReadingChip from '../Components/ReadingChip/index.js'
import QuickChip from '../Components/ReadingChip/quickchip.js'
import CalibrationChip from '../Components/ReadingChip/calibrationchip.js'
import ReadingPhoto from '../Components/ReadingPhoto';
import useUserInfo from '../Components/UserInfo';
import { ImageErrorBoundary } from '../../Shared/Components/errorboundary.js';

// https://github.com/JustFly1984/react-google-maps-api
// https://react-google-maps-api-docs.netlify.app

export default function ReadingDetails(props) {
    const userinfoData = props.userinfo;

    // get reading :id from router
    const { readingId } = useParams();

    // set up firestore query for the document
    const firestore = useFirestore();
    const collectionPath = `clients/${userinfoData.clientid}/readings`;
    const documentRef = doc(firestore, collectionPath, readingId);
    const { status, data: reading } = useFirestoreDocData(documentRef);
    const readingUserinfo = useUserInfo(reading.useruid);

    if (status === 'loading') {
        return <span>Loading...</span>;
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Reading Details</h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">User full name</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {
                                (userinfoData.role === 'superadmin' ||  userinfoData.role === 'client') && 
                                    <Link to={`/app/readings/user/${reading.useruid}`} className="underline text-isober-700 hover:text-isober-900">{`${readingUserinfo.fname} ${readingUserinfo.lname}`}</Link>
                            }
                            {readingUserinfo !== undefined && (userinfoData.role !== 'superadmin' &&  userinfoData.role !== 'client') &&
                                `${readingUserinfo.fname} ${readingUserinfo.lname}`
                            }
                        </dd>
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Email address</dt>
                        <dd className="mt-1 text-sm text-gray-900">{readingUserinfo !== undefined && readingUserinfo.email}</dd>
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Measurement</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <div className="flex overflow-hidden -space-x-1 mr-3">
                                {reading.quickmode !== undefined && reading.quickresult !== undefined && reading.quickmode === true && <QuickChip result={reading.quickresult} /> }
                                {(reading.quickmode === undefined || (reading.quickmode !== undefined && reading.quickmode === false)) && <ReadingChip value={reading.baclevel} unit={reading.bacscale} /> }

                                {reading.calibration && reading.calibration === "After" && <CalibrationChip />}
                            </div>
                        </dd>
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Date and time</dt>
                        <dd className="mt-1 text-sm text-gray-900">{formatDate(reading.created.toDate())}</dd>
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Breathalyser model</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {reading.devicemodel !== undefined && reading.devicemodel}
                            {reading.devicemodel === undefined && "Not set"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Breathalyser serial no.</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {reading.deviceserial !== undefined && reading.deviceserial}
                            {reading.deviceserial === undefined && "Not set"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Handset model</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {reading.handset !== undefined && reading.handset}
                            {reading.handset === undefined && "Not set"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">iSober version</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {reading.client !== undefined && reading.client}
                            {reading.client === undefined && "Not set"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Approximate address</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {reading.placemark && reading.placemark.name !== undefined && reading.placemark.name}
                            {(reading.placemark === undefined || reading.placemark.name === undefined) && "Not set"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Location</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {reading.location && reading.location.latitude !== undefined && reading.location.latitude.toFixed(5) + " / " + reading.location.longitude.toFixed(5)}
                            {(reading.location === undefined || reading.location.latitude === undefined) && "Not set"}
                        </dd>
                    </div>

                    <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">Map</dt>
                        <dd className="mt-1 text-sm text-gray-900 h-96">
                            {reading.location && reading.location.latitude !== undefined && <MapCanvas latitude={reading.location.latitude} longitude={reading.location.longitude}/>}
                            {(reading.location === undefined || reading.location.latitude === undefined) && "Not set"}
                        </dd>
                    </div>

                    <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">Attached photo</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {userinfoData.clientid !== undefined && reading.useruid !== undefined && reading.photomain !== undefined &&
                            <ImageErrorBoundary>
                                <ReadingPhoto clientid={userinfoData.clientid} userid={reading.useruid} photoid={reading.photomain} />
                            </ImageErrorBoundary>
                            }
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}