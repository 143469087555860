import React, { useState, useEffect } from 'react';
import { formatDateAbbr } from '../../Shared/Components/functions.js';
import { usePaginatedQueue } from './usePaginatedQueue.js';

export default function QueueTab(props) {
    // STATE
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedCertificates()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const resultsPerPage = 20;

    // FIRESTORE
    const calibrations = usePaginatedQueue(referenceItem, include, resultsPerPage);
    const currentLastVisibleItem = (calibrations !== undefined) ? calibrations.docs[calibrations.size - 1] : null;

    // PAGINATION HELPERS

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, calibrations.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (calibrations !== undefined && calibrations.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (calibrations !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, calibrations]);

    return (
        <div>
            {/* Results area */}
            {calibrations && calibrations.docs.length > 0 && <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Certificate generation queue</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">The following calibration certificates are queued for generation</p>
                </div>

                {calibrations.size > 0 && <div>
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Model</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer name</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Serials</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Technician</th>
                            </tr>
                        </thead>
                        <tbody>
                            {calibrations.docs.map((calibrationItem, itemIdx) => (
                                <tr key={calibrationItem.id} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                    <td className="pl-6 py-4">{calibrationItem.data().dts !== undefined && formatDateAbbr(calibrationItem.data().dts.toDate())}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().modelName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().certificateCustomerName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        <span className="">Unit serial: {calibrationItem.data().unitserial ? calibrationItem.data().unitserial : "—"}</span><br />
                                        <span className="">Sensor serial: {calibrationItem.data().sensorserial ? calibrationItem.data().sensorserial : "—"}</span><br />
                                        <span className="">MAC address: {calibrationItem.data().macaddress ? calibrationItem.data().macaddress : "—"}</span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().authUserName !== undefined ? calibrationItem.data().authUserName : 'Not set'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}

            </div>

            }

            {/* Empty state */}
            {calibrations.docs.length === 0 && <div className="mt-6 relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <div className="text-center">
                    <p className="text-lg leading-6 font-medium text-gray-900">No calibrations are in the queue</p>
                    <p className="mt-2 text-sm text-gray-500">
                        Calibrations that are in the queue to have certificates generated will appear here as they are received.
                    </p>
                </div>
            </div>}

            {/* Pagination controls */}
            {calibrations.docs.length > 0 &&
                <nav
                    className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{(firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>

                        <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            }

        </div>
    )
}