import { collection, query, orderBy } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

export default function useFeedback() {
    const firestore = useFirestore();
    const feedbackCollection = collection(firestore, 'feedback/');
    const collectionQuery = query(feedbackCollection, orderBy('created', 'desc'));
    const { status, data: feedbacks } = useFirestoreCollectionData(collectionQuery, { idField: 'id' });

    if (status === 'loading') {
        return undefined;
    }

    return feedbacks;
}
