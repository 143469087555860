import { collection, query, orderBy, limit, startAfter, startAt, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function usePaginatedQueue(startItem, include, rpp) {
    const firestore = useFirestore();
    const calibrationsCollection = collection(firestore, 'calibrations');

    var activeQuery = undefined;
    if (startItem === null) {
        activeQuery = query(calibrationsCollection, where('certificategenerated', '==', false), where('certificatequeued', '==', true), orderBy('dts', 'desc'), limit(rpp));
    } else {
        activeQuery = (include) ? 
        query(calibrationsCollection, where('certificategenerated', '==', false), where('certificatequeued', '==', true), orderBy('dts', 'desc'), limit(rpp), startAt(startItem)) :
        query(calibrationsCollection, where('certificategenerated', '==', false), where('certificatequeued', '==', true), orderBy('dts', 'desc'), limit(rpp), startAfter(startItem));
    }

    const { status, data: certificates } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return certificates;
}
