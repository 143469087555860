import React, { useState, useEffect } from 'react';
// import BatchActionControl from './batchcontrol.js';
import DetailsOverlay from './detailsoverlay.js';
import PrintLabelOverlay from '../Calibrations/printdialog.js';
import { LoadingBanner, ErrorBanner } from '../Components/Helpers/index.js';
import { useNavigate } from 'react-router-dom';
import { getIdToken } from "firebase/auth";
import { doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useFirestore } from 'reactfire';
import FileSaver from "file-saver";
import { useAuth } from 'reactfire';
import { formatDateAbbr } from '../../Shared/Components/functions.js';
import { usePaginatedCalibrations } from '../Components/Hooks/usePaginatedUnsignedCalibrations.js';
import CalibrationsActionMenu from '../Dashboard/CalibrationsActionMenu.js';
import ModalLoadingActivity from '../Components/ModalLoadingActivity.js';
import ReassignSuccessDialog from './ReassignSuccessDialog.js';
import ReassignClientDialog from './reassignClientDialog.js';

export default function UnsignedCertificatesTab(props) {
    // STATE
    const [detailsOverlayOpen, setDetailsOverlayOpen] = useState(false)
    const [printOverlayOpen, setPrintOverlayOpen] = useState(false)
    const [selectedCalibrationDetails, setSelectedCalibrationDetails] = useState(undefined)
    const [formLoading, setFormLoading] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);
    const resultsPerPage = 20;
    const [modalDialogOpen, setModalDialogOpen] = useState(false);
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedUnsignedCalibrations()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [reassignClientDialogOpen, setReassignClientDialogOpen] = useState(false);
    const [reassignSuccessDialogOpen, setReassignSuccessDialogOpen] = useState(false);
    const [selectedCalibration, setSelectedCalibration] = useState(undefined);
    // hold for branch search feature: const [branch, setBranch] = useState(0);
    // eslint-disable-next-line
    const [isDownloading, setIsDownloading] = useState(false);

    // HOOKS
    const auth = useAuth();
    const navigate = useNavigate();
    const unsignedCalibrations = usePaginatedCalibrations(referenceItem, include, resultsPerPage);
    const currentLastVisibleItem = (unsignedCalibrations !== undefined) ? unsignedCalibrations.docs[unsignedCalibrations.size - 1] : null;
    const firestore = useFirestore();

    /* Hold for branch search feature:  
    function branchSelected(id) {
        const validBranches = [0, 1, 2, 3, 4, 5];
        if (validBranches.includes(id)) {
            setBranch(id);
        }
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    } */

    const servePdf = async (e, certificateGuid) => {
        e.stopPropagation();

        setIsDownloading(true);
        const docRef = doc(firestore, `certificates`, certificateGuid);
        const docSnap = await getDoc(docRef);

        // existence check
        if (!docSnap.exists()) {
            window.alert("Not exists");
        }

        // certificate data should be truthy from here
        const certificate = docSnap.data();

        // compose filename overloaded with fields from the record:
        var friendlyCompanyName = friendlyCompanyForFilename(certificate.certificateCustomerName);
        var friendlyCertificateNo = friendlySerialForFilename(certificate.serial);
        
        var friendlySerialNo = friendlySerialForFilename(certificate.serialIdx.length > 1 ? `${certificate.serialIdx[0]}_${certificate.serialIdx[1]}` : certificate.serialIdx[0]);
        // var friendlySerialNo = friendlySerialForFilename(certificate.serialIdx[0]);
        
        var friendlyFileName = `Certificate_${friendlyCompanyName}_${friendlySerialNo}_${friendlyCertificateNo}.pdf`;

        const storage = getStorage();

        getDownloadURL(ref(storage, `certificates/${certificateGuid}.pdf`))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    setIsDownloading(false);
                    FileSaver.saveAs(blob, friendlyFileName);
                };
                xhr.onerror = (error) => {
                    setIsDownloading(false);
                    window.alert("Error: could not download file");
                }
                xhr.open('GET', url);
                xhr.send();
            })
            .catch((error) => {
                setIsDownloading(false);
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        window.alert("Error: object not found");
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        window.alert("Error: unauthorized");
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        window.alert("Error: request cancelled");
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        window.alert("Unknown error occurred");
                        break;
                    default:
                        window.alert("Unknown error occurred");
                        break;
                }
            });

        setIsDownloading(false);
    }

    // HELPERS

    const friendlyCompanyForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Company";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";

        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;

            if (alphaNumeric.test(str[i])) {
                retval += str[i];
            } else if (retval.slice(-1) !== "_") {
                retval += "_";
            }
        }

        // remove leading/trailing underscores
        retval = trim(retval, "_");

        // return something if end up with nothing
        if (!retval) {
            return "Company";
        }

        return retval;
    }

    const friendlySerialForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Number";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";

        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;

            if (alphaNumeric.test(str[i])) {
                retval += str[i];
            } else if (retval.slice(-1) !== "-") {
                retval += "-";
            }
        }

        // remove leading/trailing dashes
        retval = trim(retval, "-");

        // return something if end up with nothing
        if (!retval) {
            return "Number";
        }

        return retval;
    }

    const trim = (str, ch) => {
        var start = 0,
            end = str.length;

        while (start < end && str[start] === ch)
            ++start;

        while (end > start && str[end - 1] === ch)
            --end;

        return (start > 0 || end < str.length) ? str.substring(start, end) : str;
    }

    // PAGINATION HELPERS

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, unsignedCalibrations.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (unsignedCalibrations !== undefined && unsignedCalibrations.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (unsignedCalibrations !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, unsignedCalibrations]);

    // FUNCTIONS

    function openCertificateDetails(selected) {
        setSelectedCalibrationDetails(selected);
        setDetailsOverlayOpen(true);
    }

    const calDetailsHandler = (e, calibrationData) => {
        e.stopPropagation();

        if (calibrationData) {
            openCertificateDetails(calibrationData);
        }
    }

    // FORMS

    async function handleSubmit(form) {
        closeErrorBanner();

        // thought I saw a double submit despite button being disabled?
        if (formLoading === true) {
            return;
        }

        setFormLoading(true);

        // split calibration ID and device ID
        var payload = {};
        var payloadItems = [];
        form.selectedCalibrations.forEach((item) => {
            const parts = item.split('#');
            if (parts.length === 3) {
                payloadItems.push({ 'calibrationGuid': parts[0], 'deviceGuid': parts[1], 'crmGuid': parts[2] });
            }
        });
        payload.calibrations = payloadItems;

        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isoberdev.web.app/cal/enqueue', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => response.json())
                    .then(data => {
                        setFormLoading(false);
                        if (data.error) {
                            showErrorBanner(data.message);
                        } else {
                            showSuccessDialog();
                        }
                    });
            })
            .catch((errorJwt) => {
                setFormLoading(false);
                showErrorBanner("Error - invalid auth token");
            });
    }

    // LIFTING STATE

    const handleDetailsOpenChange = (newVal) => {
        setDetailsOverlayOpen(newVal);
        setSelectedCalibrationDetails(undefined);
    }

    const handlePrintClosed = () => {
        setDetailsOverlayOpen(false);
        setPrintOverlayOpen(false);
        setSelectedCalibrationDetails(undefined);
    }

    const handlePrint = (e, calibrationData) => {
        e.stopPropagation();

        setSelectedCalibrationDetails(calibrationData);
        setDetailsOverlayOpen(false);
        setPrintOverlayOpen(true);
    }

    const gotoJobcard = (e, calibrationData) => {
        e.stopPropagation();

        // validate
        let docIdRegex = /^[A-Za-z0-9]{10,30}$/;
        if (!calibrationData || !calibrationData.jobcardGuid || !docIdRegex.test(calibrationData.jobcardGuid)) {
            window.alert("Cannot view job card: identifier is not set.");
            return;
        }


        // outta here
        navigate(`/calibrations/jobcard/${calibrationData.jobcardGuid}`);
    }

    // HELPERS

    // eslint-disable-next-line
    function showSuccessDialog() {
        setFormLoading(false);
    }

    // eslint-disable-next-line
    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    const enqueueSingle = (e, calibrationData) => {
        if (!calibrationData || !calibrationData.id || !calibrationData.modelGuid || !calibrationData.crmGuid) {
            window.alert("Cannot enqueue certificate: identifiers are null. " + JSON.stringify(calibrationData));
            return;
        }

        const form = { selectedCalibrations: [`${calibrationData.id}#${calibrationData.modelGuid}#${calibrationData.crmGuid}`] };
        handleSubmit(form);
        setDetailsOverlayOpen(false);
    }

    const showReassignClient = (e, calibrationData) => {
        e.stopPropagation();

        if (calibrationData) {
            setSelectedCalibration(calibrationData);
            setReassignClientDialogOpen(true);
        }
    }

    const reassignClientHandler = async (result) => {
        setReassignClientDialogOpen(false);

        // cancel was pressed, do nothing
        if (!result) {
            setSelectedCalibration(undefined);
            return;
        }

        // validate selected calibration is not bust
        if (!selectedCalibration) {
            window.alert("Error: no selected calibration");
            return;
        }

        // validate not assigning to the same client
        if (selectedCalibration.clientGuid === result.customerGuid) {
            showErrorBanner("Cannot assign to the same client");
            return;
        }

        // show progress spinner
        setModalDialogOpen(true);

        // Merge in other required fields
        result.authUserName = `${props.userinfo.fname} ${props.userinfo.lname}`;
        result.calibrationGuid = selectedCalibration.id;
        result.jobcardGuid = selectedCalibration.jobcardGuid;
        result.deviceGuid = selectedCalibration.deviceGuid;

        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isober.co.za/cal/reassign', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(result)
                })
                    .catch((err) => {
                        setModalDialogOpen(false);
                        showErrorBanner("Error " + err);
                    })
                    .then(response => response.json())
                    .then(data => {
                        setModalDialogOpen(false);
                        if (data.error) {
                            showErrorBanner(data.message);
                        } else {
                            setModalDialogOpen(false);
                            showReasignSuccessDialog();
                        }
                    });
            })
            .catch((errorJwt) => {
                setModalDialogOpen(false);
                showErrorBanner("Error - invalid auth token");
            });
    }

    const showReasignSuccessDialog = () => {
        setReassignSuccessDialogOpen(true);
    }

    const closeReasignSuccessDialog = () => {
        setReassignSuccessDialogOpen(false);
    }

    return (
        <div>
            {selectedCalibrationDetails !== undefined && <DetailsOverlay open={detailsOverlayOpen} onDetailsOpenChange={handleDetailsOpenChange} item={selectedCalibrationDetails} handlePrint={handlePrint} enqueueHandler={enqueueSingle} />}
            {selectedCalibrationDetails !== undefined && <PrintLabelOverlay open={printOverlayOpen} onPrintClosed={handlePrintClosed} item={selectedCalibrationDetails} />}
            {reassignClientDialogOpen && <ReassignClientDialog isOpen={reassignClientDialogOpen} handler={reassignClientHandler} userinfo={props.userinfo} />}
            {modalDialogOpen && <ModalLoadingActivity isOpen={modalDialogOpen} message="Please wait while device is reassigned" />}
            {reassignSuccessDialogOpen && <ReassignSuccessDialog open={reassignSuccessDialogOpen} onChange={closeReasignSuccessDialog} />}

            {unsignedCalibrations.docs.length === 0 && <div className="mt-6 relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <div className="text-center">
                    <p className="text-lg leading-6 font-medium text-gray-900">All done!</p>
                    <p className="mt-2 text-sm text-gray-500">
                        <p>No calibrations requiring certificate generation found; they will appear here as they are recorded.</p>
                    </p>
                </div>
            </div>}

            {unsignedCalibrations.docs.length > 0 && <>
                {/* start A */}
                <div className="mt-6 bg-white shadow overflow-visible sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Recent calibrations</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Recently performed calibrations are shown below:</p>
                        
                        {formLoading === true && <LoadingBanner />}
                        {errorBannerOpen === true && <ErrorBanner message={lastErrorMessage} />}
                    </div>

                    {unsignedCalibrations.size > 0 && <div>
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Model</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer name</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Serials</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {unsignedCalibrations.docs.map((calibrationItem, itemIdx) => (
                                    <tr key={calibrationItem.id} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        <td className="pl-6 py-4 whitespace-nowrap">
                                            <p>{calibrationItem.data().authUserName !== undefined ? calibrationItem.data().authUserName : 'Not set'}</p>
                                            <p>{calibrationItem.data().dts !== undefined && formatDateAbbr(calibrationItem.data().dts.toDate())}</p>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().modelName}</td>
                                        <td className="px-6 py-4 text-sm text-gray-900">{calibrationItem.data().certificateCustomerName}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            <span className="">Unit serial {calibrationItem.data().unitserial ? calibrationItem.data().unitserial : "—"}</span><br />
                                            <span className="">Sensor serial {calibrationItem.data().sensorserial ? calibrationItem.data().sensorserial : "—"}</span>
                                        </td>
                                        <td className="px-6 py-4 text-sm text-gray-900">
                                            <p id="pricing-plans-0-description-1" className="ml-6 pl-1 text-sm md:ml-0 md:pl-0">
                                                {(calibrationItem.data().certificategenerated !== undefined && calibrationItem.data().certificategenerated === false && calibrationItem.data().certificatequeued !== undefined && calibrationItem.data().certificatequeued === false) && <span className="mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> Calibration&nbsp;complete </span>}
                                                {calibrationItem.data().certificategenerated === true && calibrationItem.data().certificateGuid !== undefined && <span className="mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800"> Certificate&nbsp;generated </span>}
                                                {calibrationItem.data().certificatequeued !== undefined && calibrationItem.data().certificatequeued === true && <span className="mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-pink-100 text-pink-800"> Queued </span>}
                                            </p>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            <div className="flex-shrink-0">
                                                {<CalibrationsActionMenu calibrationData={Object.assign(calibrationItem.data(), { id: calibrationItem.id })} reassignHandler={showReassignClient} downloadHandler={servePdf} caldetailsHandler={calDetailsHandler} printHandler={handlePrint} enqueueHandler={enqueueSingle} jobcardHandler={gotoJobcard} />}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                </div>
                {/* end A */}

                {/* Button actions */}
                {/*<BatchActionControl />*/}
                <nav className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{firstItemStack.length === 0 ? unsignedCalibrations.docs.length : (firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button type='button' onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button type='button' onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>
                        <button type='button' onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            </>}
        </div>
    )
}