import React from 'react';
import { Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Configure, useHits } from 'react-instantsearch-hooks-web';

// https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks

export default function CompanyTab(props) {
    // ALGOLIA
    const searchClient = algoliasearch('3SEANAS33X', '8ff6c7ff57ebf9447a56d076888484dd');
    
    function CustomerHits(props) {
        const { hits } = useHits(props);

        return (
            <>
                {hits.length > 0 && <div>
                    <ul className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                        {hits.map(customer => (
                            <li key={customer.name} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                <div className="w-full flex items-center justify-between p-6 space-x-6">
                                    <div className="flex-1 truncate">
                                        <div className="flex items-center space-x-3">
                                            <h3 className="text-gray-900 text-sm font-medium truncate">{customer.name}</h3>
                                        </div>
                                        <p className="mt-1 text-gray-500 text-sm truncate">
                                            {customer.address1 !== undefined && customer.address1}{!customer.address1 && '—'}<br />
                                            {customer.address2 !== undefined && customer.address2}{!customer.address2 && '—'}<br />
                                            {(customer.city === undefined && customer.post === undefined) && '—'}
                                            {customer.city !== undefined && <span>{customer.city} &nbsp;</span>}
                                            {customer.postcode !== undefined && customer.postcode}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="-mt-px flex divide-x divide-gray-200">
                                        <div className="w-0 flex-1 flex">
                                            <Link to={`/calibrations/client/${customer.objectID}`} className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                                <span className="ml-3">View customer</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                }
            </>
        );
    }

    // OTHER

    return (
        <div>
            <InstantSearch searchClient={searchClient} indexName="clientNameIndex">
                <Configure hitsPerPage={9} />
                {/* Alolia search bar */}
                <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Search calibrations by customer name</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Enter a customer name to search.</p>
                    </div>

                    <div className="px-6 pb-6">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                                <label htmlFor="query" className="block text-sm font-medium text-gray-700">Customer name</label>
                                <SearchBox classNames={{input: "ocus:ring-isober-500 focus:border-isober-500 block w-full rounded-none rounded-l-md pl-2 sm:text-sm border-gray-300"}} 
                                resetIconComponent={({ classNames }) => ( <></> )} submitIconComponent={({ classNames }) => ( <></> )} />
                            </div>
                        </div>
                    </div>
                </div>

                <CustomerHits {...props} />
            </InstantSearch>
        </div >
    )
}