import React from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import SearchTabs from './tabs.js';
import { useLocation } from 'react-router-dom';

function DashboardPage(props) {
    const currentLocation = useLocation();

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}
                        <div>
                            <SearchTabs />
                            <div className="py-4">
                                <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}

export default DashboardPage;