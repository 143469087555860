import React from 'react';
import CalibrationsByCustomer from '../Components/Search/byclient';
import useCustomer from '../Components/Hooks/usecustomer';

export default function TabCustomerCalibrations( { clientId } ) {
    // HOOKS
    const customerRecord = useCustomer(clientId);

    return (
        <div>
            <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Recent Customer Calibrations</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Below are the calibrations for the customer <span className='font-bold'>{customerRecord.name}</span>:</p>
                </div>
            </div>

            {clientId !== undefined && <CalibrationsByCustomer query={clientId}/>}
        </div>
    )
}