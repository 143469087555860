import React from 'react';

export default function FeaturesEnterprise(props) {
    return (
        <div className="bg-gray-50 overflow-hidden">
            <div className="relative max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <svg className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
                    <defs>
                        <pattern id="8b1b5f72-e944-4457-af67-0c6d15a99f38" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="404" height="784" fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
                </svg>
                <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
                    <div className="lg:col-span-1">
                        <h3 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                            iSober Enterprise<br />
                            for fleets
                        </h3>
                    </div>

                    <div className="mt-10 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:col-span-2 lg:mt-0">

                        <p className="mb-5 col-span-2 font-bold">iSober Enterprise is a comprehensive test management platform designed for business owners who 
                            operate a fleet of trucks, buses, or other vehicles. It provides instant oversight of testing and results across the entire 
                            organisation.</p>
                            
                             {/* and who need to test drivers remotely without having to physically test the User themselves. */}

                            {/* The iSober app and iSober Breathalyser Cloud enable managers and fleet operators to have instant oversight of testing and test results within their organisation from a real-time dashboard. */}

                            {/* The iSober app and iSober Breathalyser Cloud are developed to the highest industry standards of data security and privacy; your data is safe with us.</p> */}

                        <div className="mt-10 sm:mt-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-isober-900 text-white">
                                {/* Heroicon name: scale */}
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                </svg>
                            </div>
                            <div className="mt-5">
                                <h4 className="text-lg leading-6 font-medium text-gray-900">Verified record</h4>
                                <p className="mt-2 text-base leading-6 text-gray-500">
                                    Test results are stored against the unique breathalyser serial number allowing traceability for each test.
                                </p>
                            </div>
                        </div>
                        <div className="mt-10 sm:mt-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-isober-900 text-white">
                                {/* Heroicon name: scale */}
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                </svg>
                            </div>
                            <div className="mt-5">
                                <h4 className="text-lg leading-6 font-medium text-gray-900">Manage test results</h4>
                                <p className="mt-2 text-base leading-6 text-gray-500">
                                    Full measurement history is saved securely for each driver and can be managed in iSober Breathalyser Cloud.
                                </p>
                            </div>
                        </div>
                        <div className="mt-10 sm:mt-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-isober-900 text-white">
                                {/* Heroicon name: lightning-bolt */}
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                </svg>
                            </div>
                            <div className="mt-5">
                                <h4 className="text-lg leading-6 font-medium text-gray-900">Measure others</h4>
                                <p className="mt-2 text-base leading-6 text-gray-500">
                                    Test an employee or user other than the currently signed in user.
                                </p>
                            </div>
                        </div>
                        <div className="mt-10 sm:mt-0">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-isober-900 text-white">
                                {/* Heroicon name: shield-check */}
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path></svg>
                            </div>
                            <div className="mt-5">
                                <h4 className="text-lg leading-6 font-medium text-gray-900">Total security</h4>
                                <p className="mt-2 text-base leading-6 text-gray-500">
                                    The iSober app and iSober Breathalyser Cloud are developed to the highest industry standards of data security and privacy; your data is safe with us.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
