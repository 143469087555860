import React from 'react'
import { ref } from 'firebase/storage';
import { useStorage, useStorageDownloadURL } from 'reactfire';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

export default function ReadingPhoto(props) {
    const [open, setOpen] = useState(false)
    const storage = useStorage();

    // const thumbnailPath = `c/${props.clientid}/u/${props.userid}/thumb_${props.photoid}`;
    // const { status: thumbnailStatus, data: thumbnailURL } = useStorageDownloadURL(ref(storage, thumbnailPath));

    const mainPath = `c/${props.clientid}/u/${props.userid}/${props.photoid}`;
    const { status: mainStatus, data: mainURL } = useStorageDownloadURL(ref(storage, mainPath));

    if (mainStatus === 'loading' || mainStatus === 'loading') {
        return <span>Loading...</span>;
    } else if (mainStatus === 'error') {
        return <span>Image error</span>;
    }

    return (
        <div>
            <button label="waka" type="button" onClick={() => setOpen(true)} >
                <div className="rounded-lg bg-gray-100 overflow-hidden sm:col-span-4 lg:col-span-5">
                    <img src={mainURL} alt="Main" className="object-center object-cover max-h-72" />
                </div>
            </button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                    <div className="flex min-h-screen text-center md:block md:px-2 lg:px-4" style={{ fontSize: 0 }}>
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
                            &#8203;
                        </span>

                        {/* Modal contents */}
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95" enterTo="opacity-100 translate-y-0 md:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 md:scale-100" leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95">
                            <div className="flex text-base text-left transform transition md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
                                <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                                    <button type="button" className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8" onClick={() => setOpen(false)}>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    <div className="rounded-lg bg-gray-100 overflow-hidden sm:col-span-4 lg:col-span-5">
                                        <img src={mainURL} alt="Main" className="max-h-80v object-center object-contain" />
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}