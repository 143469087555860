import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';

export default function useModel(customerGuid) {
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'devicemodels', customerGuid);

    // eslint-disable-next-line no-unused-vars
    const { status, data: model } = useFirestoreDocDataOnce(documentRef, { idField: "id" });

    if (status !== 'success') {
        console.log("Model for " + status + " = undefined");
        return undefined;
    }

    return model;
}