import { ChatAltIcon, UserCircleIcon, BellIcon, UsersIcon, HomeIcon, ShieldCheckIcon, LogoutIcon, TableIcon  } from '@heroicons/react/outline'
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useFirebaseApp } from 'reactfire';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const sections = [
    { name: 'Dashboard', href: '/app', admin: false, icon: HomeIcon },
    { name: 'Download reports', href: '/app/reports', admin: true, icon: TableIcon },
    { name: 'My Account', href: '/app/account', admin: false, icon: UserCircleIcon },
    { name: 'Manage Alerts', href: '/app/alerts', admin: true, icon: BellIcon },
    { name: 'Activity Logs', href: '/app/logs', admin: true, icon: ShieldCheckIcon },
    { name: 'Manage Users', href: '/app/users', admin: true, icon: UsersIcon },
    { name: 'Support Centre', href: '/app/support', admin: false, icon: ChatAltIcon },
]

export default function SideNavigation(props) {
    const navigate = useNavigate();
    const app = useFirebaseApp();
    const auth = getAuth(app);

    // includes fix from https://github.com/FirebaseExtended/reactfire/discussions/228
    function signOut() {
        auth.signOut().then(() => {
            navigate('/app');
            window.location.reload();

        });
    }

    const showAdminSections = (props.claims !== undefined && (props.claims.superadmin || props.claims.clientadmin)) ? true : false;

    function isHighlighted(item) {
        if (props.path.startsWith('/app/users') && item === 'Users') {
            return true
        } else if (props.path.startsWith('/app/support') && item === 'Support Centre') {
            return true
        } else if (props.path.startsWith('/app/subscriptions') && item === 'Subscriptions') {
            return true
        } else if (props.path.startsWith('/app/clients') && item === 'Clients') {
            return true
        } else if (props.path.startsWith('/app/alerts') && item === 'Manage Alerts') {
            return true
        } else if (props.path.startsWith('/app/logs') && item === 'Logs') {
            return true
        } else if (props.path.startsWith('/app/account') && item === 'My Account') {
            return true
        } else if ((props.path === '/app' || props.path.startsWith('/app/dashboard') || props.path.startsWith('/app/reading')) && item === 'Dashboard') {
            return true
        }

        return false
    }

    return (
        <nav className="mt-5 flex-1 px-2 space-y-1">
            {sections.map((section) => (
                (!section.admin || showAdminSections) &&
                <Link to={section.href} key={section.name} className={classNames(
                    isHighlighted(section.name) ? 'bg-isober-1100 text-white' : 'text-white hover:bg-isober-1000 hover:bg-opacity-75',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md')}>
                    <section.icon className="mr-3 h-6 w-6 text-white" aria-hidden="true" />
                    {section.name}
                </Link>
            ))}
            <button onClick={() => signOut()} className="text-white hover:bg-gray-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                <LogoutIcon className="mr-3 h-6 w-6 text-white" />
                Sign out
            </button>
        </nav>
    )
}