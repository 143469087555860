import React from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../Components/PageHeader/index.js';
import { useLocation } from 'react-router-dom';
import { collection, query, where, orderBy } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export default function ManageDevicesPage(props) {
    // HOOKS
    const currentLocation = useLocation();

    // FIRESTORE
    const firestore = useFirestore();
    const devicesCollection = collection(firestore, `devicemodels`);
    const activeQuery = query(devicesCollection, where('deleted', '==', false), orderBy('displayname', 'asc'));
    const { data: devices } = useFirestoreCollection(activeQuery);

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">The following devices are loaded on the Calibrations system.</p>
                        </div>
                        <div className="mt-3 flex sm:mt-0 sm:ml-4">
                            <Link to='/calibrations/admin/devices/create'>
                                <button type="button" className="mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                    Add new device
                                </button>
                            </Link>
                        </div>
                    </div>

                    {devices.size === 0 && <div className="mt-5 sm:col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700">No device models are currently loaded.</p>
                            </div>
                        </div>
                    </div>
                    }

                    {devices.size > 0 &&
                        <table className="mt-6 min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Model</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Manufacturer</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Indicator kind</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {devices.docs.map((item, deviceIdx) => (
                                    <tr key={item.id} className={deviceIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.data().displayname}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.data().manufacturer}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {!item.data().ledStyle && "Numeric range"}
                                            {item.data().ledStyle && item.data().ledStyle === true && "LEDs"}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            <div className="flex-shrink-0">
                                                <Link to={`model/${item.id}`}>
                                                    <button className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                                        Details
                                                    </button>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }

                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}