import React, { useState, useEffect, useRef } from 'react';
import TabletSignatureCanvas from '../Components/Signature/signaturecontainer';
import TopazSignatureCanvas from '../Components/Signature/topazsigncanvas.js';
import DeviceListCapture from './devicelistcapture.js';
import MetadataCapture from './metadataCapture';
import AccountManagerControl from './AccountManager.js';
import { BranchControl } from '../Components/Forms/branch.js';
import { LoadingBanner, ErrorBanner } from '../Components/Helpers/index.js';
import * as Yup from "yup"
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
import ConfirmReceipt from './confirmReceipt.js';
import { useFirestore, useAuth } from 'reactfire';
import CustomerAutocomplete from '../Components/Autocomplete/customersearch';
import { addDoc, collection, serverTimestamp, writeBatch, doc } from 'firebase/firestore';

// https://reactjs.org/docs/lifting-state-up.html

export default function RecordedDeliveryTab(props) {
    // HOOKS
    const formikRef = useRef(null);
    const navigate = useNavigate();
    const auth = useAuth();
    const firestore = useFirestore();

    // FIREBASE

    // eslint-disable-next-line
    const logConfiguration = collection(firestore, 'calibrationlogs');
    const jobcardsConfiguration = collection(firestore, 'jobcards');

    // STATE
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [useTopaz, setUseTopaz] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [signatureBase64, setSignatureBase64] = useState(undefined); // state lifted from topaz component
    const [selectedCustomerNameForDisplay, setSelectedCustomerNameForDisplay] = useState(null);
    const [notes, setNotes] = useState(new Map());
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(undefined);

    // FORM

    async function handleSubmit(form) {
        setFormLoading(true);

        // recheck the form out of caution
        try {
            await validationSchema.validate(form);
            closeErrorBanner();
        } catch (err) {
            showErrorBanner("Form is invalid");
            return;
        }

        var deviceSerials = [];
        form.deviceList.forEach(d => {
            deviceSerials.push(d.serial.toUpperCase());
        });

        try {
            var newRecord = {
                creator: auth.currentUser.uid,
                created: serverTimestamp(),
                status: 'Open',
                branch: Number(form.branchId),
                clientGuid: form.customerGuid,
                clientName: form.customerName,
                certificateClientName: form.certificateCustomerName,
                deviceIdx: deviceSerials,
                numDevices: form.deviceList.length
            };

            // merge in optional account manager
            if (form.accountManager) {
                newRecord.accountManager = form.accountManager;
            }

            // inject metadata as an array field if any entries were set
            if (form.metadata && form.metadata.length > 0) {
                form.metadata.forEach(item => {
                    newRecord[item.fieldKey] = item.fieldValue;
                });
            }

            // inject signature image
            if (signatureBase64) {
                newRecord.signatureBase64 = signatureBase64;
            }

            const docRef = await addDoc(jobcardsConfiguration, newRecord);

            // perform a batch write of devices to the job card
            const thisJobcardCollectionRef = collection(firestore, `jobcards/${docRef.id}/devices`);
            const batch = writeBatch(firestore);
            form.deviceList.forEach(d => {
                const docRef = doc(thisJobcardCollectionRef);
                const ledStyle = (d.ledStyle && d.ledStyle === true) ? true : false;
                var newDeviceRecord = { modelId: d.modelGuid, model: d.modelName, serial: d.serial, status: 'Not started', ledStyle: ledStyle };

                // dial in the note if we have one
                const k = `${d.modelGuid}##${d.serial}`
                if (notes.has(k)) {
                    newDeviceRecord.note = notes.get(k);
                }

                batch.set(docRef, newDeviceRecord);
            });
            await batch.commit();

            // log this event
            const logConfiguration = collection(firestore, 'calibrationlogs');
            var userDisplayName = "Not set";
            if (props.userinfo && props.userinfo.fname && props.userinfo.lname) {
                userDisplayName = `${props.userinfo.fname} ${props.userinfo.lname}`;
            }
            const descWord = (form.deviceList.length === 1) ? 'device' : 'devices';
            await addDoc(logConfiguration, {
                user: auth.currentUser.uid,
                dts: serverTimestamp(),
                reference: docRef.id,
                success: true,
                action: "Receive units (recorded tab)",
                detail: `${form.deviceList.length} ${descWord} for ${form.customerName}`,
                username: userDisplayName
            });

            // show dialog and then navigate to dashboard
            showSuccessDialog();
        } catch (err) {
            setFormLoading(false);
            showErrorBanner("Error: could not save customer record " + err.message);
        }

        setFormLoading(false);
    }

    function isTopazInstalled() {
        var isInstalled = document.documentElement.getAttribute('SigPlusExtLiteExtension-installed');

        if (!isInstalled) {
            return false;
        }

        return true;
    }

    // EFFECTS

    useEffect(() => {
        // check for Topaz
        if (isTopazInstalled()) {
            setUseTopaz(true);
        }
    }, []);

    // LIFTING STATE

    // from BranchControl
    const handleBranchChange = async (newVal, setFieldValue, setFieldTouched) => {
        // use passed function to set formik field value 
        await setFieldValue('branchId', newVal, true);
        await setFieldTouched('branchId', true);
    }

    // from AccountManagerControl
    const handleAccountManagerChange = async (newVal, setFieldValue, setFieldTouched) => {
        // use passed function to set formik field value 
        await setFieldValue('accountManager', newVal, true);
        await setFieldTouched('accountManager', true);
    }

    // from DeviceListCapture
    const handleDeviceListChange = async (newVal, setFieldValue, setFieldTouched) => {
        await setFieldValue('deviceList', newVal, true);
        await setFieldTouched('deviceList', newVal, true);
    }

    // from MetadataCapture
    const handleMetadataChange = async (newVal, setFieldValue, setFieldTouched) => {
        await setFieldValue('metadata', newVal, true);
        await setFieldTouched('metadata', newVal, true);
    }

    // a note field in DeviceListCapture was changed
    // identifier = modelGuid##serial ; value = note text
    const handleNoteChange = async (identifier, value) => {
        await setNotes(new Map(notes.set(identifier, value))); // need to clone 'notes' or setState will see the same object IDs and noop
    }

    // from CustomerAutocomplete
    const handleCustomerChange = async (newVal, setFieldValue, setFieldTouched) => {
        // use passed function to set formik field value 
        setFieldValue('customerGuid', newVal.id);
        setFieldValue('customerName', newVal.name);
        setFieldValue('certificateCustomerName', newVal.name);

        // keep our own copy outside the form to display to the user
        setSelectedCustomerNameForDisplay(newVal.name);

        // copy name into certificate name field if not hardcoded "create new company" ID
        // client IDs are strings hence wrap the test in quotes
        if (newVal.id !== '0') {
            await setFieldValue('certificateCustomerName', newVal.name, true);
            await setFieldTouched('certificateCustomerName', true);
        } else {
            await setFieldValue('certificateCustomerName', '');
            await setFieldTouched('certificateCustomerName', true);
        }
    }

    // Lifting state helper from SignatureCanvas & TopazSignatureCanvas
    const handleSignatureMethodChange = (newVal) => {
        setUseTopaz(newVal);
    }

    // Lifting state helper from SignatureCanvas
    const onCanvasSignatureReceived = (newVal) => {
        setSignatureBase64(newVal);
    }

    // Lifting state helper from SignatureCanvas & TopazSignatureCanvas
    const onTopazSignatureReceived = (newVal) => {
        setSignatureBase64(newVal);
    }

    // from ConfirmReceipt
    const handleConfirmChange = (newVal) => {
        setSuccessDialogOpen(newVal);

        if (newVal === false) {
            hideSuccessDialog();
        }
    }

    // FORMS

    const initialValues = { customerGuid: '', customerName: '', certificateCustomerName: '', deviceList: [], branchId: 1 };
    const validationSchema = Yup.object({
        customerGuid: Yup.string("Choose customer").required("Required"),
        customerName: Yup.string("Choose customer").required("Required"),
        deviceList: Yup.array().required("At least one device is required").min(1),
        branchId: Yup.number("Choose branch").required("Required").min(1).max(5),
        metadata: Yup.array(),
        certificateCustomerName: Yup.string("Enter certificate customer name").required("Required")
    });

    function showSuccessDialog() {
        setFormLoading(false);
        setSuccessDialogOpen(true);
    }

    function hideSuccessDialog() {
        setSuccessDialogOpen(false);
        navigate('/calibrations'); // outta here
    }

    function showErrorBanner(msg) {
        setLastErrorMessage(msg);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    return (
        <div>
            <div className="py-4">

                <ConfirmReceipt open={successDialogOpen} onChange={handleConfirmChange} />

                <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                    {({ handleSubmit, handleChange, handleBlur, isValid, dirty, setFieldValue, setFieldTouched, values }) => (
                        <form onSubmit={handleSubmit} className="">

                            <div className="my-4">
                                <div className="shadow sm:rounded-md sm:overflow-display">
                                    <div className="bg-white px-4 space-y-4 sm:p-4">
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Alcohol Breathalysers</h3>
                                            <p className="mt-1 text-sm text-gray-500">Use the form below to capture devices without driver information:</p>
                                        </div>

                                        <div className="mt-0">
                                            {formLoading && <LoadingBanner />}
                                            {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />}
                                        </div>

                                        {/* Account manager */}
                                        <AccountManagerControl handler={handleAccountManagerChange} sfv={setFieldValue} sft={setFieldTouched} />

                                        {/* Branch */}
                                        <BranchControl handler={handleBranchChange} sfv={setFieldValue} sft={setFieldTouched} />
                                    </div>
                                </div>
                            </div>

                            <div className="my-4">
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Customer Information</h3>
                                            <p className="mt-1 text-sm text-gray-500">Enter information about the company or person delivering the units.</p>
                                        </div>

                                        {(formLoading || errorBannerOpen) && <div className="grid grid-cols-6 gap-4">
                                            <div className="col-span-6">
                                                <div className="mt-0">
                                                    {formLoading && <LoadingBanner />}
                                                    {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />}
                                                </div>
                                            </div>
                                        </div>}

                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">Customer name {selectedCustomerNameForDisplay && <span className='text-green-700'>{selectedCustomerNameForDisplay}</span>}</label>
                                                <CustomerAutocomplete handler={handleCustomerChange} sfv={setFieldValue} sft={setFieldTouched} showCreateOption={true} userinfo={props.userinfo} />
                                            </div>

                                            <div className="sm:col-span-6">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Customer name as it will appear on certificate
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="certificateCustomerName" id="certificateCustomerName" value={values.certificateCustomerName} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="my-4">
                                <DeviceListCapture handler={handleDeviceListChange} noteHandler={handleNoteChange} sfv={setFieldValue} sft={setFieldTouched} />
                            </div>

                            <div className="my-4">
                                <MetadataCapture handler={handleMetadataChange} sfv={setFieldValue} sft={setFieldTouched} />
                            </div>

                            {/* Signature */}
                            <div className="my-4">
                                {useTopaz && <TopazSignatureCanvas onSignatureReceived={onTopazSignatureReceived} onSignatureMethodChange={handleSignatureMethodChange} />}
                                {!useTopaz && <TabletSignatureCanvas onSignatureReceived={onCanvasSignatureReceived} onSignatureMethodChange={handleSignatureMethodChange} />}
                            </div>

                            {/* Button actions */}
                            <div className="mt-4 flex justify-end">
                                <button type="submit" disabled={formLoading || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-900">
                                    Submit
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}