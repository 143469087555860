import React, { useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from "yup"
import CalibrationsByDeviceSerial from './bydeviceserial.js';

export default function IdentifierTab(props) {
    // STATE 
    // const [formLoading, setFormLoading] = useState(false);
    const [query, setQuery] = useState(false);

    // FORMS

    const initialValues = { query: '' };
    const validationSchema = Yup.object({
        query: Yup.string("Enter search term").required("Required")
    });

    async function handleSubmit(form) {
        setQuery(form.query);
    }

    return (
        <div>
            <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Search calibrations by device</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Enter a device identifier such as serial number or MAC address e.g. ALF1100XX1 ALX1205XXA1</p>
                </div>

                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                    {({ handleSubmit, handleChange, handleBlur, isValid, dirty }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="px-6 pb-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6">
                                        <label htmlFor="query" className="block text-sm font-medium text-gray-700">
                                            Unit or sensor serial number &nbsp; <ErrorMessage name="query" component="span" className="text-red-700 italic" />
                                        </label>

                                        {/* input with button */}
                                        <div className="mt-1 flex rounded-md shadow-sm">
                                            <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                                <input type="text" name="query" id="query" autoComplete='off' onChange={handleChange} onBlur={handleBlur} className="focus:ring-isober-500 focus:border-isober-500 block w-full rounded-none rounded-l-md pl-2 sm:text-sm border-gray-300" placeholder="Enter search term..." />
                                                {/*<div className='absolute top-3 right-2'>
                                                    {formLoading && <img src='/images/loadingtiny.gif' width='16' height='16' alt='' />}
                                                </div>*/}
                                            </div>
                                            <button type="submit" disabled={/*formLoading ||*/ !dirty || !isValid} className="disabled:opacity-50 -ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500">
                                                <span>Search</span>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            
            { query && <CalibrationsByDeviceSerial query={query} userinfo={props.userinfo}/>}

        </div>
    )
}