import React from 'react';

function Pricing(props) {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto bg-white py-16 sm:py-24 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto text-center mb-8">
                    <h2 className="text-3xl leading-9 font-extrabold text-gray-900">Pricing Plans</h2>
                </div>
                {/* xs to lg */}
                <div className="max-w-2xl mx-auto lg:hidden">
                    <div className="px-4">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">iSober for individuals</h2>
                        <p className="mt-4">
                            <span className="text-4xl leading-10 font-extrabold text-gray-900">Free</span>
                        </p>
                        <p className="mt-4 text-sm leading-5 text-gray-500">iSober is free to download and try for an unlimited period.</p>
                    </div>

                    <table className="mt-8 w-full">
                        <thead>
                            <tr>
                                <th className="sr-only" scope="col">Feature</th>
                                <th className="sr-only" scope="col">Included</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Number of users</th>
                                <td className="py-5 pr-4">
                                    <span className="block text-sm leading-5 text-gray-700 text-right">
                                        Single user account<br />
                                        Use as guest or sign in Google or Apple
                                    </span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Number of results saved</th>
                                <td className="py-5 pr-4">
                                    <span className="block text-sm leading-5 text-gray-700 text-right">
                                        Guest: results not saved.<br />
                                        Sign in with Google or Apple: 20
                                    </span>
                                </td>
                            </tr>


                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Share measurement result</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Measurement with GPS and photo</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Test another user</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">No</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Create and administer users</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Create and manage alerts</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">iSober Cloud web access</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="border-t border-gray-200 px-4 pt-5">
                    </div>

                    <div className="px-4 mt-16">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">Social with IAP</h2>
                        <p className="mt-4">
                            <span className="text-4xl leading-10 font-extrabold text-gray-900">R49</span>
                            <span className="text-base leading-6 font-medium text-gray-500">/month</span>
                        </p>
                        <p className="mt-4 text-sm leading-5 text-gray-500">Upgrade to Pro using an in-app purchase at any time.</p>
                    </div>

                    <table className="mt-8 w-full">
                        <thead>
                            <tr>
                                <th className="sr-only" scope="col">Feature</th>
                                <th className="sr-only" scope="col">Included</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Number of users</th>
                                <td className="py-5 pr-4">
                                    <span className="block text-sm leading-5 text-gray-700 text-right">
                                        Single user account<br />
                                        Use as guest or sign in Google or Apple
                                    </span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Number of results saved</th>
                                <td className="py-5 pr-4">
                                    <span className="block text-sm leading-5 text-gray-700 text-right">
                                    Guest: results not saved.
                                    Sign in with Google or Apple: Unlimited.
                                    </span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Share measurement result</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Measurement with GPS and photo</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Test another user</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">No</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Create and administer users</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">No</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Create and manage alerts</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">iSober Cloud web access</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="border-t border-gray-200 px-4 pt-5">
                    </div>

                    <div className="px-4 mt-16">
                        <h2 className="text-lg leading-6 font-medium text-gray-900">Enterprise</h2>
                        <p className="mt-4">
                            <span className="text-4xl leading-10 font-extrabold text-gray-900">R99</span>
                            <span className="text-base leading-6 font-medium text-gray-500">/month</span>
                        </p>
                        <p className="mt-4 text-sm leading-5 text-gray-500">Enterprise users are invoiced monthly on account on a per-user basis.</p>
                    </div>

                    <table className="mt-8 w-full">
                        <thead>
                            <tr>
                                <th className="sr-only" scope="col">Feature</th>
                                <th className="sr-only" scope="col">Included</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Number of users</th>
                                <td className="py-5 pr-4">
                                    <span className="block text-sm leading-5 text-gray-700 text-right">Unlimited users</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Number of results saved</th>
                                <td className="py-5 pr-4">
                                    <span className="block text-sm leading-5 text-gray-700 text-right">Unlimited</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Share measurement result</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Measurement with GPS and photo</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Test another user</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Create and administer users</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Create and manage alerts</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>

                            <tr className="border-t border-gray-200">
                                <th className="py-5 px-4 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">iSober Cloud web access</th>
                                <td className="py-5 pr-4">
                                    {/* Heroicon name: check */}
                                    <svg className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Yes</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="border-t border-gray-200 px-4 pt-5">
                    </div>
                </div>

                {/* lg+ */}
                <div className="hidden lg:block">
                    <table className="w-full h-px table-fixed">
                        <caption className="sr-only">
                            Pricing plan comparison
                        </caption>
                        <thead>
                            <tr>
                                <th className="pb-4 px-6 text-sm leading-5 font-medium text-gray-900 text-left" scope="col">
                                    <span className="sr-only">Feature by</span>
                                </th>

                                <th className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">iSober for individuals</th>

                                <th className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">iSober Pro for individuals</th>

                                <th className="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Enterprise for fleets</th>
                            </tr>
                        </thead>
                        <tbody className="border-t border-gray-200 divide-y divide-gray-200">
                            <tr>
                                <th className="py-8 px-6 text-sm leading-5 font-medium text-gray-900 text-left align-top" scope="row">Pricing</th>

                                <td className="h-full py-8 px-6 align-top">
                                    <div className="relative h-full table">
                                        <p>
                                            <span className="text-4xl leading-10 font-extrabold text-gray-900">Free</span>
                                        </p>
                                        <p className="mt-2 mb-2 text-sm leading-5 text-gray-500">iSober is free to download and try for an unlimited period.</p>
                                    </div>
                                </td>

                                <td className="h-full py-8 px-6 align-top">
                                    <div className="relative h-full table">
                                        <p>
                                            <span className="text-4xl leading-10 font-extrabold text-gray-900">R49</span>
                                            <span className="text-base leading-6 font-medium text-gray-500">/month</span>
                                        </p>
                                        <p className="mt-2 mb-2 text-sm leading-5 text-gray-500">Upgrade to Pro using an in-app purchase at any time.</p>
                                    </div>
                                </td>

                                <td className="h-full py-8 px-6 align-top">
                                    <div className="relative h-full table">
                                        <p>
                                            <span className="text-4xl leading-10 font-extrabold text-gray-900">R99</span>
                                            <span className="text-base leading-6 font-medium text-gray-500">/month</span>
                                        </p>
                                        <p className="mt-2 mb-2 text-sm leading-5 text-gray-500">Enterprise users are invoiced monthly on account on a per-user basis.</p>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th className="py-5 px-6 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Number of users</th>
                                <td className="py-5 px-6">
                                    <span className="block text-sm leading-5 text-gray-700">
                                        Single user account<br />
                                        Use as guest or sign in Google or Apple
                                    </span>
                                </td>
                                <td className="py-5 px-6">
                                    <span className="block text-sm leading-5 text-gray-700">
                                        Single user account<br />
                                        Use as guest or sign in Google or Apple
                                    </span>
                                </td>
                                <td className="py-5 px-6">
                                    <span className="block text-sm leading-5 text-gray-700">
                                        Multiple users with client administrator
                                    </span>
                                </td>
                            </tr>

                            <tr>
                                <th className="py-5 px-6 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Number of results saved</th>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <span className="block text-sm leading-5 text-gray-700">
                                        Guest: results not saved.<br />
                                        Sign in with Google or Apple: 20
                                    </span>
                                </td>
                                <td className="py-5 px-6">
                                    <span className="block text-sm leading-5 text-gray-700">
                                        Guest: results not saved.<br />
                                        Sign in with Google or Apple: Unlimited.
                                    </span>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <span className="block text-sm leading-5 text-gray-700">Unlimited</span>
                                </td>
                            </tr>

                            <tr>
                                <th className="py-5 px-6 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Share measurement result</th>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <svg className="h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Included in Social sign-in</span>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <svg className="h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Included in Social with IAP</span>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <svg className="h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Included in Enterprise</span>
                                </td>
                            </tr>

                            <tr>
                                <th className="py-5 px-6 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Measurement with GPS and photo</th>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <svg className="h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Included in Social sign-in</span>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <svg className="h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Included in Social with IAP</span>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <svg className="h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Included in Enterprise</span>
                                </td>
                            </tr>

                            <tr>
                                <th className="py-5 px-6 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Test another user</th>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: minus */}
                                    <svg className="h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Not included in Social sign-in</span>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <svg className="h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Included in Social with IAP</span>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <svg className="h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Included in Enterprise</span>
                                </td>
                            </tr>

                            <tr>
                                <th className="py-5 px-6 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Create and administer users</th>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: minus */}
                                    <svg className="h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Not included in Social sign-in</span>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: minus */}
                                    <svg className="h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Included in Social with IAP</span>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <svg className="h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                    <span className="sr-only">Included in Enterprise</span>
                                </td>
                            </tr>

                            <tr>
                                <th className="py-5 px-6 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">Create and manage alerts</th>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: minus */}
                                    <svg className="h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: minus */}
                                    <svg className="h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <svg className="h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                </td>
                            </tr>

                            <tr>
                                <th className="py-5 px-6 text-sm leading-5 font-normal text-gray-500 text-left" scope="row">iSober Cloud web access</th>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: minus */}
                                    <svg className="h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: minus */}
                                    <svg className="h-5 w-5 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                                    </svg>
                                </td>
                                <td className="py-5 px-6">
                                    {/* Heroicon name: check */}
                                    <svg className="h-5 w-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Pricing;