import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useClientInfo from './useclient';
import { formatDate } from '../../Shared/Components/functions.js';
import useUserInfo from '../Components/UserInfo/index.js';

// to use parameters, the page must be a function not a class
// see: https://github.com/ReactTraining/react-router/issues/7256

function ClientDetailsPage() {
    const { clientId } = useParams();
    const clientInfo = useClientInfo(clientId);
    const createdBy = useUserInfo(clientInfo.createdby);

    return (
        <div>
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500">The details for the selected client appear below. Choose Manage Users to view the users that belong to this client and use Edit Details to update the client details.</p>
                </div>
                <div className="mt-3 flex sm:mt-0 sm:ml-4">
                    <Link to={`/system/clients/users/${clientId}`}>
                        <button type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Manage&nbsp;users</button>
                    </Link>
                    <Link to={`/system/clients/edit/${clientId}`}>
                        <button type="button" className="ml-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Edit&nbsp;details</button>
                    </Link>
                </div>
            </div>


            <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{clientInfo.name}</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Date created</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatDate(clientInfo.createddate.toDate())}</dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Created by</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {(createdBy !== undefined) && `${createdBy.fname} ${createdBy.lname}`}
                                {(createdBy === undefined) && "(Not set)"}
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Status</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{(clientInfo.disabled === false) ? "Active" : "Disabled"}</dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Integrations</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                Client API: {(clientInfo.clientapi && clientInfo.clientapi.enabled && clientInfo.clientapi.enabled === true) ? "Yes" : "No"} <br />
                                Ratality API: {(clientInfo.ratality && clientInfo.ratality.enabled && clientInfo.ratality.enabled === true) ? "Yes" : "No"}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default ClientDetailsPage;