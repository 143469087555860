import React, { useState } from 'react';
import { LightningBoltIcon, ClipboardIcon, TableIcon } from '@heroicons/react/solid';
import RecordedDeliveryTab from './tabRecorded';
import QuickReceiveTab from './tabQuickReceive';
import BulkReceiveTab from './tabBulkReceive';
import PageHeader from '../Components/PageHeader/index.js';
import { useLocation } from 'react-router-dom';

const tabs = [
    { name: 'Quick capture', icon: LightningBoltIcon },
    { name: 'Recorded delivery', icon: ClipboardIcon },
    { name: 'Bulk capture', icon: TableIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ReceivingPage(props) {
    // Hooks
    const currentLocation = useLocation();

    // State
    const [currentTab, setCurrentTab] = useState('Quick capture');

    // Tabs 1
    function selectTab(e, tabName) {
        e.preventDefault();

        setCurrentTab(tabName);
    }

    // Tabs 2
    function dropdownChange(e) {
        setCurrentTab(e.target.value);
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}



                    <div>
                        {/* start tab control */}
                        <div>
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">Select a tab</label>
                                <select id="tabs" name="tabs" onChange={dropdownChange}
                                    className="block mt-4 mb-2 w-full focus:ring-isober-500 focus:border-isober-500 border-gray-300 rounded-md"
                                    defaultValue={tabs.find((tab) => tab.name).name}>
                                    {tabs.map((tab) => (
                                        <option key={tab.name}>{tab.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="hidden sm:block">
                                <div className="border-b border-gray-200">
                                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                        {tabs.map((tab) => (
                                            <a key={tab.name} href="#0" onClick={(e) => selectTab(e, tab.name)}
                                                className={classNames(
                                                    tab.name === currentTab
                                                        ? 'border-isober-500 text-isober-600'
                                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                    'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                                                )}
                                                aria-current={tab.name === currentTab ? 'page' : undefined}>
                                                <tab.icon
                                                    className={classNames(
                                                        tab.name === currentTab ? 'text-isober-500' : 'text-gray-400 group-hover:text-gray-500',
                                                        '-ml-0.5 mr-2 h-5 w-5'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                <span>{tab.name}</span>
                                            </a>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                        </div>
                        {/* end tab control */}

                        {(currentTab === "Recorded delivery") && <RecordedDeliveryTab userinfo={props.userinfo} />}
                        {(currentTab === "Quick capture") && <QuickReceiveTab userinfo={props.userinfo} />}
                        {(currentTab === "Bulk capture") && <BulkReceiveTab userinfo={props.userinfo} />}
                    </div>

                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}