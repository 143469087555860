import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function SkuDetailModal({ handler, item }) {
    // STATE
    const [open, setOpen] = useState(true)

    // HOOKS
    const cancelButtonRef = useRef(null)

    // HELPERS

    const cancelPressed = () => {
        setOpen(false);
        handler();
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="text-center">
                                        <Dialog.Title as="h3" className="justify-left text-left text-base font-semibold leading-6 text-gray-900">
                                            Current stock on hand:
                                        </Dialog.Title>

                                        <div className="mt-2 justify-left text-left">
                                            <table className='border-separate border-spacing-x-4'>
                                                <thead>
                                                    <tr>
                                                        <td className='font-bold underline'>Site</td>
                                                        <td className='font-bold underline'>Stock</td>
                                                        <td className='font-bold underline'>Demo units</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr key='0'>
                                                        <td>Johannesburg</td>
                                                        <td align='right'>
                                                            {(item && item.balance && item.balance.jhb) && item.balance.jhb}
                                                            {(!item.balance || item.balance.jhb === undefined) && 0}
                                                        </td>
                                                        <td align='right'>
                                                            {(item.demobalance && item.demobalance.jhb && item.demobalance.jhb > 0) && ` (demo: ${item.demobalance.jhb})`}
                                                            {(!item || !item.demobalance || item.demobalance.jhb === undefined || item.demobalance.jhb === 0) && "—"}
                                                        </td>
                                                    </tr><tr key='0'>
                                                        <td>Cape Town</td>
                                                        <td align='right'>
                                                            {(item && item.balance && item.balance.ct) && item.balance.ct}
                                                            {(!item.balance || item.balance.ct === undefined) && 0}
                                                        </td>
                                                        <td align='right'>
                                                            {(item.demobalance && item.demobalance.ct && item.demobalance.ct > 0) && ` (demo: ${item.demobalance.ct})`}
                                                            {(!item || !item.demobalance || item.demobalance.ct === undefined || item.demobalance.ct === 0) && "—"}
                                                        </td>
                                                    </tr><tr key='0'>
                                                        <td>Durban</td>
                                                        <td align='right'>
                                                            {(item && item.balance && item.balance.dbn) && item.balance.dbn}
                                                            {(!item.balance || item.balance.dbn === undefined) && 0}
                                                        </td>
                                                        <td align='right'>
                                                            {(item.demobalance && item.demobalance.dbn && item.demobalance.dbn > 0) && ` (demo: ${item.demobalance.dbn})`}
                                                            {(!item || !item.demobalance || item.demobalance.dbn === undefined || item.demobalance.dbn === 0) && "—"}
                                                        </td>
                                                    </tr><tr key='0'>
                                                        <td>Botswana</td>
                                                        <td align='right'>
                                                            {(item && item.balance && item.balance.bw) && item.balance.bw}
                                                            {(!item.balance || item.balance.bw === undefined) && 0}
                                                        </td>
                                                        <td align='right'>
                                                            {(item.demobalance && item.demobalance.bw && item.demobalance.bw > 0) && ` (demo: ${item.demobalance.bw})`}
                                                            {(!item || !item.demobalance || item.demobalance.bw === undefined || item.demobalance.bw === 0) && "—"}
                                                        </td>
                                                    </tr><tr key='0'>
                                                        <td>Client site</td>
                                                        <td align='right'>
                                                            {(item && item.balance && item.balance.cs) && item.balance.cs}
                                                            {(!item.balance || item.balance.cs === undefined) && 0}
                                                        </td>
                                                        <td align='right'>
                                                            {(item.demobalance && item.demobalance.cs && item.demobalance.cs > 0) && ` (demo: ${item.demobalance.cs})`}
                                                            {(!item || !item.demobalance || item.demobalance.cs === undefined || item.demobalance.cs === 0) && "—"}
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3">
                                    <button type="button" onClick={() => cancelPressed()} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}