import React, { useEffect, useState, Fragment } from "react";
import { formatDate } from '../../Shared/Components/functions.js'
import { useLoadScript, GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";

export default function UserReadingsMap(props) {
    // State
    const [mapRef, setMapRef] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [markerMap, setMarkerMap] = useState({});
    const [center, setCenter] = useState({ lat: -25, lng: 28 });
    const [zoom, setZoom] = useState(5);
    const [setClickedLatLng] = useState(null);
    const [infoOpen, setInfoOpen] = useState(false);

    // Load the Google maps scripts
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBnmaVw-kcNkWWbExk9eBVrjgJKAeJ3pNE"
    });

    // Iterate props.readings to size, center, and zoom map to contain all markers
    const fitBounds = map => {
        const bounds = new window.google.maps.LatLngBounds();
        props.readings.map(place => {
            if (place !== undefined && place.pos !== undefined) {
                bounds.extend(place.pos);
                setCenter(place.pos);
            }
            return place.id;
        });
        map.fitBounds(bounds);
    };

    const loadHandler = map => {
        // Store a reference to the google map instance in state
        setMapRef(map);
        // Fit map bounds to contain all markers
        fitBounds(map);
    };

    // We have to create a mapping of our places to actual Marker objects
    const markerLoadHandler = (marker, place) => {
        return setMarkerMap(prevState => {
            return { ...prevState, [place.id]: marker };
        });
    };

    const markerClickHandler = (event, place) => {
        // Remember which place was clicked
        setSelectedPlace(place);

        // Required so clicking a 2nd marker works as expected
        if (infoOpen) {
            setInfoOpen(false);
        }

        setInfoOpen(true);

        // If you want to zoom in a little on marker click
        if (zoom < 13) {
            setZoom(13);
        }

        // if you want to center the selected Marker
        //setCenter(place.pos)
    };

    useEffect(() => {
        if (mapRef !== null) {
            const bounds = new window.google.maps.LatLngBounds();
            props.readings.map(place => {
                if (place !== undefined && place.pos !== undefined) {
                    bounds.extend(place.pos);
                }
                return place.id;
            });
            mapRef.fitBounds(bounds);
        }
    }, [mapRef, props.readings]);

    if (!isLoaded) {
        return null;
    }

    return (
        <Fragment>
            <GoogleMap
                // Do stuff on map initial laod
                onLoad={loadHandler}
                // Save the current center position in state
                // onCenterChanged={() => setCenter(mapRef.getCenter().toJSON())}
                // Save the user's map click position
                onClick={e => setClickedLatLng(e.latLng.toJSON())}
                center={center}
                zoom={zoom}
                mapContainerStyle={{
                    height: "70vh",
                    width: "100%"
                }}
            >
                {props.readings.map(place => (
                    (place !== undefined && place.hasOwnProperty('pos') && place.pos !== null && place.pos !== undefined) && <Marker
                        key={place.id}
                        position={place.pos}
                        onLoad={marker => markerLoadHandler(marker, place)}
                        onClick={event => markerClickHandler(event, place)}
                    />
                ))}

                {infoOpen && selectedPlace && (
                    <InfoWindow
                        anchor={markerMap[selectedPlace.id]}
                        onCloseClick={() => setInfoOpen(false)}
                    >
                        <div>
                            <div><b>{selectedPlace.name}</b></div>
                            <div>{formatDate(selectedPlace.dts.toDate())}</div>
                            <div>{selectedPlace.level.toFixed(2)} {selectedPlace.scale}</div>
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
        </Fragment>
    );
}