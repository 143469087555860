import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { formatDateDMYStephen } from '../../Shared/Components/functions.js'
import useCrm from '../Components/Hooks/useCrm'

// Parameters:
//    * handlePrint()

export default function ConfirmCalibrate(props) {
    // HOOKS
    const crm = useCrm(props.item.crm);

    // STATE
    const [open, setOpen] = useState(props.open)
    const [copySuccess, setCopySuccess] = useState(false)

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    // FUNCTIONS

    function copyCSV() {
        const csvEntry = composeCSV();
        copyTextToClipboard(csvEntry);
    }

    function composeCSV() {
        if (!props.item) {
            return "";
        }

        var retval = formatDateDMYStephen(new Date()) + "\t"; // Calibration date
        retval += "\t"; // could use certificateGuid to look up its serial number
        retval += `${props.item.certificateCustomerName}\t`; // customer name
        retval += `${props.item.modelName}\t`; // model name
        retval += (props.item.unitserial) ? `${props.item.unitserial}\t` : "\t"; // unit serial

        // use mac address if sensor serial is not set
        if (props.item.sensorserial) {
            retval += `${props.item.sensorserial}\t`;
        } else if (props.item.macaddress) {
            retval += `${props.item.macaddress}\t`;
        } else {
            retval += "\t";
        }

        retval += `${props.item.readingscount}\t`; // readings count
        retval += (props.item.ledStyle !== null && props.item.ledStyle === true) ? formatLedStyle(props.item.precalzero) + "\t" : `${props.item.precalzero} ${props.item.unitScale}\t`; // pre cal zero
        retval += (props.item.ledStyle !== null && props.item.ledStyle === true) ? formatLedStyle(props.item.precalalcohol) + "\t" : `${props.item.precalalcohol} ${props.item.unitScale}\t`; // pre cal alcohol
        retval += `${props.item.calvalue}\t`; // cal value
        retval += (props.item.ledStyle !== null && props.item.ledStyle === true) ? formatLedStyle(props.item.postcalreading) + "\t" : `${props.item.postcalreading} ${props.item.unitScale}\t`; // post cal reading
        retval += (props.item.ledStyle !== null && props.item.ledStyle === true) ? formatLedStyle(props.item.postcalzero) + "\t" : `${props.item.postcalzero} ${props.item.unitScale}\t`; // post cal zero
        retval += `${props.item.temperature}\t`; // temperature
        retval += `${props.item.humidity}\t`; // humidity
        retval += crm.bottlenumber ? crm.bottlenumber : "Unknown\t"; // CRM reference
        retval += "\t"; // this space intentionally left blank
        retval += (props.item.technicianNote) ? `${props.item.technicianNote}\t` : "\t"; // technician note

        return retval;
    }

    function formatLedStyle(n) {
        switch (n) {
            case "0":
                return "PASS";
            case "1":
                return "WARN";
            case "2":
                return "FAIL";
            case "3": 
                return "WARN/FAIL";
            default:
                return "UNKNOWN";
        }
    }

    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            window.alert('Copy ' + msg);
        } catch (err) {
            console.error('Unable to copy', err);
        }

        document.body.removeChild(textArea);
    }
    
    function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            setCopySuccess(true);
        }, function (err) {
            window.alert('Could not copy text: ', err);
        });
    }

    function printLabel() {
        props.handlePrint();
    }

    function closeDialog() {
        setOpen(false);
        props.onChange(false);
    }

    const bodyMessage = (props.bodyMessage) ? props.bodyMessage : "The calibration was saved successfully.";

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => {}}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500"
                                    onClick={() => closeDialog()}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-isober-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationIcon className="h-6 w-6 text-isober-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Confirmation
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {bodyMessage}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button type="button" onClick={() => closeDialog()} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-600 text-base font-medium text-white hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm">OK</button>
                                
                                {props.showCopyCsv === true && !copySuccess && <button type="button" onClick={() => copyCSV()} className="inline-flex justify-center mr-2 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Copy CSV</button>}
                                {props.showCopyCsv === true && copySuccess && <button type="button" onClick={() => copyCSV()} className="inline-flex justify-center mr-2 bg-green-100 py-2 px-4 border border-green-500 rounded-md shadow-sm text-sm font-medium text-green-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Copy CSV</button>}

                                {<button type="button" onClick={() => printLabel()} className="inline-flex justify-center mr-2 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Print label</button>}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}