import { FolderIcon, HomeIcon, LogoutIcon } from '@heroicons/react/outline'   
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useFirebaseApp } from 'reactfire';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const sections = [
    { name: 'Dashboard', href: '/calibrations', icon: HomeIcon },
    { name: 'Receive units', href: '/calibrations/receiving', icon: FolderIcon },
    { name: 'Calibrate', href: '/calibrations/calibrate', icon: FolderIcon },
    { name: 'Calibrations history', href: '/calibrations/calibrations', icon: FolderIcon },
    { name: 'Certificates history', href: '/calibrations/certificates', icon: FolderIcon },
    { name: 'Admin', href: '/calibrations/admin', icon: FolderIcon },
]

export default function SideNavigation(props) {
    const navigate = useNavigate();
    const app = useFirebaseApp();
    const auth = getAuth(app);

    // includes fix from https://github.com/FirebaseExtended/reactfire/discussions/228
    function signOut() {
        auth.signOut().then(() => {
            navigate('/app');
            window.location.reload();

        });
    }

    function isHighlighted(item) {
        if ((props.path.startsWith('/calibrations/dashboard') || props.path.startsWith('/calibrations/client') || props.path.startsWith('/calibrations/device') || props.path.startsWith('/calibrations/jobcard') || props.path === '/calibrations') && item === 'Dashboard') {
            return true
        } else if (props.path.startsWith('/calibrations/receiving') && item === 'Receive units') {
            return true
        } else if (props.path.startsWith('/calibrations/admin') && item === 'Admin') {
            return true
        } else if (props.path.startsWith('/calibrations/calibrate') && item === 'Calibrate') {
            return true
        } else if (props.path.startsWith('/calibrations/certificates') && item === 'Certificates') {
            return true
        } else if (props.path.startsWith('/calibrations/Calibrations') && item === 'Calibrations') {
            return true
        }

        return false
    }

    return (
        <nav className="mt-5 flex-1 px-2 space-y-1">
            {sections.map((section) => (
                <Link to={section.href} key={section.name} className={classNames(
                    isHighlighted(section.name) ? 'bg-isober-1100 text-white' : 'text-white hover:bg-gray-600 hover:bg-opacity-75',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md')}>
                    <section.icon className="mr-3 h-6 w-6 text-white" aria-hidden="true" />
                    {section.name}
                </Link>
            ))}
            <button onClick={() => signOut()} className="text-white hover:bg-gray-600 hover:bg-opacity-75 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                <LogoutIcon className="mr-3 h-6 w-6 text-white" />
                Sign out
            </button>
        </nav>
    )
}