import { collection, query } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function useJobcardEvents(jobcardId) {
    const firestore = useFirestore();
    const jobcardEventsCollection = collection(firestore, `jobcards/${jobcardId}/events`);
    var activeQuery = query(jobcardEventsCollection);

    const { status, data: events } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return events;
}
