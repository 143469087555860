import { collection, query, where, orderBy, limit, startAfter, startAt } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function usePaginatedUserAlerts(collectionPath, useruid, rpp, startItem, include) {
    const firestore = useFirestore();
    const alertsCollection = collection(firestore, collectionPath);
    
    var activeQuery = undefined;
    if (startItem === null) {
        activeQuery = query(alertsCollection, where('useruid', '==', useruid), orderBy('created', 'desc'), limit(rpp));
    } else {
        activeQuery = (include) ? query(alertsCollection, where('useruid', '==', useruid), orderBy('created', 'desc'), limit(rpp), startAt(startItem)) : query(alertsCollection, where('useruid', '==', useruid), orderBy('created', 'desc'), limit(rpp), startAfter(startItem));
    }

    const { status, data: readings } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return readings;
}