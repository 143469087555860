import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, updateDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { useAuth, useFirestore, useFirestoreDocData } from 'reactfire';
import { formatDate } from '../../Shared/Components/functions.js';
import JobCardDeviceList from './jobcardDeviceList.js';
import { ChevronLeftIcon } from '@heroicons/react/solid'
import EditCustomerNameDialog from './editCustomerNameDialog.js';
import { LoadingBanner, ErrorBanner } from '../Components/Helpers/index.js';
import { useJobcardEvents } from './useJobcardEvents.js';
import { useJobcardNotes } from './useJobcardNotes.js';
import AddNoteDialog from '../Calibrate/addnotedialog';
import { Base64Image } from './base64Image.js';

export default function JobCardDetails(props) {
    // HOOKS
    const { jobCardId } = useParams();
    const navigate = useNavigate();
    const jobcardEvents = useJobcardEvents(jobCardId);
    const jobcardNotes = useJobcardNotes(jobCardId);
    const auth = useAuth();

    // STATE
    const [editCustomerNameOpen, setEditCustomerNameOpen] = useState(false)
    const [formLoading, setFormLoading] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);
    const [allowEdits, setAllowEdits] = useState(true);
    const [addNoteOpen, setAddNoteOpen] = useState(false)

    // FIRESTORE
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'jobcards', jobCardId);
    const { status, data: jobcardRecord } = useFirestoreDocData(documentRef);

    useEffect(() => {
        if (jobcardRecord.status === "Dispatched") {
            setAllowEdits(false);
        }
    }, [jobcardRecord.status])

    if (status === 'loading') {
        return "Loading...";
    }

    if (jobcardRecord === undefined) {
        return "No such job card";
    }

    // HELPERS

    const setCertificateCustomerName = async (s) => {
        setFormLoading(true);
        await updateDoc(documentRef, { certificateClientName: s })
            .catch((error) => {
                setFormLoading(false);
                showErrorBanner("Error: could not update certificate customer name" + error.message);
            }).then(() => {
                setFormLoading(false);
            });
    }

    // FIXME: implement validates that serial number is valid
    const certificateCustomerNameIsValid = (s) => {
        if (s === null || s === undefined) {
            return false;
        }

        if (s.trim() === "") {
            return false;
        }

        return true;
    }

    const showEditCustomerName = (e) => {
        e.preventDefault();

        setEditCustomerNameOpen(true);
    }

    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    // eslint-disable-next-line
    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    // LIFTING STATE

    const handleCustomerNameChange = (newVal) => {
        setEditCustomerNameOpen(false);

        // undefined here has the semantic of just closing the dialog
        if (newVal === undefined) {
            return;
        }

        if (newVal === jobcardRecord.certificateClientName) {
            window.alert("No change, ignoring");
            return;
        }

        if (!certificateCustomerNameIsValid(newVal)) {
            window.alert("Invalid customer name");
            return;
        }

        // no issues, then persist to firestore
        setCertificateCustomerName(newVal);
    }

    // ADDING NOTES

    const addNoteChangeSaveHandler = async (note) => {
        closeErrorBanner();

        // validation
        if (!jobCardId) {
            showErrorBanner("Error - invalid job card ID");
            return;
        }

        // validation
        if (!props.userinfo || !props.userinfo.fname || !props.userinfo.lname) {
            showErrorBanner("Error – user info is not set, can't continue.");
            return;
        }

        if (!auth.currentUser.uid) {
            showErrorBanner("Error – user info is not set, can't continue.");
            return;
        }

        setFormLoading(true);

        const notesCollection = collection(firestore, `jobcards/${jobCardId}/notes`);
        await addDoc(notesCollection, {
            userGuid: auth.currentUser.uid,
            userName: `${props.userinfo.fname} ${props.userinfo.lname}`,
            created: serverTimestamp(),
            note: note,
        });

        setFormLoading(false);
    }

    const closeAddNoteHandler = () => {
        setAddNoteOpen(false);
    }

    const addNote = () => {
        setAddNoteOpen(true);
    }

    // JOB CARD

    const closeJobCard = async () => {
        if (window.confirm("Are you sure?") === true) {
            setFormLoading(true);
            await updateDoc(documentRef, { status: "Complete" })
                .catch((error) => {
                    setFormLoading(false);
                    showErrorBanner("Error: could not close the job card " + error.message);
                }).then(() => {
                    setFormLoading(false);
                });
        }
    }

    return (
        <div className='pt-4'>
            <EditCustomerNameDialog open={editCustomerNameOpen} initialValue={jobcardRecord.certificateClientName} handler={handleCustomerNameChange} />
            <AddNoteDialog open={addNoteOpen} closeHandler={closeAddNoteHandler} saveHandler={addNoteChangeSaveHandler} />

            {/* breadcrumbs */}
            <div className="flex items-center">
                <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                <button onClick={() => navigate('/calibrations')} className="text-sm font-medium text-gray-500 hover:text-gray-700">Back to job card list</button>
            </div>

            <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">


                <div className="py-2 pr-4 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Job card details &nbsp;

                            {jobcardRecord.status === 'Open' && <p className="px-2 inline-flex items-center rounded-full text-xs font-medium bg-blue-100 text-blue-800"> Open </p>}
                            {jobcardRecord.status === 'Complete' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> Job complete </p>}
                            {jobcardRecord.status === 'Quoting' && <p className="px-2 inline-flex items-center rounded-full text-xs font-medium bg-pink-100 text-pink-800"> Awaiting quote </p>}
                            {jobcardRecord.status === 'Dispatched' && <p className="px-2 inline-flex items-center rounded-full text-xs font-medium bg-blue-100 text-blue-800"> Dispatched </p>}
                        </h3>
                        {formLoading && <LoadingBanner />}
                        {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />}
                    </div>
                    <div className="mt-3 flex sm:mt-0 sm:ml-4">
                        <button type="button" onClick={() => navigate(`../dispatch/card/${jobCardId}`)} className="disabled:opacity-50 mr-2 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                            Dispatch
                        </button>

                        <button type="button" disabled={jobcardRecord.status === 'Complete'} onClick={() => closeJobCard()} className="disabled:opacity-50 ml-2 mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                            Close job card...
                        </button>
                    </div>
                </div>

                {/* Misc details */}
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-4">
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Customer:</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {jobcardRecord.clientName && jobcardRecord.clientName}
                                    {!jobcardRecord.clientName && "(Not set)"}
                                </dd>
                            </div>

                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Customer name on certificate:</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {jobcardRecord.certificateClientName && <div>{jobcardRecord.certificateClientName} <a href="#1" onClick={(e) => showEditCustomerName(e)} className='text-isober-900'>Edit</a></div>}
                                    {!jobcardRecord.certificateClientName && "(Not set)"}
                                </dd>
                            </div>

                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Date received</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {formatDate(jobcardRecord.created.toDate())}
                                </dd>
                            </div>

                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Account Manager</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    <span className='text-gray-900'>
                                        {jobcardRecord.accountManager && jobcardRecord.accountManager.displayName}
                                        {!jobcardRecord.accountManager && "Not set"}
                                    </span>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            {/* Dispatch cards */}
            {(jobcardEvents.docs.length > 0) && jobcardEvents.docs.map((record, idx) => (
                <div key={idx} className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            {(record.data().action && record.data().action === "dispatch") && "Dispatch"}
                            {(!record.data().action || record.data().action !== "dispatch") && "Event"}
                        </h3>
                    </div>

                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-4">

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Date:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {formatDate(record.data().dts.toDate())}
                                    </dd>
                                </div>

                                {record.data().invoiceNumber && <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Invoice number</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {record.data().invoiceNumber}
                                    </dd>
                                </div>}

                                {record.data().driverName && <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Driver name and surname</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {record.data().driverName}
                                    </dd>
                                </div>}

                                {record.data().idNumber && <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">ID number</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {record.data().idNumber}
                                    </dd>
                                </div>}

                                {record.data().vehicleRegNumber && <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Vehicle registration</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {record.data().vehicleRegNumber}
                                    </dd>
                                </div>}

                                {record.data().driversLicenceNumber && <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Driver's licence number:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {record.data().driversLicenceNumber}
                                    </dd>
                                </div>}

                                {record.data().waybillNumber && <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Waybill number:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {record.data().waybillNumber}
                                    </dd>
                                </div>}

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Notes:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {record.data().notes && record.data().notes}
                                        {!record.data().notes && "(Not set)"}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Devices dispatched</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {record.data().devices && `${record.data().devices.length} devices`}
                                        {!record.data().devices && "(Not set)"}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Signature:</dt>
                                    {/*<dd className="mt-1 text-sm text-gray-900">
                                        {record.data().signatureBase64 && <img className='w-[10rem]' src={record.data().signatureBase64} alt="Signature" />}
                                        {!record.data().signatureBase64 && "(Not set)"}
                                    </dd>*/}
                                    <Base64Image base64Data={record.data().signatureBase64} key={idx} />
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            ))}

            {/* Recorded delivery card */}
            {(jobcardRecord.driverName || jobcardRecord.idNumber || jobcardRecord.driversLicenceNumber || jobcardRecord.vehicleRegNumber || jobcardRecord.signatureBase64) &&
                <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Recorded delivery</h3>
                    </div>

                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-4">

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Driver name:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {jobcardRecord.driverName && <div>{jobcardRecord.driverName}</div>}
                                        {!jobcardRecord.driverName && "(Not set)"}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Driver ID number:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {jobcardRecord.idNumber && <div>{jobcardRecord.idNumber}</div>}
                                        {!jobcardRecord.idNumber && "(Not set)"}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Driver's licence number:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {jobcardRecord.driversLicenceNumber && <div>{jobcardRecord.driversLicenceNumber}</div>}
                                        {!jobcardRecord.driversLicenceNumber && "(Not set)"}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Vehicle registration number:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {jobcardRecord.vehicleRegNumber && <div>{jobcardRecord.vehicleRegNumber}</div>}
                                        {!jobcardRecord.vehicleRegNumber && "(Not set)"}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Signature at receipt:</dt>
                                    {/*<dd className="mt-1 text-sm text-gray-900">
                                        {jobcardRecord.signatureBase64 && <img className='w-[10rem]' src={jobcardRecord.signatureBase64} alt="Signature" />}
                                        {!jobcardRecord.signatureBase64 && "(Not set)"}
                                    </dd>*/}
                                    <Base64Image base64Data={jobcardRecord.signatureBase64} />
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>}



            {/* Devices on this job card */}
            {jobCardId !== undefined && <JobCardDeviceList jobcardId={jobCardId} allowEdits={allowEdits} userinfo={props.userinfo} customerName={jobcardRecord.clientName} />}

            {/* Notes on this job card */}
            {<div className="mt-6 shadow sm:rounded-md sm:overflow-hidden bg-white px-4 space-y-4 sm:p-4">
                <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Job Card Notes
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            {/*Subhead*/}
                        </p>
                    </div>
                    <div className="mt-3 flex sm:mt-0 sm:ml-4">
                        <button type="button" onClick={() => addNote()} className="mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                            Add note
                        </button>
                    </div>
                </div>

                {(jobcardNotes.docs.length > 0) && jobcardNotes.docs.map((record, idx) => (
                    <div key={idx} className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="border-t border-gray-200 px-4 py-0 sm:px-6">
                            <dl className="grid grid-cols-2 gap-x-4 gap-y-1 sm:grid-cols-4">
                                {record.data().userName && <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">User</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {record.data().userName}
                                    </dd>
                                </div>}

                                {record.data().created && <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Date:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {formatDate(record.data().created.toDate())}
                                    </dd>
                                </div>}

                                {record.data().note && <div className="sm:col-span-3">
                                    <dt className="text-sm font-medium text-gray-500">Note</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {record.data().note}
                                    </dd>
                                </div>}
                            </dl>
                        </div>
                    </div>
                ))}

                {(jobcardNotes.docs.length === 0) && <div>No notes are set.</div>}

            </div >}
        </div >
    )
}