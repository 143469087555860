import { collection, query, orderBy, limit, startAfter, startAt } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function usePaginatedCalibrations(startItem, include, rpp) {
    const firestore = useFirestore();
    const calibrationsCollection = collection(firestore, 'calibrations');

    var activeQuery = undefined;
    if (startItem === null) {
        activeQuery = query(calibrationsCollection, orderBy('dts', 'desc'), limit(rpp))
    } else {
        activeQuery = (include) ? 
            query(calibrationsCollection,  orderBy('dts', 'desc'), limit(rpp), startAt(startItem)) : 
            query(calibrationsCollection, orderBy('dts', 'desc'), limit(rpp), startAfter(startItem));
    }

    const { status, data: calibrations } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return calibrations;
}
