import React from 'react';

function Content(props) {
    return (
        <div className="bg-white overflow-hidden">
            <div className="relative max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
                <div className="mx-auto text-base max-w-prose lg:max-w-none">
                    <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Breathalyser test management</h3>
                </div>
                <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                    <div className="relative lg:row-start-1 lg:col-start-2">
                        <svg className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                            <defs>
                                <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                        </svg>
                        <div className="relative text-base mx-auto max-w-prose lg:max-w-none bg-white">
                            <figure className="text-center">
                                <div className="inline-block">
                                    <img className="rounded-lg object-cover object-center" src="images/logo_content.png" alt="" width="244" height="186" />
                                </div>
                                <figcaption className="mt-3 text-sm text-gray-500 max-w-[244px] mx-auto">
                                    <span>Alcohol Breathalysers Pty Ltd has over 19 years of experience of providing breathalysers and device calibration services to private individuals, law enforcement and regulated industries.</span>
                                </figcaption>
                            </figure>
                        </div>

                    </div>
                    <div className="mt-8 lg:mt-0">
                        <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                            <p className="mb-5">The iSober App is an advanced breathalyser test management tool designed to capture, share, 
                                track, and store breath alcohol measurements from our range of Bluetooth-enabled breathalysers. It serves 
                                a variety of users, from individuals and drivers to business owners and fleet operators who need to test 
                                drivers or employees remotely without being physically present.</p>
                            
                            <p className="mb-5">For individual users, iSober allows you to test yourself, with your results instantly captured 
                                and saved to the iSober cloud and able to be shared over messages and email. Each test result is accompanied 
                                by a photo, GPS location, device serial number, and date and time.</p>
                            
                            <p className="mb-5">For enterprise users, iSober offers robust fleet and business management features. 
                                Organisations that conduct daily, random, or mandatory breathalyser tests on drivers, employees, or contractors 
                                can leverage iSober to obtain precise breath alcohol test results. This helps in preventing intoxicated 
                                individuals from operating vehicles or entering workplaces, and provides strong evidence for any necessary 
                                disciplinary actions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;