import React from 'react';
import { useSigninCheck } from 'reactfire';
import SignInPage from './Components/SignInPage/index.js';
import { useUser } from 'reactfire';
import ApplicationHome from './ApplicationHome.js';

export default function AuthCheck() {
    const { status, data: signInCheckResult } = useSigninCheck();
    const { data: authUser } = useUser();

    if (status === 'loading') {
        return "LOADING (AuthCheck)...";
    } 
    
    if (signInCheckResult.signedIn === true && authUser != null && authUser.uid != null) {
        console.log("[AuthCheck] will render ApplicationHome");
        return <ApplicationHome authUser={authUser} />;
    } else {
        console.log("[AuthCheck] will render SignInPage");
        return <SignInPage />;
    }
}