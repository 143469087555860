import React, { Suspense } from 'react';
import { PageHeaderAlt } from '../Components/PageHeader/index.js';
import ReadingsByClient from './readingsbyclient.js';
import ReadingDetails from './readingdetails.js';
import ReadingsByUser from './readingsbyuser.js';
import { Routes, Route, useLocation } from 'react-router-dom';
import { GreenAlert } from '../../Shared/Components/alerts.js'
import ErrorBoundary from '../../Shared/Components/errorboundary.js'
import { LoadScript } from '@react-google-maps/api';

function DashboardPage(props) {
    const showMessage = false;
    const userinfoData = props.userinfo;
    const currentLocation = useLocation();

    // superadmin is only used in the Admin module so not relevant here
    /* function isSuperAdmin() {
        if (props.idToken && props.idToken.claims && props.idToken.claims.hasOwnProperty('superadmin') && props.idToken.claims.superadmin === true) {
            return true;            
        }

        return false;
    } */

    function isClientAdmin() {
        if (props.idToken && props.idToken.claims && props.idToken.claims.hasOwnProperty('clientadmin') && props.idToken.claims.clientadmin === true) {
            return true;
        }

        return false;
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeaderAlt location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-0 sm:px-0">

                    {showMessage && <GreenAlert message={"Client=" + userinfoData.clientid + "; " + userinfoData.fname + " " + userinfoData.lname + "; Email=" + userinfoData.email + "; Role=" + userinfoData.role} />}

                    <div className="py-4">
                        <ErrorBoundary>
                            <Suspense fallback={<div>Loading...</div>}>

                                {/* LoadScript must only be called 1x, so needs to encompass all components that have a <GoogleMap>
                                    https://github.com/JustFly1984/react-google-maps-api/issues/70 */}
                                <LoadScript googleMapsApiKey="AIzaSyBnmaVw-kcNkWWbExk9eBVrjgJKAeJ3pNE"> 
                                    {   // routes when user is a client admin
                                        (isClientAdmin() === true) &&
                                        <Routes>
                                            <Route path="*" element={<ReadingsByClient userinfo={userinfoData} />} />
                                            <Route path="reading/:readingId" element={<ReadingDetails userinfo={userinfoData} />} />
                                            <Route path="readings/user/:userId" element={<ReadingsByUser userinfo={userinfoData} />} />
                                        </Routes>
                                    }

                                    {   // routes when user is a normal user
                                        (isClientAdmin() !== true) &&
                                        <Routes>
                                            <Route path="*" element={<ReadingsByUser userinfo={userinfoData} />} />
                                            <Route path="reading/:readingId" element={<ReadingDetails userinfo={userinfoData} />} />
                                            <Route path="readings/user/:userId" element={<ReadingsByUser useruid={userinfoData} />} />
                                        </Routes>
                                    }
                                </LoadScript>
                            </Suspense>
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default DashboardPage;