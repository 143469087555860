import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PageHeaderAlt } from '../Components/PageHeader/index.js';
// import { UserIcon } from '@heroicons/react/solid'
import { DeviceIcon } from '../Components/Logos/logos.js';
import DeviceTab from './DeviceTab';
import UserTab from './UserTab';

export default function SocialUsersPage(props) {
    const tabs = [
        { name: 'Device', icon: DeviceIcon },
        // { name: 'User', icon: UserIcon },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    // hooks
    const currentLocation = useLocation();

    // state
    const [currentTab, setCurrentTab] = useState("Device");

    // functions

    function selectTab(e, tabName) {
        e.preventDefault();

        setCurrentTab(tabName);
    }

    function dropdownChange(e) {
        setCurrentTab(e.target.value);
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeaderAlt location={currentLocation.pathname} />
                <div className="max-w-7xl mx-auto px-0 sm:px-0">
                    {/* Replace with your content */}

                    <div>
                        {/* start tab control */}
                        <div>
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">Select a tab</label>
                                <select id="tabs" name="tabs" onChange={dropdownChange}
                                    className="block mt-4 mb-2 w-full focus:ring-isober-500 focus:border-isober-500 border-gray-300 rounded-md"
                                    defaultValue={tabs.find((tab) => tab.name).name}>
                                    {tabs.map((tab) => (
                                        <option key={tab.name}>{tab.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="hidden sm:block">
                                <div className="border-b border-gray-200">
                                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                        {tabs.map((tab) => (
                                            <a key={tab.name} href="#0" onClick={(e) => selectTab(e, tab.name)}
                                                className={classNames(
                                                    tab.name === currentTab
                                                        ? 'border-isober-500 text-isober-600'
                                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                    'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                                                )}
                                                aria-current={tab.name === currentTab ? 'page' : undefined}>
                                                <tab.icon
                                                    className={classNames(
                                                        tab.name === currentTab ? 'text-isober-500' : 'text-gray-400 group-hover:text-gray-500',
                                                        '-ml-0.5 mr-2 h-5 w-5'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                <span>{tab.name}</span>
                                            </a>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                        </div>
                        {/* end tab control */}

                        {(currentTab === "Device") && <DeviceTab userinfo={props.userinfo} />}
                        {(currentTab === "User") && <UserTab userinfo={props.userinfo} />}
                    </div>

                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}