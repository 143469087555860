import React from 'react';
import { useParams } from 'react-router-dom';
import useJobCard from '../Components/Hooks/useJobCard'; 
import useJobCardDevice from '../Components/Hooks/useJobcardDevice';
import CalibrateFragment from './calibrate';
import { useNavigate } from 'react-router-dom';

export default function CalibrateDevice(props) {
    // HOOKS
    const { jobcardId, deviceId } = useParams();
    const jobcardRecord = useJobCard(jobcardId);
    const deviceRecord = useJobCardDevice(jobcardId, deviceId);
    const navigate = useNavigate();

    // HELPERS

    const cancelHandler = () => {
        navigate(`/calibrations/jobcard/${jobcardId}`); // back where we came from
    }

    // validate parameters
    if (jobcardRecord === undefined || deviceRecord === undefined) {
        return <div>Error call a function</div>;
    }

    return (
        <div>
            { (deviceRecord !== undefined && jobcardRecord !== undefined) && <CalibrateFragment device={deviceRecord} jobcard={jobcardRecord} cancelHandler={cancelHandler} userinfo={props.userinfo} /> }
        </div >
    )
}