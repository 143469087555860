import React from 'react';
import Footer from '../Components/Footer/index';
import Header from '../Components/Header/index';

function PrivacyPage(props) {
    return (
        <div>
            <Header title="Privacy Statement" />


            <div className="relative py-16 bg-white overflow-hidden">
                <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                    <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                        <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                            <defs>
                                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                        </svg>
                        <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                            <defs>
                                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                        </svg>
                        <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                            <defs>
                                <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                        </svg>
                    </div>
                </div>
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <h3><b>Effective: March 1, 2019</b></h3>
                        <p className="mt-8 text-xl text-gray-500 leading-8">This Privacy Policy describes how iSober collects, uses and discloses information, and what choices you have with respect to the information.</p>

                        <h3 className="mt-8 text-xl text-black leading-8"><b>What Information We Collect and Process</b></h3>

                        <p className="mt-8 text-xl text-gray-500 leading-8">Services data. When an authorised user interacts with iSober, data is generated that is stored and processed. For example, iSober records the blood alcohol level, date and time, a photo, and breathalyser device serial number for each reading that it takes. If the user is not signed in to the iSober cloud, then readings are stored locally on the iPhone and are not transmitted off the device. If the user is signed in to the iSober cloud, then each reading will be transmitted to iSober cloud.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">Log data. iSober collects anonymous information when the app accesses our services and records it in log files. This log data may include the Internet Protocol (IP) address, the section of the app being used, the device details and settings, the date and time the services were used and language preferences. Information about blood alcohol readings is not collected or transmitted as part of log data.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">Device information. iSober collects information about devices accessing the Services, including type of device, what operating system is used, device settings, application IDs, unique device identifiers and crash data. Whether we collect some or all of this other information often depends on the type of device used and its settings.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">Location information. Location information is an important part of verifying the location where a blood alcohol reading was taken and an important control measure. The iSober app gathers location information, which may be your precise location. This information is stored alongside a blood alcohol reading and possibly a photo.</p>

                        <h3 className="mt-8 text-xl text-black leading-8"><b>How We Use Information</b></h3>

                        <p className="mt-8 text-xl text-gray-500 leading-8">iSober uses information to provide, update, maintain and protect our services, web sites and business. This includes use of other information to support delivery of the services under a customer agreement, prevent or address service errors, security or technical issues, analyse and monitor usage, trends and other activities or at an authorised user's request.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">As required by applicable law, legal process or regulation.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">To communicate with you by responding to your requests, comments and questions. If you contact us, we may use your information to respond.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">To develop and provide search, learning and productivity tools and additional features. iSober tries to make the services as useful as possible. For example, we may improve search functionality by using information to help determine and rank the relevance of content, channels or expertise to an authorised user, make services suggestions based on historical use and predictive models, identify organisational trends and insights, to customise a services experience or create new productivity features and products.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">To send emails and other communications. We may send you service, technical and other administrative emails, messages and other types of communications. We may also contact you to inform you about changes in our Services, our Services offerings, and important Services-related notices, such as security and fraud notices. These communications are considered part of the Services and you may not opt out of them. In addition, we sometimes send emails about new product features, promotional communications or other news about iSober. These are marketing messages so you can control whether you receive them.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">For billing, account management and other administrative matters. iSober may need to contact you for invoicing, account management and similar reasons and we use account data to administer accounts and keep track of billing and payments.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">To investigate and help prevent security issues and abuse.</p>

                        <h3 className="mt-8 text-xl text-black leading-8"><b>Requesting Data Deletion</b></h3>

                        <p className="mt-8 text-xl text-gray-500 leading-8">You have the right to request at any time that iSober deletes from its systems both your account 
                        as well as any measurements and related data that are stored in cloud.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">To request that your data be deleted, please send an email to <a href="mailto:info@breathalysers.co.za">info@breathalysers.co.za</a> from the email address that you use to sign in to the app. Our support team will confirm the request by return email and once recieved, will delete your data. After your data is deleted, you will receive confirmation that your data has been deleted and no records retained.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">iSober will remove from its platform and systems any data you have created or saved within the app such as measurements and associated photos, as well as your user profile record. No data will remain on our systems once this action has been taken. There is no additional retention period.</p>
                        
                        <h3 className="mt-8 text-xl text-black leading-8"><b>Data Retention</b></h3>

                        <p className="mt-8 text-xl text-gray-500 leading-8">iSober will retain Customer Data in accordance with a Customer's instructions, including any applicable terms in the Customer Agreement and Customer's use of Services functionality, and as required by applicable law. </p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">Depending on the Services plan, Customer may be able to customise its retention settings and apply those customised settings. iSober may retain Other Information pertaining to you for as long as necessary for the purposes described in this Privacy Policy. This may include keeping your Other Information after you have deactivated your account for the period of time needed for iSober to pursue legitimate business interests, conduct audits, comply with (and demonstrate compliance with) legal obligations, resolve disputes and enforce our agreements.</p>

                        <h3 className="mt-8 text-xl text-black leading-8"><b>How We Share And Disclose Information</b></h3>

                        <p className="mt-8 text-xl text-gray-500 leading-8">Customer's Instructions. iSober will solely share and disclose customer data in accordance with a customer's instructions, including any applicable terms in the customer agreement and the customer's use of services functionality, and in compliance with applicable law and legal process.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">Customer Access. Owners, administrators, authorised users and other customer representatives and personnel may be able to access, modify or restrict access to other information. This may include, for example, your employer viewing your blood alcohol reading, using service features to view logs of activity, or accessing or modifying your profile details.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">Third Party Service Providers and Partners. We may engage third party companies or individuals as service providers or business partners to process customer information and support our business. These third parties may, for example, provide software development, infrastructure management or virtual computing and storage services.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">During a Change to iSober's Business. If iSober engages in a merger, acquisition, bankruptcy, dissolution, reorganisation, sale of some or all of iSober's assets or stock, financing, public offering of securities, acquisition of all or a portion of our business, a similar transaction or proceeding, or steps in contemplation of such activities (e.g. due diligence), some or all customer information may be shared or transferred, subject to standard confidentiality arrangements.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">To Comply with Laws. If we receive a request for information, we may disclose customer information if we reasonably believe disclosure is in accordance with or required by any applicable law, regulation or legal process.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">To enforce our rights, prevent fraud, and for safety. To protect and defend the rights, property or safety of iSober or third parties, including enforcing contracts or policies, or in connection with investigating and preventing fraud or security issues.</p>

                        <h3 className="mt-8 text-xl text-black leading-8"><b>Security</b></h3>

                        <p className="mt-8 text-xl text-gray-500 leading-8">iSober works hard to protect information you provide from loss, misuse, and unauthorised access or disclosure. These steps take into account the sensitivity of the information we collect, process and store, and the current state of technology. Given the nature of communications and information processing technology, iSober cannot guarantee that information, during transmission through the Internet or while stored on our systems or otherwise in our care, will be absolutely safe from intrusion by others.</p>

                        <h3 className="mt-8 text-xl text-black leading-8"><b>Changes To This Privacy Policy</b></h3>

                        <p className="mt-8 text-xl text-gray-500 leading-8">iSober may change this privacy policy from time to time. Laws, regulations and industry standards evolve, which may make those changes necessary, or we may make changes to our business. We will post the changes to this page and encourage you to review our privacy policy to stay informed. If we make changes that materially alter your privacy rights, iSober will provide additional notice, such as via email or through the service itself. If you disagree with the changes to this privacy policy, you should deactivate your services account.</p>

                        <h3 className="mt-8 text-xl text-black leading-8"><b>International Data Transfers</b></h3>

                        <p className="mt-8 text-xl text-gray-500 leading-8">iSober may transfer your personal data to countries other than the one in which you live.</p>

                        <h3 className="mt-8 text-xl text-black leading-8"><b>Data Protection Officer</b></h3>

                        <p className="mt-8 text-xl text-gray-500 leading-8">To communicate with our Data Protection Officer, please email <a href="mailto:angusbreathalysers.co.za">angus@breathalysers.co.za</a>.</p>

                        <h3 className="mt-8 text-xl text-black leading-8"><b>Identifying the Data Controller and Processor</b></h3>

                        <p className="mt-8 text-xl text-gray-500 leading-8">Data protection law in certain jurisdictions differentiates between the "controller" and "processor" of information. In general, the customer is the controller of customer data. In general, iSober is the processor of customer data and the controller of other information.</p>

                        <h3 className="mt-8 text-xl text-black leading-8"><b>Your Rights</b></h3>

                        <p className="mt-8 text-xl text-gray-500 leading-8">Individuals located in certain countries, including the European Economic Area, have certain statutory rights in relation to their personal data. Subject to any exemptions provided by law, you may have the right to request access to information, as well as to seek to update, delete or correct this information. You can usually do this using the settings and tools provided in your services account. If you cannot use the settings and tools, contact customer support for additional access and assistance.</p>

                        <p className="mt-8 text-xl text-gray-500 leading-8">To the extent that iSober's processing of your personal data is subject to the General Data Protection Regulation (GDPR), iSober relies on its legitimate interests, described above, to process your data. iSober may also process other information that constitutes your personal data for direct marketing purposes and you have a right to object to iSober's use of your personal data for this purpose at any time.</p>

                        <p>&nbsp;</p>

                    </div>
                </div>
            </div>


            <Footer />
        </div>
    )
}

export default PrivacyPage;
