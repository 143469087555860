import React, { useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from "yup"
import ReadingsBySerial from './ReadingsBySerial';

export default function DeviceTab(props) {
    // state
    const [queryTerm, setQueryTerm] = useState(false);

    // formik

    const searchInitialValues = { email: "" };
    const searchSchema = Yup.object({
        serialno: Yup.string("Enter a valid serial number").required("Required")
    });

    // functions

    async function handleSubmit(form) {
        setQueryTerm(form.serialno);
    }

    return (
        <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Search by device serial number</h3>
                {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Subtitle</p>*/}
            </div>
            <div className="border-t border-gray-200">
                <div className="py-8 px-8">

                    <p>Enter a serial number below to search. <span className="text-gray-500">Note: all requests for personally-identifying information are logged.</span>
                    <br/><span className="text-gray-500">E.g. E8161F3E917, EE419F2F9B8A, F267FC161348</span></p>

                    <Formik initialValues={searchInitialValues} onSubmit={handleSubmit} validationSchema={searchSchema} >
                        {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values }) => (
                            <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">

                                <div className="mt-4">
                                    <ErrorMessage name="serialno" component="span" className="text-red-700 italic" />
                                    <div className="m-0 relative rounded-md shadow-sm">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-gray-400">
                                                <path d="M16.5 7.5h-9v9h9v-9z" />
                                                <path fillRule="evenodd" d="M8.25 2.25A.75.75 0 019 3v.75h2.25V3a.75.75 0 011.5 0v.75H15V3a.75.75 0 011.5 0v.75h.75a3 3 0 013 3v.75H21A.75.75 0 0121 9h-.75v2.25H21a.75.75 0 010 1.5h-.75V15H21a.75.75 0 010 1.5h-.75v.75a3 3 0 01-3 3h-.75V21a.75.75 0 01-1.5 0v-.75h-2.25V21a.75.75 0 01-1.5 0v-.75H9V21a.75.75 0 01-1.5 0v-.75h-.75a3 3 0 01-3-3v-.75H3A.75.75 0 013 15h.75v-2.25H3a.75.75 0 010-1.5h.75V9H3a.75.75 0 010-1.5h.75v-.75a3 3 0 013-3h.75V3a.75.75 0 01.75-.75zM6 6.75A.75.75 0 016.75 6h10.5a.75.75 0 01.75.75v10.5a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V6.75z" clipRule="evenodd" />
                                            </svg>
                                        </div>

                                        <input type="text" name="serialno" id="serialno" onChange={handleChange} onBlur={handleBlur} className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md" placeholder="Serial number" />
                                    </div>
                                </div>

                                <div className="mt-6 sm:border-t sm:border-gray-200">
                                    <div className="pt-4 flex justify-end">
                                        <button type="submit" disabled={!dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Search</button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>

                    <div>
                        { /* Serial F6435E7A4619 in doc 0mMmwLxrUX9na1Z13PaT not found! */}
                        { queryTerm && <ReadingsBySerial serialno={queryTerm}/>}
                        {/*<ReadingsBySerial serialno="F267FC161348"/>*/}
                    </div>

                </div>
            </div>
        </div>
    )
}