import React from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { Routes, Route, useLocation } from 'react-router-dom';
import CalibrationsMain from './search.js';

export default function CalibrationsPage(props) {
    const currentLocation = useLocation();

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">

                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-0 sm:px-0">
                    {/* Replace with your content */}

                    <Routes>
                        <Route path="*" element={<CalibrationsMain userinfo={props.userinfo} />} />
                    </Routes>

                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}