import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import UpdateBalanceModal from './UpdateBalanceModal';
import useSkus from './useSkus';

export default function DashboardTable( { category } ) {
    // STATE
    var [updateSkuModalOpen, setUpdateSkuModalOpen] = useState(false);
    var [selectedSkuItem, setSelectedSkuItem] = useState(undefined);

    // HOOKS
    let navigate = useNavigate();
    let skus = useSkus(category);

    // HELPERS

    const showUpdateSku = (e, item) => {
        e.preventDefault();
        setSelectedSkuItem(item);
        setUpdateSkuModalOpen(true);
    }

    const sum = (itemBalance) => {
        var retval = 0;

        // validate
        if (!itemBalance || typeof itemBalance !== 'object') {
            return 0;
        }

        // being careful about string and numeric types with firebase

        if (itemBalance.jhb && !isNaN(itemBalance.jhb)) {
            retval += parseInt(itemBalance.jhb);
        }

        if (itemBalance.ct && !isNaN(itemBalance.ct)) {
            retval += parseInt(itemBalance.ct);
        }

        if (itemBalance.dbn && !isNaN(itemBalance.dbn)) {
            retval += parseInt(itemBalance.dbn);
        }

        if (itemBalance.bw && !isNaN(itemBalance.bw)) {
            retval += parseInt(itemBalance.bw);
        }

        if (itemBalance.cs && !isNaN(itemBalance.cs)) {
            retval += parseInt(itemBalance.cs);
        }

        return retval;
    }

    // HANDLER CALLBACKS

    const closeUpdateHandler = () => {
        setUpdateSkuModalOpen(false);
        setSelectedSkuItem(undefined);
    }

    const showHistory = (e) => {
        e.preventDefault();

        navigate("/stock/sku/guid");
    }

    return (
        <div>
            {updateSkuModalOpen === true && <UpdateBalanceModal item={selectedSkuItem} handler={closeUpdateHandler} />}

            <div>
                {/* Empty state */}
                {skus.length === 0 && <div className="mt-6 relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <div className="text-center">
                        <p className="text-lg leading-6 font-medium text-gray-900">No SKUs</p>
                        <p className="mt-2 text-sm text-gray-500">
                            No SKUs found for the selected category.
                        </p>
                    </div>
                </div>}

                {/* Not empty state */}
                {skus.length > 0 && <table className="mt-6 min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr key='hdr'>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SKU</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Jhb</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">CT</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">DBN</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">BW</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">CS</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {skus.map((item, itemIdx) => (
                            <tr key={itemIdx} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                    <span onClick={showHistory} className='underline cursor-pointer'>{item.sku}</span>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                    {(item.balance && item.balance.jhb) && item.balance.jhb}
                                    {(!item.balance || item.balance.jhb === undefined) && "0"}
                                    {(item.demobalance !== undefined && item.demobalance.jhb !== undefined && item.demobalance.jhb > 0) && ` (${item.demobalance.jhb})`}
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                    {(item.balance && item.balance.ct) && item.balance.ct}
                                    {(!item.balance || item.balance.ct === undefined) && "0"}
                                    {(item.demobalance !== undefined && item.demobalance.ct !== undefined && item.demobalance.ct > 0) && ` (${item.demobalance.ct})`}
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                    {(item.balance && item.balance.dbn) && item.balance.dbn}
                                    {(!item.balance || item.balance.dbn === undefined) && "0"}
                                    {(item.demobalance !== undefined && item.demobalance.dbn !== undefined && item.demobalance.dbn > 0) && ` (${item.demobalance.dbn})`}
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                    {(item.balance && item.balance.bw) && item.balance.bw}
                                    {(!item.balance || item.balance.bw === undefined) && "0"}
                                    {(item.demobalance !== undefined && item.demobalance.bw !== undefined && item.demobalance.bw > 0) && ` (${item.demobalance.bw})`}
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                    {(item.balance && item.balance.cs) && item.balance.cs}
                                    {(!item.balance || item.balance.cs === undefined) && "0"}
                                    {(item.demobalance !== undefined && item.demobalance.cs !== undefined && item.demobalance.cs > 0) && ` (${item.demobalance.cs})`}
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                    {sum(item.balance)}
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                    <button onClick={(e) => showUpdateSku(e, item)} className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                        Update
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
            </div>
        </div>
    )
}