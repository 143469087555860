import { collection, query, where, orderBy, limit, startAfter, startAt } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export default function usePaginatedClientUsers(clientid, rpp, startItem, include) {
    const firestore = useFirestore();
    const userinfoCollection = collection(firestore, 'userinfo');
    
    var activeQuery = undefined;
    if (startItem === null) {
        activeQuery = query(userinfoCollection, where('clientid', '==', clientid), where('enabled', '==', true), orderBy('lname', 'asc'), limit(rpp));
    } else {
        activeQuery = (include) ? query(userinfoCollection, where('clientid', '==', clientid), where('enabled', '==', true), orderBy('lname', 'asc'), limit(rpp), startAt(startItem)) : query(userinfoCollection, where('clientid', '==', clientid), where('enabled', '==', true), orderBy('lname', 'asc'), limit(rpp), startAfter(startItem));
    }

    const { status, data: users } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return users;
}