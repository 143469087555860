import React, { useState, useEffect } from 'react';
import { formatDate } from '../../Shared/Components/functions.js';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import FileSaver from "file-saver";
import { usePaginatedCertificates } from './usePaginatedCertificates.js';

export default function RecentCertificatesTab(props) {
    // STATE
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedCertificates()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const resultsPerPage = 20;

    // FIRESTORE
    const certificates = usePaginatedCertificates(referenceItem, include, resultsPerPage);
    const currentLastVisibleItem = (certificates !== undefined) ? certificates.docs[certificates.size - 1] : null;

    // HELPERS

    const trim = (str, ch) => {
        var start = 0,
            end = str.length;

        while (start < end && str[start] === ch)
            ++start;

        while (end > start && str[end - 1] === ch)
            --end;

        return (start > 0 || end < str.length) ? str.substring(start, end) : str;
    }

    const friendlyCompanyForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Company";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";

        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;

            if (alphaNumeric.test(str[i])) {
                retval += str[i];
            } else if (retval.slice(-1) !== "_") {
                retval += "_";
            }
        }

        // remove leading/trailing underscores
        retval = trim(retval, "_");

        // return something if end up with nothing
        if (!retval) {
            return "Company";
        }

        return retval;
    }

    const friendlySerialForFilename = (inputString) => {
        // return something if passed nothing
        if (!inputString) {
            return "Number";
        }

        // inputString should be truthy from here
        const str = inputString.trim()
        var retval = "";

        // only keep alphanumeric characters, rest become underscore
        for (var i = 0; i < str.length; i++) {
            var alphaNumeric = /^[a-zA-Z0-9]$/;

            if (alphaNumeric.test(str[i])) {
                retval += str[i];
            } else if (retval.slice(-1) !== "-") {
                retval += "-";
            }
        }

        // remove leading/trailing dashes
        retval = trim(retval, "-");

        // return something if end up with nothing
        if (!retval) {
            return "Number";
        }

        return retval;
    }

    const servePdf = (certificate) => {
        // compose filename overloaded with fields from the record:
        var friendlyCompanyName = friendlyCompanyForFilename(certificate.data().certificateCustomerName);
        var friendlyCertificateNo = friendlySerialForFilename(certificate.data().serial);
        
        var friendlySerialNo = friendlySerialForFilename(certificate.data().serialIdx.length > 1 ? `${certificate.data().serialIdx[0]}_${certificate.data().serialIdx[1]}` : certificate.data().serialIdx[0]);
        // var friendlySerialNo = friendlySerialForFilename(certificate.data().serialIdx[0]);
        
        var friendlyFileName = `Certificate_${friendlyCompanyName}_${friendlySerialNo}_${friendlyCertificateNo}.pdf`;

        setIsDownloading(true);
        const storage = getStorage();
        getDownloadURL(ref(storage, `certificates/${certificate.id}.pdf`))
            .then((url) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    setIsDownloading(false);
                    FileSaver.saveAs(blob, friendlyFileName);
                };
                xhr.onerror = (error) => {
                    setIsDownloading(false);
                    window.alert("Error: could not download file");
                }
                xhr.open('GET', url);
                xhr.send();
            })
            .catch((error) => {
                setIsDownloading(false);
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        window.alert("Error: object not found");
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        window.alert("Error: unauthorized");
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        window.alert("Error: request cancelled");
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        window.alert("Unknown error occurred");
                        break;
                    default:
                        window.alert("Unknown error occurred");
                        break;
                }
            });
    }

    // PAGINATION HELPERS

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, certificates.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (certificates !== undefined && certificates.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (certificates !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, certificates]);

    return (
        <div>
            {/* start A */}
            <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Recently generated certificates</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">The following certificates have been generated and are ready for download.</p>
                </div>

                {certificates.docs.length > 0 && <div className="relative bg-white rounded-md -space-y-px">
                    {certificates.docs.map((certificate, idx) => (
                        <div key={idx} className="rounded-tl-md rounded-tr-md relative border p-4 flex flex-col md:pl-4 md:pr-6 md:grid md:grid-cols-8 focus:outline-none">
                            {/* col 1 model */}
                            <div className="flex items-center text-sm md:col-span-3">
                                <span id="pricing-plans-0-label" className="ml-3 font-medium">
                                    <span className='font-semibold'>{certificate.data().modelName}</span><br />
                                    {certificate.data().serialIdx.map((serial /*, index*/) => {
                                        return (
                                            <div className='text-gray-400'>{serial}</div>
                                        );
                                    })}
                                </span>
                            </div>

                            {/* col 2 customer */}
                            <p id="pricing-plans-0-description-0" className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-left md:col-span-3">
                                <span className='font-semibold'>{certificate.data().certificateCustomerName}</span> &nbsp;
                                {certificate.data().reissue !== undefined && certificate.data().reissue === true && <span className="mt-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-pink-100 text-pink-800"> Reissue </span>}
                                <br /> Generated {formatDate(certificate.data().created.toDate())}
                            </p>

                            {/* col 3 certificate no */}
                            <p id="pricing-plans-0-description-1" className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">
                                <span className="">No. {certificate.data().serial}</span>
                            </p>

                            {/* col 4 button */}
                            <div id="pricing-plans-0-description-2" className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right md:col-span-1">
                                <div className="ml-4 flex-shrink-0">
                                    <button onClick={() => servePdf(certificate)} disabled={isDownloading} className="disabled:opacity-50 ml-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>}

            </div>
            {/* end A */}

            {certificates.docs.length === 0 && <div className="mt-6 relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <div className="text-center">
                    <p className="text-lg leading-6 font-medium text-gray-900">No Certificates</p>
                    <p className="mt-2 text-sm text-gray-500">
                        No certificates found; they will appear here as they are recorded.
                    </p>
                </div>
            </div>}

            {/* pagination area */}
            {certificates.docs.length > 0 &&
                <nav className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{(firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>

                        <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            }

        </div>
    )
}