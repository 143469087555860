import React, { useState } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import useUserInfo from '../Components/UserInfo/index';
import * as Yup from "yup"
import { Formik, ErrorMessage } from 'formik';
import { getIdToken } from "firebase/auth";
import { useAuth } from 'reactfire';

export default function EditUserFragment(props) {
    // user info fields
    const { userId } = useParams();
    const { clientId } = useParams();
    const userInfo = useUserInfo(userId);
    const hasAlerts = (userInfo.alerts === true) ? true : false;
    const isSuperadmin = (userInfo.role === 'superadmin') ? true : false;
    const isClientadmin = (userInfo.role === 'clientadmin' || isSuperadmin) ? true : false;

    // hooks
    const auth = useAuth();
    const navigate = useNavigate();

    // state
    const [deactivateConfirmOpen, setDeactivationConfirmOpen] = useState(false);
    const [deactivateSuccessOpen, setDeactivationSuccessOpen] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [deactivateLoading, setDeactivateLoading] = useState(false);
    const [updateSuccessOpen, setUpdateSuccessOpen] = useState(false);
    const [updateBannerOpen, setUpdateBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);

    // formik (note react breaks if default value for idnumber is null, requires empty string)
    const editUserInitialValues = { idnumber: (userInfo.idnumber === null) ? "" : userInfo.idnumber, fname: userInfo.fname, lname: userInfo.lname, email: userInfo.email, clientadmin: isClientadmin, superadmin: isSuperadmin, alertstab: hasAlerts };
    const editUserValidationSchema = Yup.object({
        fname: Yup.string("Enter a first name").required("First name is required"),
        lname: Yup.string("Enter a last name").required("Last name is required"),
        idnumber: Yup.string("").matches(/^(\s*|\d{13})$/, "Enter a valid ID number").nullable(),
        email: Yup.string("Enter a valid email address").email("Invalid").required("Required"),
        alertstab: Yup.boolean("Alerts tab"),
        clientadmin: Yup.boolean("Client administrator"),
        superadmin: Yup.boolean("iSober administrator"),
    });

    function showDeactivateConfirm() {
        setDeactivationConfirmOpen(true);
    }

    function hideDeactivateConfirm() {
        setDeactivationConfirmOpen(false);
    }

    function showDeactivateSuccess() {
        hideDeactivateConfirm();
        setDeactivationSuccessOpen(true);
    }

    function hideDeactivateSuccess() {
        setDeactivationSuccessOpen(false);
        navigate(`/system/clients/users/${clientId}`, {replace: true}); // outta here
    }

    function hideUpdateSuccess() {
        setUpdateSuccessOpen(false);
        navigate(`/system/clients/users/${clientId}`, {replace: true}); // outta here
    }

    function startLoading() {
        setFormLoading(true);
    }

    function stopLoading() {
        setFormLoading(false);
    }

    function showErrorBanner(msg) {
        setLastErrorMessage(msg);
        setUpdateBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setUpdateBannerOpen(false);
    }

    // Web API for deactivate user
    async function deactivateUser() {
        closeErrorBanner(); // in case open
        setDeactivateLoading(true);

        // valid client ID or bail
        if (clientId === undefined || clientId === null) {
            setFormLoading(false);
            showErrorBanner("Client ID in invalid, please contact Support if this error persists.");
            return;
        }

        // valid user ID or bail
        if (userId === undefined || userId === null) {
            setFormLoading(false);
            showErrorBanner("User ID in invalid, please contact Support if this error persists.");
            return;
        }        

        const payload = { "client": clientId, "useruid": userId }

        // go...
        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isober.co.za/api/userdel', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.error) {
                            setDeactivationConfirmOpen(false);
                            setLastErrorMessage(data.message);
                            setUpdateBannerOpen(true);
                        } else {
                            showDeactivateSuccess();
                        }
                    });
            });
    }

    // Web API for update user details
    async function handleSubmit(form) {
        closeErrorBanner();
        startLoading();

        // merge in from router's path
        form.useruid = userId;
        form.clientid = userInfo.clientid;

        // valid client ID or bail
        if (form.clientid === undefined || form.clientid === null) {
            setFormLoading(false);
            showErrorBanner("Client ID in invalid, please contact Support if this error persists.");
            return;
        }

        // valid user ID or bail
        if (form.useruid === undefined || form.useruid === null) {
            setFormLoading(false);
            showErrorBanner("User ID in invalid, please contact Support if this error persists.");
            return;
        }

        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isober.co.za/api/usermod', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(form)
                })
                    .then(response => response.json())
                    .then(data => {
                        stopLoading();
                        if (data.error) {
                            setLastErrorMessage(data.message);
                        } else {
                            setUpdateSuccessOpen(true);
                        }
                    });
            });
    }

    return (
        <div>
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500">Use the form below to edit the details for <b>{userInfo.fname} {userInfo.lname}</b>.</p>
                    {/*<p className="mt-2 max-w-4xl text-sm text-gray-500">{JSON.stringify(userInfo)}</p>*/}
                </div>
                <div className="mt-3 flex sm:mt-0 sm:ml-4">
                    <button type="button" onClick={showDeactivateConfirm} className="ml-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-red-700 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                        Deactivate user
                    </button>
                </div>
            </div>

            { /* Deactivate user modal */}
            {deactivateConfirmOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Deactivate account</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Are you sure you want to deactivate this account? All of the user's data will remain for audit and reference, however the user's login will be removed forever. This action cannot be undone.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button type="button" disabled={deactivateLoading} onClick={deactivateUser} className="disabled:opacity-50 disabled:bg-red-700 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-700 text-base font-medium text-white hover:bg-red-600 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm">
                                Deactivate
                            </button>
                            <button type="button" onClick={hideDeactivateConfirm} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm">
                                Cancel
                            </button>
                            {deactivateLoading &&
                                <div className="w-full inline-flex justify-center px-4 py-2 bg-white sm:w-auto">
                                    <img src="/images/loading.gif" width="25" height="25" alt="" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }

            {/* Decativate modal confirmation */}
            {deactivateSuccessOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    {/*  This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                        <div>
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                {/* Heroicon name: outline/check */}
                                <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Confirmation</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        The user was deactivated successfully.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <button onClick={hideDeactivateSuccess} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none sm:text-sm">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

            {/* Update user modal confirmation */}
            {updateSuccessOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    {/*  This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                        <div>
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                {/* Heroicon name: outline/check */}
                                <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Confirmation</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        The user details were updated successfully.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <button onClick={hideUpdateSuccess} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none sm:text-sm">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

            <div className="bg-white shadow overflow-hidden sm:rounded-lg px-4 py-5 sm:px-6">

                <Formik initialValues={editUserInitialValues} onSubmit={handleSubmit} validationSchema={editUserValidationSchema} >
                    {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values }) => (
                        <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                <div>
                                    <div>
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Edit user</h3>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Enter the form below.</p>
                                    </div>

                                    <div className="border-t border-gray-200 mt-6 py-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                                        {formLoading &&
                                            <div className="sm:col-span-6">
                                                <img src="/images/loading.gif" width="40" height="40" alt="" className="mb-4" />
                                            </div>
                                        }

                                        {/* Error banner */}
                                        {updateBannerOpen && <div className="sm:col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                                    </svg>
                                                </div>
                                                <div className="ml-3">
                                                    <p className="text-sm text-yellow-700">{lastErrorMessage}</p>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        <div className="sm:col-span-3">
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address</label>
                                            <div className="mt-1">
                                                <input type="text" name="email" id="email" readOnly={true} value={values.email}
                                                    className="text-gray-400 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-300 focus:border-gray-300 sm:text-sm" />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-3">
                                            <label htmlFor="idnumber" className="block text-sm font-medium text-gray-700">
                                                SA ID number &nbsp;
                                                <ErrorMessage name="idnumber" component="span" className="text-red-700 italic" />
                                            </label>
                                            <div className="mt-1">
                                                <input type="text" name="idnumber" id="idnumber" placeholder="Optional" value={values.idnumber} onChange={handleChange} onBlur={handleBlur}
                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="fname" className="block text-sm font-medium text-gray-700">
                                                First name &nbsp;
                                                <ErrorMessage name="fname" component="span" className="text-red-700 italic" />
                                            </label>
                                            <div className="mt-1">
                                                <input type="text" name="fname" id="fname" value={values.fname} onChange={handleChange} onBlur={handleBlur}
                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-3">
                                            <label htmlFor="lname" className="block text-sm font-medium text-gray-700">
                                                Last name &nbsp;
                                                <ErrorMessage name="lname" component="span" className="text-red-700 italic" />
                                            </label>
                                            <div className="mt-1">
                                                <input type="text" name="lname" id="lname" value={values.lname} onChange={handleChange} onBlur={handleBlur}
                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Role</label>
                                            <div className="mt-1">
                                                <div className="relative flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <input id="clientadmin" name="clientadmin" type="checkbox" defaultChecked={values.clientadmin} onChange={handleChange} onBlur={handleBlur}
                                                            className="focus:ring-isober-1000 h-4 w-4 text-isober-1000 border-gray-300 rounded" />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor="clientadmin" className="text-gray-700">Client administrator</label>
                                                        <p className="text-gray-500">User is able to perform administrative tasks in this account.</p>
                                                    </div>
                                                </div>

                                                {isSuperadmin && <div className="relative flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <input id="superadmin" name="superadmin" type="checkbox" defaultChecked={values.superadmin} onChange={handleChange} onBlur={handleBlur}
                                                            className="focus:ring-isober-1000 h-4 w-4 text-isober-1000 border-gray-300 rounded" />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor="superadmin" className="text-gray-700">iSober administrator</label>
                                                        <p className="text-gray-500">User is able to perform administrative tasks within iSober.</p>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>

                                        <div className="sm:col-span-3">
                                            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">Features</label>
                                            <div className="mt-1">
                                                <div className="relative flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <input id="alertstab" name="alertstab" type="checkbox" defaultChecked={values.alertstab} onChange={handleChange} onBlur={handleBlur}
                                                            className="focus:ring-isober-1000 h-4 w-4 text-isober-1000 border-gray-300 rounded" />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor="alertstab" className="text-gray-700">Measurement alerts</label>
                                                        <p className="text-gray-500">Configure measurement alerts on the web to receive push notifications on mobile.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>





                            <div className="pt-5">
                                <div className="flex justify-end">
                                    <Link to={`/system/clients/${clientId}/user/${userId}`}>
                                        <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Cancel</button>
                                    </Link>
                                    <button type="submit" disabled={!dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-1000 hover:bg-isober-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Save</button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}