import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { collection, query, where, orderBy } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// state is passed up via props.handler

// note how we deconstruct props.sfv so doesn't update useEffect on every prop change
// per https://stackoverflow.com/questions/63205014/react-hook-useeffect-has-a-missing-dependency-props

export default function DeviceChooser({ handler, sfv }) {
    // STATE
    const [selected, setSelected] = useState(null)

    // FIRESTORE
    const firestore = useFirestore();
    const lostDevicesCollection = collection(firestore, `devicemodels`);
    const activeQuery = query(lostDevicesCollection, where('deleted', '==', false), orderBy('displayname', 'asc'));
    const { data: devices } = useFirestoreCollection(activeQuery);

    // when selected changes, use callback to lift state up to parent
    React.useEffect(() => {
        if (selected) {
            handler({id: selected.id, modelName: selected.displayname, ledStyle: selected.ledStyle}, sfv);
        }
    }, [selected, handler, sfv])

    if (devices === undefined) {
        return "Loading...";
    }

    return (
        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <>
                    <div className="mt-1 relative">
                        <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500 sm:text-sm">
                        <span className="block truncate">{selected === null ? 'Choose...' : selected.displayname}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {devices.docs.map((device) => (
                                    <Listbox.Option key={device.id} className={({ active }) =>
                                            classNames(
                                                active ? 'text-white bg-isober-600' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-8 pr-4'
                                            )
                                        }
                                        value={{...device.data(), id: device.id}}>
                                        {({ selected, active }) => (
                                            <>
                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                    {device.data().displayname}
                                                </span>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-isober-600',
                                                            'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}