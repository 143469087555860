import React, { useState } from 'react';
import { useAuth } from 'reactfire';
import { getIdToken } from "firebase/auth";
import { Formik } from 'formik';
import * as Yup from "yup"
import useClientInfo from './useclient';

export default function ClientApiTab(props) {
    // hooks
    const auth = useAuth();
    const clientInfo = useClientInfo(props.clientId);

    // initial boolean
    const initialEnabled = (clientInfo.clientapi !== undefined && clientInfo.clientapi.enabled !== undefined) ? clientInfo.clientapi.enabled : false;

    // form validation
    const apiInitialValues = { apiEnabled: initialEnabled };
    const apiValidationSchema = Yup.object({
        apiEnabled: Yup.boolean("API enabled")
    });

    // state
    const [formLoading, setFormLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);

    function showSuccessDialog() {
        setSuccessDialogOpen(true);
    }

    function hideSuccessDialog() {
        setSuccessDialogOpen(false);
    }

    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    async function handleSubmit(form) {
        closeErrorBanner();
        setFormLoading(true);

        // merge in client ID from props
        form.clientId = props.clientId;

        // validate or bail
        if (form.clientId === undefined || form.clientId === null) {
            showErrorBanner("Error: invalid client ID");
        }

        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isober.co.za/api/apimod', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(form)
                })
                    .then(response => response.json())
                    .then(data => {
                        setFormLoading(false);
                        if (data.error) {
                            showErrorBanner(data.message);
                        } else {
                            showSuccessDialog();
                        }
                    });
            })
            .catch((errorJwt) => {
                showErrorBanner("Error: invalid auth token");
            });
    }

    return (
        <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">API integration</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Communicate the Client ID and the API Key to the client.</p>
            </div>

            {/* Decativate modal confirmation */}
            {successDialogOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                    {/*  This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                        <div>
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                {/* Heroicon name: outline/check */}
                                <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Confirmation</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        The client's API setting was updated successfully.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <button onClick={hideSuccessDialog} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-1000 text-base font-medium text-white hover:bg-isober-800 focus:outline-none sm:text-sm">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

            <div className="border-t border-gray-200">
                <div className="py-5 px-8">

                    <Formik initialValues={apiInitialValues} onSubmit={handleSubmit} validationSchema={apiValidationSchema} >
                        {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values }) => (
                            <form onSubmit={handleSubmit} className="">

                                {formLoading &&
                                    <div className="sm:col-span-6">
                                        <img src="/images/loading.gif" width="40" height="40" alt="" className="mb-4" />
                                    </div>
                                }

                                {/* Error banner */}
                                {errorBannerOpen && <div className="sm:col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-sm text-yellow-700">{lastErrorMessage}</p>
                                        </div>
                                    </div>
                                </div>
                                }

                                <div className="relative flex items-start">
                                    <div className="flex items-center h-5">
                                        <input id="apiEnabled" name="apiEnabled" type="checkbox" defaultChecked={initialEnabled} onChange={handleChange} onBlur={handleBlur} className="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300 rounded" />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="apiEnabled" className="">Enable API integration</label>
                                    </div>
                                </div>

                                {values.apiEnabled && <div>
                                    <div className="mt-5 text-sm">
                                        <p htmlFor="candidates" className="font-medium text-gray-700">Client ID</p>
                                        <p className="text-gray-500">{props.clientId}</p>
                                    </div>
                                    <div className="mt-5 text-sm">
                                        <p htmlFor="candidates" className="font-medium text-gray-700">API Key</p>

                                        {(clientInfo.clientapi === undefined || clientInfo.clientapi.key === undefined) && <p className="text-gray-500">—.</p>}
                                        {(clientInfo.clientapi !== undefined && clientInfo.clientapi.key !== undefined) && <p className="text-gray-500">{clientInfo.clientapi.key}</p>}
                                    </div>
                                </div>}

                                <div className="mt-6 pt-4 border-t border-gray-200">
                                    <div className="flex justify-end">
                                        <button type="submit" disabled={!dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Update</button>
                                    </div>
                                </div>

                            </form>
                        )}
                    </Formik>

                </div>
            </div>
        </div>
    )
}