import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MenuFragment from './menu.js';
import ManageDevicesPage from './managedevices.js';
import CreateCrmPage from './createcrm.js';
import CrmPage from './crmpage.js';
import LostDevicesPage from './lostdevices.js';
import CreateLostDevicePage from './createlost.js';
import DevicePage from './devicepage.js';
import EditDevicePage from './editdevice.js';
import CreateDevicePage from './createdevice.js';
import CustomersPage from './customerslist.js';
import EditCustomerPage from './editcustomer.js';
import CreateCustomerPage from './createcustomer.js';
import CustomerDetailsPage from './customerdetails.js';
import LogViewerPage from './logviewer.js';
import EditCrmPage from './editcrmpage.js';
import ViewCrmPage from './viewcrmpage.js';

export default function AdminPage(props) {

    return (
        <Routes>
            <Route path="*" element={<MenuFragment />} />
            <Route path="clients/*" element={<CustomersPage />} />
            <Route path="clients/create" element={<CreateCustomerPage userinfo={props.userinfo} />} />
            <Route path="clients/edit/:customerId" element={<EditCustomerPage />} />
            <Route path="clients/:customerId" element={<CustomerDetailsPage />} />
            <Route path="clients/*" element={<CustomersPage />} />
            <Route path="devices/*" element={<ManageDevicesPage />} />
            <Route path="devices/create" element={<CreateDevicePage userinfo={props.userinfo} />} />
            <Route path="devices/edit/:modelId" element={<EditDevicePage />} />
            <Route path="devices/model/:modelId" element={<DevicePage />} />
            <Route path="crm/create" element={<CreateCrmPage userinfo={props.userinfo} />} />
            <Route path="crm/*" element={<CrmPage />} />
            <Route path="crm/edit/:crmId" element={<EditCrmPage userinfo={props.userinfo} />} />
            <Route path="crm/view/:crmId" element={<ViewCrmPage userinfo={props.userinfo} />} />
            <Route path="lost/*" element={<LostDevicesPage />} />
            <Route path="lost/create" element={<CreateLostDevicePage userinfo={props.userinfo} />} />
            <Route path="logviewer/*" element={<LogViewerPage />} />
        </Routes>
    )
}