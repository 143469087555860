import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import useCalibrationHistory from './useCalHistory';
import { formatDateAbbr } from '../../Shared/Components/functions';

export default function CalHistoryOverlay({ isOpen, handler, unitSerial, sensorSerial }) {
    // HOOKS
    const calibrations = useCalibrationHistory(unitSerial, sensorSerial);

    // STATE
    const [open, setOpen] = useState(isOpen)

    function closeDialog() {
        setOpen(false);
        handler();
    }

    React.useEffect(() => {
        setOpen(isOpen);
    }, [isOpen])

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => { /* no-op */ }}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500" onClick={() => closeDialog()}>
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-isober-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationIcon className="h-6 w-6 text-isober-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Calibration History
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        {unitSerial && <p>Unit serial: {unitSerial}</p>}
                                        {!unitSerial && <p>Unit serial: ——</p>}

                                        {sensorSerial && <p>Sensor serial: {sensorSerial}</p>}
                                        {!sensorSerial && <p>Sensor serial: ——</p>}
                                    </div>

                                    <div className="mt-2 divide-y-2 divide-gray-400">
                                        {(calibrations && calibrations.size > 0) && calibrations.docs.map((calibration) => (<div className='py-2 text-xs overflow-scroll'>
                                            <p>{!calibration.data().backdate && <span>{formatDateAbbr(calibration.data().dts.toDate())}</span>}
                                                {calibration.data().backdate && <span>{formatDateAbbr(calibration.data().backdate.toDate())}</span>} by &nbsp;
                                                {calibration.data().authUserName && <span>{calibration.data().authUserName}</span>}
                                            </p>
                                            {calibration.data().clientName && <p>Client: {calibration.data().clientName}</p>}
                                            {calibration.data().certificateCustomerName && <p>Certificate name: {calibration.data().certificateCustomerName}</p>}
                                            {calibration.data().calvalue && <p>Sensor condition: {calibration.data().calvalue}</p>}
                                        </div>
                                        ))}
                                        {(!calibrations || !calibrations.size > 0) && "No previous calibrations found."}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-600 text-base font-medium text-white hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => closeDialog()}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

// {
//     "jobcardGuid": "okFqjfLOdIPfw3u51Alp",
//     "certificategenerated": false,
//     "serialidx": [
//         "TBF0L0023"
//     ],
//     "clientName": "Verbia Systems",
//     "precalzero": "0.00",
//     "unitScale": "mg/L",
//     "modelGuid": "946KwOHzUXofX7GMb9qH",
//     "unitserial": "TBF0L0023",
//     "crmGuid": "0cF1sbcHfL8YB3PlQcyY",
//     "certificateCustomerName": "Verbia Systems",
//     "certificatequeued": false,
//     "authUserGuid": "d0j7hIqDUOMmvR0iuWL7YmvWdYk1",
//     "overridePassLimit": "N/A",
//     "authUserName": "Thabo Maseli",
//     "postcalzero": "0.00",
//     "clientGuid": "CAwLyQf9fugGwsEpetm5",
//     "precalalcohol": "0.34",
//     "validity": "12 months",
//     "deviceGuid": "IWVIsPKVSsb6MlGRKrbm",
//     "modelName": "iBlow10",
//     "calvalue": "020003390",
//     "readingscount": "",
//     "humidity": "33.4",
//     "overrideValidityCount": "65000",
//     "backdate": {
//         "seconds": 1698143420,
//         "nanoseconds": 0
//     },
//     "temperature": "32.4",
//     "postcalreading": "0.33",
//     "dts": {
//         "seconds": 1698229821,
//         "nanoseconds": 132000000
//     },
//     "ledStyle": false
// }