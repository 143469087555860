import React from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, limit, orderBy } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { formatDate } from '../../Shared/Components/functions';

export default function JobCardsList(props) {
    // CHECK BRANCH
    const validBranches = [0, 1, 2, 3, 4, 5];
    const branchPredicate = (props.branch !== undefined && props.branch !== null && validBranches.includes(props.branch)) ? props.branch : 0;

    // FIRESTORE
    const firestore = useFirestore();
    const calibrationCollection = collection(firestore, `jobcards`);
    const activeQuery = (branchPredicate !== 0) ? query(calibrationCollection, where('status', '==', 'Open'), where('branch', '==', branchPredicate), orderBy('created', 'desc'), limit(50)) : query(calibrationCollection, where('status', '==', 'Open'), orderBy('created', 'desc'), limit(50));
    const { status, data: jobcards } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return 'Loading...';
    }

    return (
        <div>
            {jobcards.size === 0 && <div className="mt-6 relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <div className="text-center">
                    <p className="text-lg leading-6 font-medium text-gray-900">No job cards</p>
                    <p className="mt-2 text-sm text-gray-500">
                        No job cards found for the selected branch; they will appear here as they are recorded.
                    </p>
                </div>
            </div>}

            {jobcards.size > 0 && <table className="mt-6 min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr key='hdr'>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Branch</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Devices</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {jobcards.docs.map((jobcard, itemIdx) => (
                        <tr key={`A_${itemIdx}`} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                {jobcard.data().branch === 1 && 'Johannesburg'}
                                {jobcard.data().branch === 2 && 'Cape Town'}
                                {jobcard.data().branch === 3 && 'Durban'}
                                {jobcard.data().branch === 4 && 'Client site'}
                                {jobcard.data().branch === 5 && 'Botswana'}
                            </td>
                            <td className="px-6 pt-2 whitespace-nowrap text-sm text-gray-900">{formatDate(jobcard.data().created.toDate())}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{jobcard.data().clientName}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                {jobcard.data().numDevices !== undefined && jobcard.data().numDevices}
                                {jobcard.data().numDevices === undefined && jobcard.data().deviceIdx !== undefined && jobcard.data().deviceIdx.length}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                {jobcard.data().status === 'Open' && <p className="px-2 inline-flex items-center rounded-full text-xs font-medium bg-blue-100 text-blue-800"> Open </p>}
                                {jobcard.data().status === 'Complete' && <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> Job complete </p>}
                                {jobcard.data().status === 'Quoting' && <p className="px-2 inline-flex items-center rounded-full text-xs font-medium bg-pink-100 text-pink-800"> Awaiting quote </p>}
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-900">
                                <Link to={`/calibrations/dispatch/card/${jobcard.id}`}>
                                    <button className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                        Details
                                    </button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
        </div>
    )
}