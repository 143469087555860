import { collection, query } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

// hook to get a map of usernames indexed by id
// via: import useClientUsers from '../Components/UserInfo/clientusers.js';
//      const clientUsers = useClientUsers(clientId);

export default function useClientInfo() {
    // set up firestore query
    const firestore = useFirestore();
    const clientsCollection = collection(firestore, 'clients');
    const clientsQuery = query(clientsCollection);
    const { status, data: clients } = useFirestoreCollectionData(clientsQuery, {
        idField: 'id', // merge id into object created for each document
    });

    if (status === 'loading') {
        // console.log("[useClientUsers] loading ");
        return undefined;
    }

    const retval = new Map();

    clients.forEach(client => {
        retval.set(client.id, client.name);
    });

    // console.log("[useClientUsers] returning " + retval.size);

    return retval;
}