import React from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import UsersCount from './usercount.js';
// import MeasurementsCount from './MeasurementsCount.js';

function DashboardPage(props) {
    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader title="Dashboard" />
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}
                    <div className="py-4">
                        <UsersCount />

                        { /* Hold until we know how to count measurements
                             <MeasurementsCount /> 
                          */ }
                    </div>
                    {/* /End replace */}
                </div>
            </div>
        </main>
    )
}

export default DashboardPage;