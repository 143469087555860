import React from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { Link, useLocation, useParams } from 'react-router-dom';
import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { formatDateShort } from '../../Shared/Components/functions.js';

export default function CustomerDetailsPage(props) {
    // HOOKS
    const currentLocation = useLocation();
    const { customerId } = useParams();

    // FIRESTORE
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'calibrationclients', customerId);
    const { status, data: customer } = useFirestoreDocData(documentRef);

    if (status === 'loading') {
        return <span>Loading...</span>;
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">The selected client's details appear below.</p>
                        </div>
                        <div className="mt-3 flex sm:mt-0 sm:ml-4">
                            <Link to={`/calibrations/admin/clients/edit/${customerId}`}>
                                <button type="button" className="mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                    Edit details
                                </button>
                            </Link>
                        </div>
                    </div>

                    {/* Device area */}
                    <div className="mt-6 bg-white shadow overflow-y-visible sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Customer information</h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">
                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Name:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {customer.name}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Created:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {formatDateShort(customer.created.toDate())}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Address ln 1:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {customer.address1 !== undefined && customer.address1}
                                        {customer.address1 === undefined && "—"}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Address ln 2:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                    {customer.address2 !== undefined && customer.address2}
                                    {customer.address2 === undefined && "—"}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">City:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {customer.city !== undefined && customer.city}
                                        {customer.city === undefined && "—"}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Postcode:</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {customer.postcode !== undefined && customer.postcode}
                                        {customer.postcode === undefined && "—"}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    )
}