import React, { useState } from 'react';
import * as XLSX from "xlsx"; // https://docs.sheetjs.com/docs/demos/frontend/react/
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import ModalLoadingActivity from '../Components/ModalLoadingActivity.js';

export default function DateRangeTab(props) {
    // first day of the current month
    const currentMonthStart = new Date();
    currentMonthStart.setDate(1);
    currentMonthStart.setHours(0, 0, 0, 0);
    const defaultSelected = { from: currentMonthStart, to: new Date() };

    // STATE
    const [reportResultset, setReportResultset] = useState(undefined);
    const [range, setRange] = useState(defaultSelected);
    const [loading, setLoading] = useState(false);

    // HOOKS
    const firestore = useFirestore();

    const resetRange = () => {
        setRange(defaultSelected);
        setReportResultset(undefined);
    }

    const handleDownload = () => {
        var rows = reportResultset.docs.map(function (calibration) {
            var retval = calibration.data();

            // remove irrelevant columns 
            delete retval.serialidx;
            delete retval.certificatequeued;
            delete retval.deviceGuid;
            delete retval.clientGuid;
            delete retval.modelGuid;
            delete retval.ledStyle;
            delete retval.authUserGuid;
            delete retval.certificategenerated;

            // format calibration date
            if (retval.dts && retval.dts.toDate()) {
                retval.date = retval.dts.toDate().toLocaleString()
                delete retval.dts;
            }

            // format void date
            if (retval.voidDts && retval.voidDts.toDate()) {
                retval.voidDate = retval.voidDts.toDate().toLocaleString()
                delete retval.voidDts;
            }

            return retval;
        });

        // create workbook and worksheet
        const workbook = XLSX.utils.book_new();
        const measurementsWorksheet = XLSX.utils.json_to_sheet(rows);

        XLSX.utils.book_append_sheet(workbook, measurementsWorksheet, "Measurements");
        XLSX.writeFile(workbook, "ReportFor2023.xlsx", { compression: true });
    }

    const getReportData = async () => {
        setReportResultset(undefined); // reset first
        setLoading(true);

        // check date range
        if (validateDateRange() === false) {
            // alerts will be raised in validateDateRange() or shown on-page
            setLoading(false);
            return;
        }

        // validate client ID
        if (!props.userinfo || !props.userinfo.clientid) {
            window.alert("Error: client ID is not set, please contact support.");
            setLoading(false);
            return;
        }

        // validate dates
        if (!range || !range.from || !range.to) {
            window.alert("Error: date range is not set, please contact support.");
            setLoading(false);
            return;
        }

        // start from 00:00:00 AM
        const startDate = range.from;
        startDate.setHours(0, 0, 0, 0);

        // end at 23:59:59 PM
        const endDate = range.to;
        endDate.setHours(23, 59, 59, 999);

        // configure query
        const collectionPath = `calibrations`;
        const calibrationsCollection = collection(firestore, collectionPath);
        const collectionQuery = query(calibrationsCollection, where('dts', '>=', startDate), where('dts', '<=', endDate), orderBy('dts', 'desc'));

        // execute query
        const querySnapshot = await getDocs(collectionQuery);
        setReportResultset(querySnapshot);

        setLoading(false);
    }

    const dateRemovingTime = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    const validateDateRange = () => {
        // duplicated in overall picture, but needed here
        if (!range || !range.from || !range.to) {
            window.alert("Range error: a date range is not set.");
            return false;
        }

        if (range.from >= new Date()) {
            window.alert("Range error: start date cannot be in the future.");
            return false;
        }

        if (dateRemovingTime(range.from).getTime() === dateRemovingTime(range.to).getTime()) {
            window.alert(`Range error: start and end date cannot be the same.`);
            return false;
        }

        if (range.from >= range.to) {
            window.alert(`Range error: end date cannot be earlier than start date.`);
            return false;
        }

        return true;
    }

    return (
        <div>
            {loading && <ModalLoadingActivity isOpen={loading} message="Please wait while report is generated" />}
            <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6 grid grid-cols-2">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Date range</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Choose a date range below, and press 'Search' to locate calibrations.</p>
                    </div>
                    <div className='justify-right text-right'>
                        <button type="button" onClick={() => resetRange()} className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">Reset</button>
                    </div>


                </div>
                <div className="border-t border-gray-200">
                    <DayPicker mode="range" selected={range} onSelect={setRange} max={90} defaultMonth={currentMonthStart} numberOfMonths={3} modifiersClassNames={{ selected: 'text-white bg-isober-900', today: 'text-white bg-isober-1100' }} />
                </div>
            </div>

            <div className="grid grid-cols-2">
                <div className="mt-5 flex justify-start">
                    {range && range.from && range.to && <p>{format(range.from, 'PPPP')} &nbsp;to&nbsp; {format(range.to, 'PPPP')}</p>}
                    {!range && <p>No range selected</p>}
                </div>
                <div className="mt-5 flex justify-end">
                    {!reportResultset && <button type="button" onClick={() => getReportData()} disabled={!(range && range.from && range.to)} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-1000 hover:bg-isober-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                        Next
                    </button>}

                    {reportResultset && <div>
                        <button type="button" onClick={() => handleDownload()} disabled={!(range && range.from && range.to)} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-1000 hover:bg-isober-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                            Download Excel
                        </button>
                    </div>}
                </div>
            </div>

        </div>
    )
}
